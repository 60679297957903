import { ApolloClient, InMemoryCache, ApolloLink, split } from '@apollo/client'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import {} from '@apollo/client/link/context'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import omitDeep from 'omit-deep-lodash'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import { useCallback, useEffect, useState } from 'react'
import { sha256 } from 'crypto-hash'
const { createUploadLink } = require('apollo-upload-client')
const undefinedToNullReplacer = (key, value) =>
  value === undefined ? null : value
function replaceNull(obj) {
  //^ because you seem to want to replace (strings) "null" or "undefined" too
  return JSON.parse(JSON.stringify(obj, undefinedToNullReplacer))
}
const cleanTypenameLink = new ApolloLink((operation, forward) => {
  const keysToOmit = ['__typename'] // more keys like timestamps could be included here
  const def = getMainDefinition(operation.query) as any

  //check if mutation operation and it is not a file upload as variables must not be changed for uploads
  //upload is detected if there is a variable property named "file"
  if (
    def &&
    def.operation === 'mutation' &&
    def.operation &&
    !operation.variables?.file
  ) {
    const removeTypeNameVars = omitDeep(operation.variables, keysToOmit)
    const nullNormalized = replaceNull(removeTypeNameVars)
    operation.variables = nullNormalized
  }
  return forward ? forward(operation) : null
})

/**
 * provides Apollo link with custom http headers
 * see: https://www.apollographql.com/docs/react/networking/authentication/
 */
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem('token')
  const orgId = localStorage.getItem('orgId')
  const locationId =
    sessionStorage.getItem('locationId') || localStorage.getItem('locationId')
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : '',
      'x-orgId': orgId,
      'x-locationId': locationId,
      'accept-culture': 'hr',
    },
  }
})
const uploadLink =
  process.env.REACT_APP_USE_PERSISTED_QUERY === 'false'
    ? createUploadLink({
        uri: process.env.REACT_APP_API_URL,
      })
    : createPersistedQueryLink({ sha256, useGETForHashedQueries: true }).concat(
        createUploadLink({
          uri: process.env.REACT_APP_API_URL,
        })
      )

// const uploadLink = createUploadLink({
//   uri: process.env.REACT_APP_API_URL,
// }
// )
let wsLink: WebSocketLink
let subscriptionClient: SubscriptionClient

export const createWsLink = () => {
  if (subscriptionClient) {
    subscriptionClient.unsubscribeAll()
    subscriptionClient.close()
  }

  subscriptionClient = new SubscriptionClient(
    process.env.REACT_APP_SUBSCRIPTIONS_URL!,

    {
      reconnect: true,
      lazy: true,
      connectionParams: () => {
        const token = localStorage.getItem('token')
        return {
          headers: {
            authorization: token,
          },
        }
      },
    }
  )
  subscriptionClient.onDisconnected(() => {
    console.log(
      '%csocket connection with zoyya graph has disconnected.',
      'background:red; color:white'
    )
  })
  subscriptionClient.onConnected(() => {
    console.log(
      '%cestablished socket connection with zoyya graph.',
      'background:green; color:white'
    )
  })
  subscriptionClient.onReconnected(async () => {
    try {
      console.log(
        '%cReestablished socket connection with zoyya graph.',
        'background:green; color:white'
      )
      await apolloClient.cache.reset()
    } catch (error) {
      console.error({ error })
    }
  })

  wsLink = new WebSocketLink(subscriptionClient)
  return wsLink
}
wsLink = createWsLink()
export const useWebSocketId = () => {
  const [connectionId, setConnectionId] = useState(0)
  useEffect(() => {
    const removeSubs = subscriptionClient.onReconnected(() => {
      setConnectionId(id => id + 1)
    })
    return () => {
      removeSubs()
    }
  }, [])
  return connectionId
}
export const useOnWsReconnect = (
  handler: (connectionId?: number) => any,
  deps: any[] = []
) => {
  const connectionId = useWebSocketId()
  const callback = useCallback(handler, [...(deps || [])])
  useEffect(() => {
    if (!connectionId) return
    callback()
  }, [connectionId, callback])
}
const operationLink = cleanTypenameLink.concat(
  authLink.concat(uploadLink) as any
)

const splitterLink = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    )
  },
  wsLink,
  operationLink
) as any

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: splitterLink,
  defaultOptions: {
    mutate: { errorPolicy: 'all' },
    query: { errorPolicy: 'all' },
    watchQuery: { errorPolicy: 'all' },
  },
})
