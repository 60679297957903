import React, {
  ChangeEventHandler,
  InputHTMLAttributes,
  useCallback,
  MouseEventHandler,
  useEffect,
} from 'react'
import { useField } from 'formik'
import { useFormContext } from '../Form'
import { FieldContainer } from '../FieldContainer'
import PhoneInput from 'react-phone-input-2'
import styled from 'styled-components'
import {
  blueBorder,
  blueLightBorder,
  grayDark,
  grayDarker,
  grayLight,
  red,
  redLight,
  redLighter,
  white,
} from '../../style/colors'
import { fontFamily, fontSize, fontWeight, lineHeight } from '../../style/fonts'
import { useSdk } from 'sdk'
import 'react-phone-input-2/lib/bootstrap.css'
const StyledInput = styled.div<any>`
  .react-tel-input input.form-control {
    position: relative;
    box-sizing: border-box;
    margin: ${props => (props.size === 'small' ? '0 0 8px 0' : '0px')};
    background: ${({ hasError }) => (hasError ? redLight : white)};
    border-radius: 4px;
    border: 1px solid ${({ hasError }) => (hasError ? red : grayLight)};
    box-shadow: 2px 2px 0 2px transparent;
    transition-property: border-width, border-color, box-shadow;
    transition-duration: 0.1s;
    transition-timing-function: ease-in;
    color: ${({ hasError }) => (hasError ? red : grayDarker)};
    font-family: ${fontFamily};
    font-size: ${fontSize};
    font-weight: ${fontWeight};
    line-height: ${lineHeight};
    padding: 9px 14px 8px 60px;
    padding-left: ${props => (props.hasIcon ? '40px' : 'auto')};
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    ${({ prefix }) => (prefix ? `padding-left: ${prefix.paddingLeft};` : '')};
    cursor: ${props => (props.isCalendar ? 'pointer' : 'inherit')};

    &::placeholder {
      color: ${props => props.theme.colors.secondaryDark};
    }

    &:focus {
      border: 1px solid ${({ hasError }) => (hasError ? red : blueBorder)};
      box-shadow: 0px 0px 0px 3px
        ${({ hasError }) => (hasError ? redLighter : blueLightBorder)};
      outline: none;
      transition-property: border-width, border-color, box-shadow;
      transition-duration: 0.1s;
      transition-timing-function: ease-in;
    }

    &:disabled {
      background: ${grayLight};
      border: 1px solid ${grayLight};
      color: ${grayDark};
      cursor: not-allowed;

      &::placeholder {
        color: ${props => props.theme.colors.secondaryDark};
      }
    }
  }
`
type TFormFieldPhoneInputProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  label?: string
  style?: any
  onClick?: (event: MouseEventHandler<HTMLInputElement>) => any
  helpText?: string
  helpLink?: string
  defaultMask?: string
  icon?: any
}

export const FormFieldPhoneInput = (props: TFormFieldPhoneInputProps) => {
  const { ...rest } = props
  const [field, meta] = useField(props)
  const formContext = useFormContext()

  const handleChange = useCallback(
    (value, country, e, formattedValue) => {
      formContext?.valueChange(field, '', formattedValue)
    },
    [formContext, field]
  )

  useEffect(() => {
    const phoneInput = document.getElementsByClassName('selected-flag')[0]
    phoneInput.setAttribute('tabindex', '-1')

    const input = document.getElementsByTagName('input')[0]

    if (input && props.autoFocus) {
      input.focus()
    }
  }, [props.autoFocus])

  const { t } = useSdk()

  return (
    <FieldContainer
      label={props.label}
      error={meta.error}
      fieldName={field.name}
      type={props.type}
      helpText={props.helpText}
      helpLink={props.helpLink}
    >
      <StyledInput data-cy={`input_${props.name}`}>
        <PhoneInput
          {...rest}
          data-cy={`input_${props.name}`}
          placeholder={props.placeholder}
          country={'hr'}
          value={field.value || ''}
          onChange={handleChange}
          disabled={props.disabled}
          jumpCursorToEnd
          autocompleteSearch
          searchNotFound={t('translation.PhoneInput.label-no-results')}
          searchPlaceholder={t('translation.PhoneInput.label-search')}
          enableSearch
          //  alwaysDefaultMask
          enableAreaCodes
          enableAreaCodeStretch
          areaCodes={{
            hr: [
              '01',
              '023',
              '020',
              '021',
              '022',
              '031',
              '032',
              '033',
              '034',
              '035',
              '040',
              '041',
              '042',
              '043',
              '044',
              '047',
              '048',
              '049',
              '051',
              '052',
              '053',
              '099',
              '99',
              '095',
              '95',
              '091',
              '91',
              '098',
              '98',
              '1',
              '23',
              '20',
              '31',
              '32',
              '33',
            ],
          }}
          defaultMask={props.defaultMask || '(..) ...-....'}
        />
      </StyledInput>
    </FieldContainer>
  )
}
