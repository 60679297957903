import { css } from 'styled-components'

export const base = css`
  display: inline-block;
  fill: currentcolor;
  stroke: currentColor;
`

export const small = css`
  width: 18px;
  height: 18px;
  min-width: 18px;
`

export const smaller = css`
  width: 22px;
  height: 22px;
  min-width: 22px;
`

export const extraSmall = css`
  width: 12px;
  height: 12px;
  min-width: 12px;
`

export const medium = css`
  width: 26px;
  height: 26px;
  min-width: 26px;
`

export const large = css`
  width: 32px;
  height: 32px;
  min-width: 32px;
`

export const extraLarge = css`
  width: 40px;
  height: 40px;
`

export const big = css`
  width: 50px;
  height: 50px;
`
