import styled from 'styled-components'
import { device } from '../../../constants'

export const DesktopLocationsContainer = styled.div<any>`
  position: relative;
  width: 100%;
  padding: 130px 0px 0px 0px;
  margin: 0px auto;
  height: 100%;
  overflow: auto;
  background: ${props => props.theme.colors.light};
  @media ${device.tablet} {
    display: block !important;
    padding: 0px;
    padding-top: 0px;
    background-image: none;
  }
`

export const DesktopLocationsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 60px;
  max-width: 1100px;
  margin: 60px auto 0px;
  padding-bottom: 60px;
  width: 100%;
  @media ${device.tablet} {
    padding: 0px 30px;
    padding-bottom: 60px;
    grid-template-columns: repeat(1, 100%);
    height: auto;
    gap: 35px;
    margin-top: 35px;
  }
`

export const LocationsTitle = styled.span`
  font-size: 22px;
  font-weight: 600;
  padding: 20px 0 0 15px;
  display: flex;
`

export const DesktopLocationsItem = styled.div`
  display: flex;
  padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  border: 5px solid ${props => props.theme.colors.lightBackground};
  border-radius: 40px;
  transition-property: transform;
  transition-duration: 400ms;
  transition-timing-function: ease;
  cursor: pointer;
  background: ${props => props.theme.colors.light};
  height: 350px;
  :hover {
    transform: translate(0px, -5px);
  }

  @media ${device.tablet} {
    background: transparent;
    box-shadow: none;
    height: auto;
    max-width: 300px;
    margin: auto;
  }
`

export const DesktopLocationsLink = styled.a`
  display: flex;
  height: 100%;
  padding-right: 0px;
  padding-left: 0px;
  flex-direction: column;
  justify-content: flex-start;
  text-decoration: none;
  transition: transform 330ms ease-in-out;
  position: relative;
  :active {
    transform: scale(0.98);
  }
`

export const DesktopLocationsImageWrapper = styled.div`
  position: relative;
  display: block;
  margin: 0px;
  padding: 0px;
  background: transparent;
  animation: 0s ease 0s 1 normal none running none;
  border-radius: 4px;
  overflow: hidden;
  width: 100%;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: black;
    transition: opacity 300ms ease 0s;
  }
`

export const DesktopLocationImage = styled.img<any>`
  display: flex;
  width: 80%;
  height: 135px;
  background-position: center;
  object-fit: contain;
  border-radius: 40px 40px 0px 0px;

  @media ${device.tablet} {
    height: 110px;
  }
`

export const DesktopLocationWrapper = styled.div<any>`
  object-fit: cover;
  display: flex;
  width: 100%;
  height: 180px;
  border-radius: 10px 10px 0px 0px;
  background-position: center;

  @media ${device.tablet} {
    border-radius: 4px;
    height: 180px;
  }
`

export const DesktopLocationsImageDesc = styled.div`
  display: flex;
  height: auto;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1 1 0%;
  color: rgb(102, 102, 102);
  text-decoration: none;
  width: 100%;
  height: 140px;
  @media ${device.tablet} {
    height: auto;
  }
`

export const DesktopLocationTag = styled.div`
  color: rgb(106, 78, 225);
  font-size: 12px;
  font-weight: 500;
`

export const DesktopLocationCardTitle = styled.p`
  letter-spacing: 0px;
  min-width: 0px;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  color: ${props => props.theme.colors.text};
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  margin: 0px;
  overflow: hidden;
  text-align: center;

  @media ${device.tablet} {
    display: initial;
    overflow: auto;
    margin-top: 10px;
    line-height: 24px;
  }
`

export const DesktopLocationCardSubtitle = styled.p`
  letter-spacing: 0px;
  min-width: 0px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: ${props => props.theme.colors.text};
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 0px 0px 2px;
  text-align: center;
  padding: 0px 20px;
  @media ${device.tablet} {
    margin-bottom: 20px;
    display: initial;
    overflow: auto;
    max-height: 100px;
    font-size: 14px;
  }
`

export const DesktopLocationLink = styled.div`
  font-size: 14px;
  font-weight: 500;
  background: ${props => props.theme.colors.accent3};
  color: ${props => props.theme.colors.light};
  padding: 5px 20px;
  text-align: center;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  justify-content: center;
  margin-top: auto;
  border-radius: 0px 0px 40px 40px;
  height: 80px;

  @media ${device.tablet} {
    height: 70px;
  }
`
