import styled from 'themes'
import { StyledFieldsContainer } from '../../components/Form/Form'
import { device } from '../../constants'
import { FieldRow } from '../Settings/styles'
import { ButtonStyled } from '../../components/Button/Button'
import { StyledCheck } from '../../components/Checkbox/Checkbox'

export const LoginPage = styled.div`
  overflow: auto;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  max-height: 100%;
  max-width: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;

  background-image: radial-gradient(#1972b9, #16376a);
  @media ${device.tablet} {
    display: initial;
  }
`

export const LoginForm = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  height: 100%;

  border-radius: 20px;
  z-index: 999;
  width: 500px;
  height: auto;

  ${StyledCheck} {
    margin-left: 0px;
  }

  @media ${device.tablet} {
    width: 100%;
    border-radius: 20px 20px 0px 0px;
    height: 100%;
    ${FieldRow} {
      flex-direction: row;
    }
  }
`

export const LoginFormHeader = styled.a`
  height: 120px;
  display: flex;
  flex-direction: column;
  position: relative;
  /* margin: 20px; */
  background: linear-gradient(45deg, #1a74bb, #004187);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  @media ${device.tablet} {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    height: auto;
  }
`

export const LoginHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  z-index: 9999;
  padding-left: 30px;
  @media ${device.tablet} {
    position: relative;
    height: 100px;
    align-items: center;
    justify-content: flex-start;
  }
`

export const LoginLogoTitle = styled.div`
  color: ${props => props.theme.colors.accent1};
  font-size: 18px;
  font-weight: 600;
  margin-left: 10px;

  @media ${device.tablet} {
    font-size: 14px;
  }
`

export const LogoColor = styled.img`
  width: 118px;

  @media ${device.tablet} {
    width: 104px;
  }
`

export const LoginFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 30px;
  ${StyledFieldsContainer} {
    height: auto;
  }

  @media ${device.tablet} {
    display: initial;
    height: 100%;
    padding-bottom: 50px;

    ${FieldRow} {
      ${ButtonStyled} {
        margin-bottom: 14px !important;
      }
    }
  }
`
export const LoginImage = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: -5px;
  height: 90%;
  /* margin: auto; */
  width: 100%;

  left: 20%;

  @media ${device.tablet} {
    display: none;
  }
`

export const LoginTitle = styled.span`
  font-size: 20px;
  color: ${props => props.theme.colors.primary};
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LoginHint = styled.span`
  display: flex;
  margin: auto;
  text-align: center;
  color: ${props => props.theme.colors.secondaryDark};
  margin-top: 10px;
  justify-content: center;

  @media ${device.tablet} {
    flex-direction: column;
  }
`

export const LoginLink = styled.span`
  color: ${props => props.theme.colors.primary};
  text-decoration: underline;
  margin-left: 10px;
  cursor: pointer;

  @media ${device.tablet} {
    margin-left: 0;
  }
`

export const LoginPartnerRedirect = styled.span`
  color: ${props => props.theme.colors.primary};
  margin-top: 5px;
  cursor: pointer;
`
