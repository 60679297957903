import React from 'react'
import Text from '../Text/Text'
import { CheckboxInput } from '../Form/Fields/Checkbox'
import styled from 'styled-components'
import { device } from '../../constants'

export const StyledCheck = styled.div`
  display: flex;
  align-items: center;
  margin-left: 30px;
  margin-top: 10px;

  @media ${device.tablet} {
    margin-left: 10px;

    input {
      margin-right: 50px !important;
    }
  }
`

export const Checkbox = props => {
  const { html, label, handleChange, checked, disabled, style } = props
  return (
    <div
      style={Object.assign({
        display: 'flex',
        flex: 1,
      })}
    >
      <StyledCheck
        style={{
          ...style,
        }}
      >
        <CheckboxInput
          onChange={handleChange}
          style={{ marginRight: 50, height: 38 }}
          checked={checked}
          disabled={disabled}
          type={'checkbox'}
        />
        <div dangerouslySetInnerHTML={html ? { __html: html } : undefined}>
          {label ? (
            <Text
              style={{
                marginBottom: '5px',
                display: 'flex',
              }}
              type="label"
            >
              {label}
            </Text>
          ) : null}
        </div>
      </StyledCheck>
    </div>
  )
}
