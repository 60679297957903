import variables from './themes.scss'
import baseStyled, {
  ThemedStyledInterface,
  ThemeContext,
} from 'styled-components'
import { useContext } from 'react'
type TThemeColors = {
  background: string
  backgroundDarker: string
  primary: string
  outline: string
  accent1: string
  accent2: string
  accent3: string
  accent4: string
  accent5: string
  secondaryDark: string
  secondaryDarker: string
  text: string
  light: string
  gradientBackground: string
  mainBackground: string
  lightBackground: string
  primaryDark: string
}
const themeProps = [
  'background',
  'backgroundDarker',
  'primary',
  'outline',
  'accent1',
  'accent2',
  'accent3',
  'accent4',
  'accent5',
  'secondaryDark',
  'secondaryDarker',
  'text',
  'light',
  'gradientBackground',
  'mainBackground',
  'lightBackground',
  'primaryDark',
]

const getThemeProps = themeName => {
  let colors = {}

  for (let i = 0; i < themeProps.length; i++) {
    let firstLetter = themeProps[i].slice(0, 1).toUpperCase()
    let otherLetters = themeProps[i].slice(1, themeProps[i].length)
    colors[themeProps[i]] = variables[themeName + firstLetter + otherLetters]
  }

  return colors as TThemeColors
}

export const themes = {
  default: {
    colors: getThemeProps('default'),
  },
  dark: {
    colors: getThemeProps('dark'),
  },
  // deep: {
  //   colors: getThemeProps("deep")
  // },
  // base: {
  //   colors: getThemeProps("base")
  // },
  // light: {
  //   colors: getThemeProps("light")
  // }
}
export interface ITheme {
  colors: TThemeColors
}
export const styled = baseStyled as ThemedStyledInterface<ITheme>
export default styled

export const useTheme = () => {
  return useContext(ThemeContext) as ITheme
}
