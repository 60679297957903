import React, {
  ChangeEventHandler,
  InputHTMLAttributes,
  useCallback,
  MouseEventHandler,
} from 'react'
import { useField } from 'formik'
import { useFormContext } from '../Form'
import { FieldContainer } from '../FieldContainer'
import styled from 'styled-components'
import {
  blueBorder,
  blueLightBorder,
  grayDark,
  grayDarker,
  grayLight,
  red,
  redLight,
  redLighter,
  white,
} from '../../style/colors'
import { fontFamily, fontWeight, lineHeight } from '../../style/fonts'
import ReactCodeInput from 'react-verification-code-input'
const StyledInput = styled.div<any>`
  .verificationCode {
    width: 100% !important;
  }
  .verificationCode input {
    background: ${({ hasError }) => (hasError ? redLight : white)};
    border-radius: 4px;
    border: 1px solid ${({ hasError }) => (hasError ? red : grayLight)};
    box-shadow: 2px 2px 0 2px transparent;

    color: ${({ hasError }) => (hasError ? red : grayDarker)};
    font-family: ${fontFamily};
    font-size: 20px;
    font-weight: ${fontWeight};
    line-height: ${lineHeight};
    width: calc((100% / 6) - 3px) !important;

    &:focus {
      border: 1px solid ${({ hasError }) => (hasError ? red : blueBorder)};
      box-shadow: 0px 0px 0px 3px
        ${({ hasError }) => (hasError ? redLighter : blueLightBorder)};
      outline: none;
      transition-property: border-width, border-color, box-shadow;
      transition-duration: 0.1s;
      transition-timing-function: ease-in;
    }

    &:disabled {
      background: ${grayLight};
      border: 1px solid ${grayLight};
      color: ${grayDark};
      cursor: not-allowed;

      &::placeholder {
        color: ${props => props.theme.colors.secondaryDark};
      }
    }

    &:not(last-child) {
      margin-right: 3px;
    }
  }
`
type TFormFieldVerificationInputProps = InputHTMLAttributes<
  HTMLInputElement
> & {
  name: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  label?: string
  style?: any
  onClick?: (event: MouseEventHandler<HTMLInputElement>) => any
  helpText?: string
  helpLink?: string
  fields?: any
}

export const FormFieldVerificationInput = (
  props: TFormFieldVerificationInputProps
) => {
  const { onChange, ...rest } = props
  const [field, meta] = useField(props)
  const formContext = useFormContext()

  const handleChange = useCallback(
    event => {
      formContext?.valueChange(field, event, event)
      if (onChange) {
        onChange(event)
      }
    },
    [formContext, field, onChange]
  )

  return (
    <FieldContainer
      label={props.label}
      error={meta.error}
      fieldName={field.name}
      type={props.type}
      helpText={props.helpText}
      helpLink={props.helpLink}
    >
      <StyledInput>
        <ReactCodeInput
          {...rest}
          onChange={handleChange}
          disabled={props.disabled}
          type={'number'}
          values={[]}
          placeholder={[]}
          className={'verificationCode'}
          fieldWidth={70}
          fieldHeight={70}
        />
      </StyledInput>
    </FieldContainer>
  )
}
