import moment from 'moment'
import { debounce, sumBy } from 'lodash'
import i18n from 'localization'

import React, { useEffect, useState } from 'react'
import { OrganizationSwitchIcon } from './layout/styles'
import Avatar, { AvatarWrapper } from './components/Avatar/Avatar'
import { Shop } from './components/Icon/Icons/Shop'
import Cross from './components/Icon/Icons/Cross'
import { CheckMarkIcon } from './components/Icon/Icons/Checkmark'
import parsePhoneNumber from 'libphonenumber-js'

export const analyticsTrack = (event, metadata) => {
  let analytics = (window.analytics = window.analytics || [])
  if (analytics && typeof analytics.track === 'function') {
    analytics.track(event, metadata)
  }
}

export function formatPhoneNumber(phoneNumberString) {
  const phoneNumber = parsePhoneNumber(phoneNumberString, {
    defaultCountry: 'HR',
  })

  return phoneNumber ? phoneNumber.formatInternational() : phoneNumberString
}

export const formatTime = (appointment, isBooking) => {
  let startTime = ''
  if (isBooking) {
    startTime = moment(appointment?.startTimeBooking).format('HH:mm')
  } else {
    startTime = appointment?.startTime
  }
  const minutes = startTime?.split(':')[1]
  const hour = startTime?.split(':')[0]

  const minutesWithHours =
    parseInt(hour) * 60 + parseInt(minutes) + parseInt(appointment?.duration)

  const newMinutes = Math.floor(minutesWithHours % 60)
  const newHours = Math.floor(minutesWithHours / 60)

  return (
    (newHours < 10 ? '0' + newHours : newHours) +
    ':' +
    (newMinutes < 10 ? '0' + newMinutes : newMinutes)
  )
}

export const hours = [
  { id: '00:00', display: '00:00' },
  { id: '', display: '00:15' },
  { id: '00:30', display: '00:30' },
  { id: '', display: '00:45' },
  { id: '01:00', display: '01:00' },
  { id: '', display: '01:15' },
  { id: '01:30', display: '01:30' },
  { id: '', display: '01:45' },
  { id: '02:00', display: '02:00' },
  { id: '', display: '02:15' },
  { id: '02:30', display: '02:30' },
  { id: '', display: '02:45' },
  { id: '03:00', display: '03:00' },
  { id: '', display: '03:15' },
  { id: '03:30', display: '03:30' },
  { id: '', display: '03:45' },
  { id: '04:00', display: '04:00' },
  { id: '', display: '04:15' },
  { id: '04:30', display: '04:30' },
  { id: '', display: '04:45' },
  { id: '05:00', display: '05:00' },
  { id: '', display: '05:15' },
  { id: '05:30', display: '05:30' },
  { id: '', display: '05:45' },
  { id: '06:00', display: '06:00' },
  { id: '', display: '06:15' },
  { id: '06:30', display: '06:30' },
  { id: '', display: '06:45' },
  { id: '07:00', display: '07:00' },
  { id: '', display: '07:15' },
  { id: '07:30', display: '07:30' },
  { id: '', display: '07:45' },
  { id: '08:00', display: '08:00' },
  { id: '', display: '08:15' },
  { id: '08:30', display: '08:30' },
  { id: '', display: '08:45' },
  { id: '09:00', display: '09:00' },
  { id: '', display: '09:15' },
  { id: '09:30', display: '09:30' },
  { id: '', display: '09:45' },
  { id: '10:00', display: '10:00' },
  { id: '', display: '10:15' },
  { id: '10:30', display: '10:30' },
  { id: '', display: '10:45' },
  { id: '11:00', display: '11:00' },
  { id: '', display: '11:15' },
  { id: '11:30', display: '11:30' },
  { id: '', display: '11:45' },
  { id: '12:00', display: '12:00' },
  { id: '', display: '12:15' },
  { id: '12:30', display: '12:30' },
  { id: '', display: '12:45' },
  { id: '13:00', display: '13:00' },
  { id: '', display: '13:15' },
  { id: '13:30', display: '13:30' },
  { id: '', display: '13:45' },
  { id: '14:00', display: '14:00' },
  { id: '', display: '14:15' },
  { id: '14:30', display: '14:30' },
  { id: '', display: '14:45' },
  { id: '15:00', display: '15:00' },
  { id: '', display: '15:15' },
  { id: '15:30', display: '15:30' },
  { id: '', display: '15:45' },
  { id: '16:00', display: '16:00' },
  { id: '', display: '16:15' },
  { id: '16:30', display: '16:30' },
  { id: '', display: '16:45' },
  { id: '17:00', display: '17:00' },
  { id: '', display: '17:15' },
  { id: '17:30', display: '17:30' },
  { id: '', display: '17:45' },
  { id: '18:00', display: '18:00' },
  { id: '', display: '18:15' },
  { id: '18:30', display: '18:30' },
  { id: '', display: '18:45' },
  { id: '19:00', display: '19:00' },
  { id: '', display: '19:15' },
  { id: '19:30', display: '19:30' },
  { id: '', display: '19:45' },
  { id: '20:00', display: '20:00' },
  { id: '', display: '20:15' },
  { id: '20:30', display: '20:30' },
  { id: '', display: '20:45' },
  { id: '21:00', display: '21:00' },
  { id: '', display: '21:15' },
  { id: '21:30', display: '21:30' },
  { id: '', display: '21:45' },
  { id: '22:00', display: '22:00' },
  { id: '', display: '22:15' },
  { id: '22:30', display: '22:30' },
  { id: '', display: '22:45' },
  { id: '23:00', display: '23:00' },
  { id: '', display: '23:15' },
  { id: '23:30', display: '23:30' },
  { id: '', display: '23:45' },
]

export const workHours = [
  { id: '00:00', display: '00:00' },
  { id: '', display: '00:15' },
  { id: '', display: '00:30' },
  { id: '', display: '00:45' },
  { id: '', display: '01:00' },
  { id: '', display: '01:15' },
  { id: '', display: '01:30' },
  { id: '', display: '01:45' },
  { id: '02:00', display: '02:00' },
  { id: '', display: '02:15' },
  { id: '', display: '02:30' },
  { id: '', display: '02:45' },
  { id: '', display: '03:00' },
  { id: '', display: '03:15' },
  { id: '', display: '03:30' },
  { id: '', display: '03:45' },
  { id: '04:00', display: '04:00' },
  { id: '', display: '04:15' },
  { id: '', display: '04:30' },
  { id: '', display: '04:45' },
  { id: '', display: '05:00' },
  { id: '', display: '05:15' },
  { id: '', display: '05:30' },
  { id: '', display: '05:45' },
  { id: '06:00', display: '06:00' },
  { id: '', display: '06:15' },
  { id: '', display: '06:30' },
  { id: '', display: '06:45' },
  { id: '', display: '07:00' },
  { id: '', display: '07:15' },
  { id: '', display: '07:30' },
  { id: '', display: '07:45' },
  { id: '08:00', display: '08:00' },
  { id: '', display: '08:15' },
  { id: '', display: '08:30' },
  { id: '', display: '08:45' },
  { id: '', display: '09:00' },
  { id: '', display: '09:15' },
  { id: '', display: '09:30' },
  { id: '', display: '09:45' },
  { id: '10:00', display: '10:00' },
  { id: '', display: '10:15' },
  { id: '', display: '10:30' },
  { id: '', display: '10:45' },
  { id: '', display: '11:00' },
  { id: '', display: '11:15' },
  { id: '', display: '11:30' },
  { id: '', display: '11:45' },
  { id: '12:00', display: '12:00' },
  { id: '', display: '12:15' },
  { id: '', display: '12:30' },
  { id: '', display: '12:45' },
  { id: '', display: '13:00' },
  { id: '', display: '13:15' },
  { id: '', display: '13:30' },
  { id: '', display: '13:45' },
  { id: '14:00', display: '14:00' },
  { id: '', display: '14:15' },
  { id: '', display: '14:30' },
  { id: '', display: '14:45' },
  { id: '', display: '15:00' },
  { id: '', display: '15:15' },
  { id: '', display: '15:30' },
  { id: '', display: '15:45' },
  { id: '16:00', display: '16:00' },
  { id: '', display: '16:15' },
  { id: '', display: '16:30' },
  { id: '', display: '16:45' },
  { id: '', display: '17:00' },
  { id: '', display: '17:15' },
  { id: '', display: '17:30' },
  { id: '', display: '17:45' },
  { id: '18:00', display: '18:00' },
  { id: '', display: '18:15' },
  { id: '', display: '18:30' },
  { id: '', display: '18:45' },
  { id: '', display: '19:00' },
  { id: '', display: '19:15' },
  { id: '', display: '19:30' },
  { id: '', display: '19:45' },
  { id: '20:00', display: '20:00' },
  { id: '', display: '20:15' },
  { id: '', display: '20:30' },
  { id: '', display: '20:45' },
  { id: '', display: '21:00' },
  { id: '', display: '21:15' },
  { id: '', display: '21:30' },
  { id: '', display: '21:45' },
  { id: '22:00', display: '22:00' },
  { id: '', display: '22:15' },
  { id: '', display: '22:30' },
  { id: '', display: '22:45' },
  { id: '', display: '23:00' },
  { id: '', display: '23:15' },
  { id: '', display: '23:30' },
  { id: '', display: '23:45' },
]

export const calculateDuration = event => {
  return moment(`2000-01-01 ${event.endTime}`).diff(
    moment(`2000-01-01 ${event.startTime}`),
    'minutes'
  )
}

export const getOrgId = () => {
  return localStorage.getItem('orgId') &&
    localStorage.getItem('orgId') !== 'null'
    ? localStorage.getItem('orgId')
    : null
}

export const getToken = () => {
  return localStorage.getItem('token')
}

export const getLocationId = () => {
  return (
    sessionStorage.getItem('locationId') || localStorage.getItem('locationId')
  )
}

export const getUserAvatar = (resource, isColor, size, idx) => {
  let userAvatar = null

  if (
    resource?.user?.avatarUrl &&
    !resource?.user?.avatarUrl.endsWith('null')
  ) {
    userAvatar = resource?.user?.avatarUrl
  } else if (!!resource?.user?.google_profilePicUrl) {
    userAvatar = resource?.user?.google_profilePicUrl
  } else if (!!resource?.user?.fb_profilePicUrl) {
    userAvatar = resource?.user?.fb_profilePicUrl
  }
  return isColor ? (
    userAvatar ? (
      <Avatar size={size} src={userAvatar} />
    ) : (
      <AvatarWrapper size={size}>
        <OrganizationSwitchIcon
          background={resource?.color || '#1A74BB'}
          size={size}
        >
          {resource?.user?.firstName
            ? resource?.user?.firstName.substring(0, 2)
            : 'NN'}
        </OrganizationSwitchIcon>
      </AvatarWrapper>
    )
  ) : (
    userAvatar
  )
}

export const getLocationImage = location => {
  const imageUrl = location?.imageUrl || location?.avatarUrl

  return !imageUrl || imageUrl?.endsWith('null')
    ? process.env.PUBLIC_URL + '/assets/noImage.svg'
    : imageUrl
}

export const genders = [
  { id: 'Female', title: i18n.t('translation.helpers.female') },
  { id: 'Male', title: i18n.t('translation.helpers.male') },
  { id: 'Unspecified', title: i18n.t('translation.helpers.dont-say') },
]
export const roles = [
  { id: 'OWNER', title: i18n.t('translation.helpers.Owner') },
  { id: 'ADMIN', title: i18n.t('translation.helpers.administrator') },
  { id: 'USER', title: i18n.t('translation.helpers.User') },
]

export const categories = [
  { id: 'vip', title: i18n.t('translation.helpers.vip-klijent') },
]

function getSize() {
  const isClient = typeof window === 'object'
  return {
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined,
  }
}

export function useViewportDimensions() {
  const [dimensions, setDimensions] = useState({
    isMobile: window?.innerWidth < 1020,
  })
  useEffect(() => {
    const isClient = typeof window === 'object'
    if (!isClient) {
      return
    }

    const handleResize = debounce(() => {
      const dimensionsCalc = {
        isMobile: window?.innerWidth < 1020,
      }

      if (dimensionsCalc.isMobile !== dimensions.isMobile) {
        setDimensions(dimensionsCalc)
      }
    }, 100)

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [dimensions]) // Empty array ensures that effect is only run on mount and unmount

  return dimensions
}
export function useWindowSize() {
  const [windowSize, setWindowSize] = useState(getSize())

  useEffect(() => {
    const isClient = typeof window === 'object'
    if (!isClient) {
      return
    }

    const handleResize = debounce(() => {
      setWindowSize(getSize())
    }, 400)

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [windowSize]) // Empty array ensures that effect is only run on mount and unmount

  return windowSize
}

export const getHour = date => {
  return moment(date).format('HH:mm')
}

export const getISODate = (date?: moment.MomentInput) => {
  return moment(date).format('YYYY-MM-DD')
}

export const getStartOfWeek = (date?: moment.MomentInput) => {
  return moment(date)
    .startOf('week')
    .format('YYYY-MM-DD')
}

export const getEndOfWeek = (date?: moment.MomentInput) => {
  return moment(date)
    .endOf('week')
    .format('YYYY-MM-DD')
}

export const getStartOfMonth = (date?: moment.MomentInput) => {
  return moment(date)
    .startOf('month')
    .subtract(5, 'day')
    .format('YYYY-MM-DD')
}

export const getEndOfMonth = (date?: moment.MomentInput) => {
  return moment(date)
    .endOf('month')
    .add(5, 'day')
    .format('YYYY-MM-DD')
}

export const getStartOfYear = (date?: moment.MomentInput) => {
  return moment(date)
    .startOf('year')
    .format('YYYY-MM-DD')
}

export const getEndOfYear = (date?: moment.MomentInput) => {
  return moment(date)
    .endOf('year')
    .format('YYYY-MM-DD')
}

export const getTodayISO = () => {
  return moment().format('YYYY-MM-DD')
}

export const getDuration = totalMinutes => {
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60
  return (
    (hours > 0 ? hours + ' h ' : '') + (minutes > 0 ? minutes + ' min' : '')
  )
}

export const getStatusLabel = (
  label,
  t,
  isMobile?: boolean,
  reason?: string
) => {
  switch (label) {
    case 'CONFIRMED':
      return isMobile ? '✓' : t('translation.status.confirmed')
    case 'new':
      return isMobile ? '⟲' : t('translation.status.new')
    case 'DRAFT':
      return isMobile ? '⟲' : t('translation.status.draft')
    case 'canceled':
      return isMobile
        ? '⤫'
        : reason === 'CLIENT_NO_SHOW'
        ? t('translation.helpers.noShow')
        : reason === 'CLIENT_CANCELED'
        ? t('translation.helpers.clientCanceled')
        : t('translation.status.canceled')
    case 'concluded':
      return isMobile ? '✓' : t('translation.helpers.concluded')
    default:
      return isMobile ? '⟲' : t('translation.status.new')
  }
}

export const getStatusColor = (label, selectedTheme) => {
  switch (label) {
    case 'CONFIRMED':
      return selectedTheme.colors.accent3
    case 'new':
      return selectedTheme.colors.primary
    case 'DRAFT':
      return selectedTheme.colors.accent5
    case 'canceled':
      return selectedTheme.colors.accent5
    case 'noShow':
      return selectedTheme.colors.accent5
    case 'concluded':
      return selectedTheme.colors.primary
    default:
      return selectedTheme.colors.primary
  }
}

export const getStatusIcon = label => {
  switch (label) {
    case 'CONFIRMED':
      return <CheckMarkIcon size={'small'} />
    case 'new':
      return <CheckMarkIcon size={'small'} />
    case 'DRAFT':
      return <Shop size={'small'} />
    case 'canceled':
      return <Cross size={'small'} />
    case 'noShow':
      return <Cross size={'small'} />
    default:
      return <CheckMarkIcon size={'small'} />
  }
}

export function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = event => {
      // Do nothing if clicking ref's element or descendent elements
      if (
        !ref.current ||
        typeof ref.current.contains != 'function' ||
        ref.current.contains(event.target)
      ) {
        return
      }

      handler(event)
    }

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
    }
  }, [ref, handler])
}

export const getDurationString = duration => {
  const hours = Math.floor(duration / 60)
  const minutes = Math.floor(duration % 60)

  return (
    (hours < 10 ? '0' + hours : hours) +
    ':' +
    (minutes < 10 ? '0' + minutes : minutes)
  )
}

export const normalizeMobilePhoneNumber = number => {
  let normalized = number
  if (!normalized) return
  normalized = normalized.replace(/[^\d+]+/g, '')
  normalized = normalized.replace(/^3850/, '+385')
  normalized = normalized.replace(/^385/, '+385')
  return normalized
}
export const getServiceDuration = selectedServices => {
  return sumBy(selectedServices, 'durationMinutes')
}

export const getEndTime = (timeId, selectedServices) => {
  const duration = getServiceDuration(selectedServices)
  const time = moment().format('YYYY-MM-DD') + ' ' + timeId
  return moment(time)
    .add(duration, 'minutes')
    .format('HH:mm')
}

export const formatPhone = phone => {
  console.log({ phone })
  return phone && phone?.length > 4
    ? phone?.replace(/[^\dxX+]/g, '')?.replace('3850', '385')
    : null
}
export const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()
  return /iphone|ipad|ipod/.test(userAgent)
}
export const isInStandaloneMode = () =>
  //@ts-ignore
  ('standalone' in window.navigator && window.navigator.standalone) ||
  window.matchMedia('(display-mode: standalone)').matches ||
  //@ts-ignore
  window.navigator?.standalone ||
  document.referrer.includes('android-app://')

export const getCalendarTimes = (calendarData, startDate, endDate) => {
  return calendarData?.data?.calendar?.getOpenHours?.filter(x =>
    moment(x.date).isBetween(startDate, endDate)
  )
}
