import React, { useRef, useContext } from 'react'
import {
  DesktopLocationCardSubtitle,
  DesktopLocationCardTitle,
  DesktopLocationImage,
  DesktopLocationLink,
  DesktopLocationsGrid,
  DesktopLocationsImageDesc,
  DesktopLocationsItem,
  DesktopLocationsLink,
} from './location'
import { getLocationImage } from 'helpers'
import { useGetOrganizationsQuery } from 'state/graphql'

import { Loader, AnimatedDiv } from 'components'
import { useSdk } from 'sdk'
import darken from 'polished/lib/color/darken'
import { getCurrentLanguage } from 'localization'
import Helmet from 'react-helmet'
import { uniq } from 'lodash'
import { useHeader } from 'mobile/Header'
import { ClientAppDrawer } from 'clientRoutes'
import styled from 'themes'
import { device } from '../../../constants'
import Footer from '../Footer/Footer'
import { MobileContext } from 'App'

export const Background = styled.img`
  width: 100vw;
  max-height: 70vh;
  position: absolute;
  min-width: 100vw;
  left: 0;
  object-fit: cover;
`

export const AkdImage = styled.img``

export const OrderButton = styled.div`
  background: ${props => props.theme.colors.accent1};
  font-size: 14px;
  font-weight: 600;
  color: ${props => props.theme.colors.accent3};
  padding: 0px 25px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 100px;
  text-transform: uppercase;

  :hover {
    background: ${props => darken(0.1, props.theme.colors.accent1)};
  }

  @media ${device.tablet} {
    margin-top: 20px;
    margin-bottom: 10px;
    line-height: 40px;
  }
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: ${props => props.theme.colors.light};
  min-height: 550px;

  @media ${device.tablet} {
    min-height: auto;
  }
`

export const LocationImageSection = styled.div`
  margin: 100px auto -45px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1220px;
  width: 100%;
  max-height: 70vh;
  position: relative;

  @media ${device.tablet} {
    flex-direction: column;
    margin: 0px;
    height: 100%;
    max-height: calc((var(--vh, 1vh) * 100));
  }
`

const DesktopLocationsGridComponent = () => {
  const { navigateTo, t } = useSdk()
  const orgsData = useGetOrganizationsQuery()

  useHeader({
    content: '',
    drawer: <ClientAppDrawer />,
  })

  const myOrgs = orgsData?.data?.market?.organizations?.my || []
  const otherOrgs = orgsData?.data?.market?.organizations?.other || []

  const orgs = [...uniq(myOrgs), ...otherOrgs]

  const isMobile = useContext(MobileContext)

  const listRef = useRef<any>()

  return (
    <AnimatedDiv isHome>
      <Helmet>
        {getCurrentLanguage() === 'en' ? (
          <title>{'Online booking'}</title>
        ) : (
          <title>{'E-naručivanje'}</title>
        )}
      </Helmet>

      <LoacationSection>
        <LocationGridHeader>
          <LocationImageSection>
            <LocationsGridTitle>
              <AkdLogo src={process.env.PUBLIC_URL + '/assets/eRed-logo.svg'} />
              <TitleBig>
                {t('translation.clientRoutes.selectLocation')}
              </TitleBig>
              <span>{t('translation.clientRoutes.requestAppointment')}</span>
              <OrderButton
                onClick={() => {
                  listRef.current.scrollIntoView({
                    behavior: 'smooth',
                  })
                }}
              >
                {t('translation.clientRoutes.bookAppointment')}
              </OrderButton>
            </LocationsGridTitle>
            <img
              src={process.env.PUBLIC_URL + '/assets/eRed-background.svg'}
              alt={'desktop'}
            />
          </LocationImageSection>
        </LocationGridHeader>
        <HeaderDivider />
      </LoacationSection>
      <Container>
        <DesktopLocationsGrid ref={listRef}>
          {orgsData.loading || !orgs[0] ? (
            <Loader isComponent />
          ) : (
            orgs.map(location => (
              <DesktopLocationsItem
                data-cy={location?.orgName}
                onClick={() => {
                  location?.locations?.length > 1
                    ? navigateTo.selectedOrg({ orgId: location?.id })
                    : navigateTo.selectedLocation({
                        orgId: location?.id,
                        locationId: location?.locations?.[0].id,
                      })
                  localStorage.removeItem('selectedServices')
                }}
              >
                <DesktopLocationsLink>
                  <DesktopLocationImage src={getLocationImage(location)} />
                  <DesktopLocationsImageDesc>
                    <DesktopLocationCardTitle>
                      {location.orgName}
                    </DesktopLocationCardTitle>
                    <DesktopLocationCardSubtitle>
                      {location.description}
                    </DesktopLocationCardSubtitle>

                    <DesktopLocationLink>
                      {t('translation.LocationsGrid.label-visitLocation')}
                    </DesktopLocationLink>
                  </DesktopLocationsImageDesc>
                </DesktopLocationsLink>
              </DesktopLocationsItem>
            ))
          )}
        </DesktopLocationsGrid>
      </Container>
      {isMobile && <Footer />}
    </AnimatedDiv>
  )
}

export const LoacationSection = styled.div`
  @media ${device.tablet} {
    height: 100%;
    min-height: 100%;
  }
`

export const HeaderDivider = styled.div`
  width: 100%;
  height: 70px;
  min-height: 70px;
  background: ${props => props.theme.colors.lightBackground};
`

export const LocationGridHeader = styled.div`
  max-width: 1300px;
  padding: 0px 40px;
  min-height: 500px;
  min-width: 100%;
  max-height: 70vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
  background: linear-gradient(45deg, #1a74bb, #004187);

  img {
    max-width: 60%;
    width: 50%;
    margin-left: auto;
  }

  @media ${device.tablet} {
    height: calc(100% - 70px);
    max-height: calc((var(--vh, 1vh) * 100));

    img {
      max-width: 100%;
      margin-left: 0px;
      margin-bottom: -30px;
      margin-top: auto;
      width: calc((var(--vh, 1vh) * 100 - 360px));
    }
  }
`

export const DesktopLocationTriangle = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 100px 100px 0;
  border-color: transparent #deb2a7 transparent transparent;
  right: 0px;
`

export const AkdLogo = styled.img`
  position: absolute;
  max-width: 130px !important;
  bottom: 115px;
  z-index: 0;

  @media ${device.tablet} {
    position: relative;
    min-width: 110px;
    margin-bottom: -30px !important;
    max-width: 85px !important;
    bottom: 0px;
  }
`

export const TitleBig = styled.span`
  font-size: 42px;
  z-index: 999;

  @media ${device.tablet} {
    font-size: 28px;
  }
`
export const LocationsGridTitle = styled.div`
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  font-size: 38px;
  font-weight: 500;
  line-height: 46px;
  color: ${props => props.theme.colors.light};
  position: relative;

  @media ${device.tablet} {
    margin: auto;
    font-size: 24px;
    line-height: 34px;
  }
`
export default DesktopLocationsGridComponent
