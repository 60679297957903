import React from 'react'
import { slide as SlderMenu } from 'react-burger-menu'
import { SidebarRow } from '../layout/styles'
import { ChevronRight } from 'components/Icon/Icons/ChevronRight'
import { NavigationTitle, NavigationButton } from 'styles'
import styled from 'styled-components'
import Button from '../components/Button/Button'

export const Container = styled.div<{ isDark?: boolean; ref?: any }>`
  .bm-burger-button {
    position: ${props => (!props.isDark ? 'absolute' : 'sticky')};
    margin-right: ${props => (!props.isDark ? '0px' : '15px')};
    width: 35px;
    height: 35px;
    right: 15px;
    top: ${props => (!props.isDark ? '10px' : '15px')};
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    width: 50px !important;
    height: 50px !important;
    right: 0px !important;
    top: 0px !important;
    padding: 15px;
  }

  /*
Sidebar wrapper styles
*/
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    top: 0px;
  }

  /* General sidebar styles */
  .bm-menu {
    background: ${props => props.theme.colors.light};

    font-size: 1.15em;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    top: 0px;
    left: 0px;
  }
`

export const Menu = React.forwardRef((props: any, ref: any) => {
  const { children, isOpen, handleMenuOpenChange, isDark } = props
  return (
    <Container isDark={isDark}>
      <SlderMenu
        right
        isOpen={isOpen}
        onStateChange={handleMenuOpenChange}
        customBurgerIcon={
          <img
            src={process.env.PUBLIC_URL + '/assets/navigation-menu2.svg'}
            alt="Navigaion menu"
          />
        }
        customCrossIcon={
          <img
            src={process.env.PUBLIC_URL + '/assets/icons/close.svg'}
            alt="Close"
          />
        }
      >
        <div ref={ref}>{children}</div>
      </SlderMenu>
    </Container>
  )
})
type TMenuItemProps = {
  label?: string
  name?: string
  cy?: string | number
  icon?: any
  onClick?: () => any
  isInstallButton?: boolean
}
export const MenuItem = (props: TMenuItemProps) => {
  const { label, name, cy, icon, onClick, isInstallButton } = props
  return isInstallButton ? (
    <InstallWrapper onClick={onClick}>
      <SidebarRow style={{ flex: 1 }}>
        <InstallTitle id={name} data-cy={cy}>
          {label}
        </InstallTitle>
      </SidebarRow>
      <Button label={'Preuzmi'} buttonType={'secondary'} size={'small'} />
    </InstallWrapper>
  ) : (
    <NavigationButton onClick={onClick}>
      {icon}
      <SidebarRow style={{ flex: 1 }}>
        <NavigationTitle id={name} data-cy={cy}>
          {label}
        </NavigationTitle>
      </SidebarRow>
      <ChevronRight stroke={2} size={'extraSmall'} style={{ color: '#333' }} />
    </NavigationButton>
  )
}

export const InstallWrapper = styled(NavigationButton)`
  background: ${props => props.theme.colors.primary};
  color: white;
  margin-left: -20px;
  margin-right: -20px;
  padding: 40px;
`

export const InstallTitle = styled.span`
  color: ${props => props.theme.colors.light};
`
