export const fontFamily = 'Montserrat, sans-serif'
//"system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, sans-serif";

export const fontSizeSmall = '0.857rem'
export const fontSize = '1rem'
export const fontSizeBigger = '1.143rem'

export const fontWeight = 400 // Normal
export const fontWeightMedium = 500
export const fontWeightBold = 700

export const lineHeight = '28px'
export const lineHeightSmall = '1rem'
