import React from 'react'
import {
  FooterColumn,
  FooterContainer,
  FooterFlex,
  FooterLink,
  FooterList,
  FooterListItem,
  FooterLogo,
  FooterSide,
  FooterSideRight,
  FooterText,
  FooterWrapper,
} from './styles'
import { publicAssets } from 'assets/publicAssets'
import { useSdk } from 'sdk'

const Footer = () => {
  const { t } = useSdk()

  return (
    <FooterContainer>
      <FooterWrapper>
        <FooterFlex>
          <FooterSide>
            <FooterLink>
              <FooterLogo src={publicAssets['akd-logo-white.svg']} />
            </FooterLink>
            <FooterText>{t('translation.Footer.label-footerText')}</FooterText>
            <FooterText>{t('translation.Footer.label-faq')}</FooterText>
          </FooterSide>
          <FooterSideRight>
            <FooterColumn>
              <FooterList role="list">
                <FooterListItem>
                  <a href={process.env.PUBLIC_URL + '/o-nama'}>
                    {t('translation.Footer.label-aboutUs')}
                  </a>
                </FooterListItem>
                <FooterListItem>
                  <a href={process.env.PUBLIC_URL + '/kontakt'}>
                    {t('translation.Footer.label-contact')}
                  </a>
                </FooterListItem>
              </FooterList>
            </FooterColumn>
            <FooterColumn>
              <FooterList role="list">
                <FooterListItem>
                  <a href={process.env.PUBLIC_URL + '/politika-privatnosti'}>
                    {t('translation.Footer.label-privacyPolicy')}
                  </a>
                </FooterListItem>
                <FooterListItem>
                  <a href={process.env.PUBLIC_URL + '/politika-kolacica'}>
                    {t('translation.Footer.label-cookies')}
                  </a>
                </FooterListItem>
                <FooterListItem>
                  <a href={process.env.PUBLIC_URL + '/uvjeti-koristenja'}>
                    {t('translation.Footer.label-termsOfService')}
                  </a>
                </FooterListItem>
              </FooterList>
            </FooterColumn>
          </FooterSideRight>
        </FooterFlex>
      </FooterWrapper>
    </FooterContainer>
  )
}
export default Footer
