import styled from 'themes'
import { device } from '../../../constants'

export const FooterContainer = styled.footer`
  padding: 0 20px;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  text-align: center;
  display: block;
  margin-top: auto;
  background: linear-gradient(45deg, #004187, #1a74bb);
`

export const FooterWrapper = styled.div`
  display: block;
  max-width: 1220px;
  margin-top: 0;
  margin-left: auto;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
  grid-auto-columns: 1fr;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  position: relative;
  width: 100%;
  margin-right: auto;
`

export const FooterFlex = styled.div`
  display: flex;
  height: auto;
  margin-bottom: 0;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: #666;
  text-align: left;
`

export const FooterSide = styled.div`
  width: 50%;
  padding-top: 40px;
  padding-right: 60px;
  padding-bottom: 70px;

  @media ${device.tablet} {
    width: 100%;
  }
`

export const FooterLink = styled.a`
  max-height: 60px;
  min-width: 70px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  color: #333;
  -o-object-fit: fill;
  object-fit: fill;
  text-decoration: none;
  max-width: 100%;
  display: inline-block;
`

export const FooterLogo = styled.img`
  max-width: 100%;
  border: 0;
  vertical-align: middle;
  display: inline-block;
  width: 90px;
`

export const FooterText = styled.p`
  color: ${props => props.theme.colors.light};
  font-size: 14px;
  line-height: 34px;
  padding-top: 10px;
`

export const FooterSocialWrapper = styled.div`
  margin-top: 40px;
  padding-top: 0;
  padding-bottom: 0;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
  position: static;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
`

export const FooterSocialLink = styled.a`
  position: relative;
  display: inline-block;
  margin-right: 0;
  margin-left: 0;
`
type TFooterSocialFacebookProps = {
  isDark?: boolean
}
export const FooterSocialFacebook = styled.div<TFooterSocialFacebookProps>`
  display: flex;
  margin-right: 4px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #000;
  border-radius: 0;
  background-image: url(https://uploads-ssl.webflow.com/5f104abb3aa5a96156dbb059/5f104abc6d13576a4b28ca15_facebook-logo%20Black.svg);
  background-size: 16px;
  opacity: 0.6;
  filter: ${props => (props.isDark ? 'none' : ' invert(100%)')};
  -webkit-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
  width: 32px;
  height: 32px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  :hover {
    opacity: 1;
  }
`
type TFooterSocialFacebookInstaProps = {
  isDark?: boolean
}
export const FooterSocialInsta = styled.div<TFooterSocialFacebookInstaProps>`
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #000;
  border-radius: 0;
  background-size: 16px;

  opacity: 0.6;
  filter: ${props => (props.isDark ? 'none' : ' invert(100%)')};
  -webkit-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
  margin-right: 3px;
  background-image: url('https://uploads-ssl.webflow.com/5f104abb3aa5a96156dbb059/5f104abc6d1357059228ca6e_instagram%20(1).png');
  width: 32px;
  height: 32px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  :hover {
    opacity: 1;
  }
`

export const FooterSocialLinkedIn = styled.div`
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #ffffff;
  border-radius: 0;
  background-size: 16px;
  opacity: 0.6;
  -webkit-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
  margin-right: 3px;
  background-image: url(https://uploads-ssl.webflow.com/5f104abb3aa5a96156dbb059/5f423fbf78d43f33a5de8873_linkedIn.svg);
  width: 32px;
  height: 32px;
  background-position: 50% 50%;
  background-repeat: no-repeat;

  :hover {
    opacity: 1;
  }
`

export const FooterSocialYouTube = styled.div`
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: #000;
  border-radius: 0;
  background-size: 16px;
  opacity: 0.6;
  -webkit-filter: invert(100%);
  filter: invert(100%);
  -webkit-transition: background-color 0.2s ease;
  transition: background-color 0.2s ease;
  margin-right: 3px;
  background-image: url(https://uploads-ssl.webflow.com/5f104abb3aa5a96156dbb059/5fd0719af788d64f615ba879_social-video-youtube-clip.svg);
  width: 32px;
  height: 32px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  :hover {
    opacity: 1;
  }
`

export const FooterSideRight = styled.div`
  display: -webkit-flex;
  display: flex;
  padding-top: 90px;
  padding-right: 15px;
  padding-left: 60px;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 50%;
  padding-bottom: 30px;
  justify-content: flex-end;

  @media ${device.tablet} {
    display: none;
  }
`

export const FooterColumn = styled.div`
  width: 200px;
  margin-bottom: 20px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  text-align: left;
`

export const FooterList = styled.ul`
  padding-left: 0;
  list-style: none;
`

export const FooterListItem = styled.li`
  font-size: 16px;
  color: #30364d;
  line-height: 30px;
  display: list-item;
  text-align: -webkit-match-parent;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  a {
    color: ${props => props.theme.colors.light};
    display: inline-block;
    margin-bottom: 0;
    border-bottom: 2px solid transparent;
    -webkit-transition: border 0.3s ease, color 0.3s ease;
    transition: border 0.3s ease, color 0.3s ease;
    font-size: 14px;
    line-height: 30px;
    text-decoration: none;

    :hover {
      border-bottom: 2px solid ${props => props.theme.colors.light};
    }
  }
`

export const FooterCopyright = styled.div`
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 1px solid #e0e0e0;
  color: #666;
  border-top-color: rgba(210, 210, 245, 0.2);
`

export const FooterCopyrightLeft = styled.div`
  font-size: 14px;
  line-height: 22px;
  color: #fff;

  a {
    display: inline-block;
    margin-bottom: 0;
    border-bottom: 2px solid transparent;
    -webkit-transition: border 0.3s ease, color 0.3s ease;
    transition: border 0.3s ease, color 0.3s ease;
    color: #30364d;
    font-size: 14px;
    color: #d2d2f5;
    line-height: 30px;
    text-decoration: none;
  }
`

export const FooterCopyrightRight = styled.div`
  color: #fff;
  display: flex;
  font-weight: 400;

  a {
    display: inline-block;
    margin-bottom: 0;
    border-bottom: 2px solid transparent;
    -webkit-transition: border 0.3s ease, color 0.3s ease;
    transition: border 0.3s ease, color 0.3s ease;
    color: #30364d;
    font-size: 14px;
    color: #e3e3e3;
    margin-right: 4px;
    margin-left: 4px;
  }
`
