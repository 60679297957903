import React from 'react'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useSdk } from 'sdk'
import {
  MarketMeDocument,
  useLoginMutation,
  useMarketMeLazyQuery,
  useMarketMeQuery,
} from 'state/graphql'
export const UserContext = React.createContext<{
  user?: any
  setUser: (user) => any
}>({ setUser: () => {} })
export const useAuth = () => {
  let storedToken = localStorage.getItem('token')
  const [initialUserLoad, setInitialUserLoad] = useState(!!storedToken)
  const { user, setUser } = useContext(UserContext)
  const { appServices, apolloClient } = useSdk()
  const [loginMutation, { loading: loadingLogin }] = useLoginMutation()
  const [
    getMeQuery,
    { data: userData, loading: loadingUser, refetch, called },
  ] = useMarketMeLazyQuery()

  useEffect(() => {
    if (!storedToken) return
    getMeQuery()
  }, [storedToken])

  useEffect(() => {
    if (loadingUser || !called) return
    if (userData?.market?.me) {
      setUser(userData?.market?.me)
    }
    setInitialUserLoad(false)
  }, [userData, loadingUser, called])

  const signOut = useCallback(() => {
    localStorage.removeItem('token')
    setUser(null)
  }, [setUser])
  const signIn = useCallback(
    async (userName: string, password: string) => {
      const result = await loginMutation({ variables: { userName, password } })

      appServices.handleMutationResult(result, '').onSuccess(() => {
        const token = result.data?.market.login.token
        localStorage.setItem('token', token!)
        setUser(result!.data!.market.login.user)
      })
      return result?.data?.market?.login?.user
    },
    [setUser, appServices]
  )
  const setToken = useCallback(
    async (token: string) => {
      localStorage.setItem('token', token)
      const result = await apolloClient.query({
        query: MarketMeDocument,
        fetchPolicy: 'network-only',
      })
    },
    [localStorage, refetch, getMeQuery, called]
  )
  const refetchUser = useCallback(() => {
    if (!refetch) return
    refetch()
  }, [refetch])
  let loggedOnUser = user || userData?.market?.me
  return {
    isSignedIn: !!loggedOnUser,
    inProgress: loadingUser || loadingLogin || initialUserLoad,
    token: storedToken,
    user: loggedOnUser,
    signIn,
    signOut,
    refetchUser,
    setToken,
  }
}
