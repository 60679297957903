import React, {
  ChangeEventHandler,
  InputHTMLAttributes,
  useCallback,
  MouseEventHandler,
  useContext,
} from 'react'
import { useField } from 'formik'
import { useFormContext } from '../Form'
import styled from 'styled-components'
import {
  grayLight,
  grayDarker,
  grayDark,
  red,
  redLight,
  redLighter,
  white,
  blueLightBorder,
  blueBorder,
} from '../../style/colors'
import { fontFamily, fontSize, fontWeight, lineHeight } from '../../style/fonts'
import { FieldContainer } from '../FieldContainer'
import { MobileContext } from 'App'

export const StyledInput = styled.input<any>`
  position: relative;
  box-sizing: border-box;
  margin: ${props => (props.size === 'small' ? '0 0 8px 0' : '0px')};
  background: ${({ hasError }) => (hasError ? redLight : white)};
  border-radius: 4px;
  border: 1px solid ${({ hasError }) => (hasError ? red : grayLight)};
  box-shadow: 2px 2px 0 2px transparent;
  transition-property: border-width, border-color, box-shadow;
  transition-duration: 0.1s;
  transition-timing-function: ease-in;
  color: ${({ hasError }) => (hasError ? red : grayDarker)};
  font-family: ${fontFamily};
  font-size: ${fontSize};
  font-weight: ${fontWeight};
  line-height: ${lineHeight};
  padding: 9px 8px 8px 8px;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  ${({ prefix }) => (prefix ? `padding-left: ${prefix.paddingLeft};` : '')};
  cursor: ${props => (props.isCalendar ? 'pointer' : 'inherit')};
  padding-left: ${props => (props.hasIcon ? '50px' : 'auto')};

  &::placeholder {
    color: ${props => props.theme.colors.secondaryDark};
  }

  &:focus {
    border: 1px solid ${({ hasError }) => (hasError ? red : blueBorder)};
    box-shadow: 0px 0px 0px 3px
      ${({ hasError }) => (hasError ? redLighter : blueLightBorder)};
    outline: none;
    transition-property: border-width, border-color, box-shadow;
    transition-duration: 0.1s;
    transition-timing-function: ease-in;
  }

  &:disabled {
    background: ${grayLight};
    border: 1px solid ${grayLight};
    color: ${grayDark};
    cursor: not-allowed;

    &::placeholder {
      color: ${props => props.theme.colors.secondaryDark};
    }
  }
`
type TFormFieldTextProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  label?: string
  multiline?: boolean
  style?: any
  parseValue?: (value?: string) => any
  onClick?: (event: MouseEventHandler<HTMLInputElement>) => any
  icon?: any
  showPassword?: boolean
  helpText?: string
  helpLink?: string
}

export const FormFieldText = React.forwardRef(
  (props: TFormFieldTextProps, ref) => {
    const { parseValue, onChange, icon, ...rest } = props
    const [field, meta] = useField(props)
    const formContext = useFormContext()

    const isMobile = useContext(MobileContext)
    const handleChange = useCallback(
      event => {
        const value = parseValue
          ? parseValue(event?.target.value)
          : event?.target.value
        formContext?.valueChange(field, event, value)
        if (onChange) {
          onChange(value)
        }
      },
      [formContext, field, parseValue, onChange]
    )

    return (
      <FieldContainer
        label={props.label}
        error={meta.error}
        fieldName={field.name}
        type={props.type}
        icon={props.icon}
        helpText={props.helpText}
        helpLink={props.helpLink}
      >
        <StyledInput
          {...rest}
          ref={ref}
          placeholder={props.placeholder}
          name={props.name}
          data-cy={`input_${props.name}`}
          value={field.value || ''}
          onChange={handleChange}
          autoFocus={!isMobile && props.autoFocus}
          autoComplete={props.autoComplete}
          disabled={props.disabled}
        />
      </FieldContainer>
    )
  }
)
FormFieldText.defaultProps = {
  autoComplete: 'off',
  spellCheck: false,
  autoCorrect: 'off',
  autoCapitalize: 'off',
}
