import React from 'react'
import { Text } from 'components'
import styled from 'styled-components'
import { device } from '../../constants'

const StyleSectionTitleContainer = styled.div`
  border-bottom: 1px solid #e1e4e8;
  padding-left: 0.5rem;
  padding-bottom: 8px;
  margin-bottom: 1rem;

  @media ${device.tablet} {
    padding-top: 20px;
  }
`

export const SectionTitle = props => {
  const { title, style } = props
  return (
    <StyleSectionTitleContainer style={style}>
      <Text size="large">{title}</Text>
    </StyleSectionTitleContainer>
  )
}
