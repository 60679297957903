import { DocumentNode } from 'graphql'
import * as Apollo from '@apollo/client'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
  GraphQLDate: any
  GraphQLTime: any
  Upload: any
  GraphQLDateTime: any
  LimitedListDirective: any
  DistinctList: any
}

export enum CacheControlScope {
  Public = 'PUBLIC',
  Private = 'PRIVATE',
}

export type ChangePasswordInput = {
  oldPassword: Scalars['String']
  newPassword: Scalars['String']
}

export type ConfirmOtpInput = {
  inviteId: Scalars['String']
  otp: Scalars['String']
}

export type ConfirmOtpResult = {
  token: Scalars['String']
}

export type ConfirmRegistrationInput = {
  inviteId?: Maybe<Scalars['String']>
  otp?: Maybe<Scalars['String']>
}

export type ConfirmUserRegistrationResult = {
  token: Scalars['String']
  user: MarketUser
}

export type Country = {
  id?: Maybe<Scalars['ID']>
  countryCode: Scalars['String']
  countryName: Scalars['String']
  currency?: Maybe<Currency>
}

export type CountryQuery = {
  get?: Maybe<Country>
  all?: Maybe<Array<Maybe<Country>>>
}

export type CountryQueryGetArgs = {
  id: Scalars['ID']
}

export type Currency = {
  id?: Maybe<Scalars['ID']>
  currencyCode: Scalars['String']
  currencyName: Scalars['String']
}

export type CurrencyQuery = {
  get?: Maybe<Currency>
  all?: Maybe<Array<Maybe<Currency>>>
}

export type CurrencyQueryGetArgs = {
  id: Scalars['ID']
}

export type EmailConfirmedResult = {
  email?: Maybe<Scalars['String']>
  userId?: Maybe<Scalars['ID']>
}

export type FetchParams = {
  pageNumber?: Maybe<Scalars['Int']>
  pageSize?: Maybe<Scalars['Int']>
}

export type FileImportResult = {
  filename?: Maybe<Scalars['String']>
  mimetype?: Maybe<Scalars['String']>
  encoding?: Maybe<Scalars['String']>
  downloadUrl?: Maybe<Scalars['String']>
}

export type FilterCountryInput = {
  searchTerm?: Maybe<Scalars['String']>
  fetch?: Maybe<FetchParams>
}

export type FilterCurrencyInput = {
  searchTerm?: Maybe<Scalars['String']>
  fetch?: Maybe<FetchParams>
}

export type FilterFreeSlotsInput = {
  locationId: Scalars['ID']
  dateFrom: Scalars['GraphQLDate']
  services: Array<Maybe<Scalars['ID']>>
  resourceId?: Maybe<Scalars['ID']>
}

export type FreeSlot = {
  startTime?: Maybe<Scalars['GraphQLTime']>
  resources: Array<Maybe<Scalars['ID']>>
}

export type FreeSlotsResult = {
  date?: Maybe<Scalars['GraphQLDate']>
  timeSlots: Array<Maybe<FreeSlot>>
}

export enum GenderEnum {
  Male = 'Male',
  Female = 'Female',
  Unspecified = 'Unspecified',
}

export type MarketConfirmPasswordResetInput = {
  userName?: Maybe<Scalars['String']>
  otp?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
}

export type MarketFilterResourceBookingInput = {
  dateFrom?: Maybe<Scalars['GraphQLDate']>
  dateTo?: Maybe<Scalars['GraphQLDate']>
  clientId?: Maybe<Scalars['ID']>
}

export type MarketLocation = {
  id?: Maybe<Scalars['ID']>
  orgId?: Maybe<Scalars['String']>
  code: Scalars['String']
  name: Scalars['String']
  isActive: Scalars['Boolean']
  mobilePhone?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  zipCode?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  country?: Maybe<Country>
  workShifts: Array<MarketLocationWorkShift>
  resources: Array<MarketResource>
  description?: Maybe<Scalars['String']>
  imageUrl?: Maybe<Scalars['String']>
  onlineBooking_showPrices: YesNoEnum
  onlineBooking_status: OnOffEnum
  category?: Maybe<Scalars['String']>
  lat?: Maybe<Scalars['Float']>
  lng?: Maybe<Scalars['Float']>
  onlineBooking_showEmail: Scalars['Boolean']
  onlineBooking_showAddress: Scalars['Boolean']
  onlineBooking_showPhone: Scalars['Boolean']
  timeSlotDuration: Scalars['Int']
  onlineBooking_listOnZoyya: Scalars['Boolean']
  facebookUrl?: Maybe<Scalars['String']>
  instagramUrl?: Maybe<Scalars['String']>
  twitterUrl?: Maybe<Scalars['String']>
  emailNote?: Maybe<Scalars['String']>
  onlineBooking_serviceSelection: MarketServiceSelectionEnum
  onlineBooking_minDaysBefore: Scalars['Int']
  onlineBooking_minHrsBefore: Scalars['Int']
  shortDescription?: Maybe<Scalars['String']>
  webUrl?: Maybe<Scalars['String']>
}

export type MarketLocationsResult = {
  my: Array<MarketLocation>
  other: Array<MarketLocation>
}

export type MarketLocationWorkShift = {
  name?: Maybe<Scalars['String']>
  openHours: Array<WorkShiftDaySchedule>
}

export type MarketLoginInput = {
  userName?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
}

export type MarketLoginPayload = {
  token: Scalars['String']
  user: MarketUser
}

export type MarketMutation = {
  uploadAvatar?: Maybe<FileImportResult>
  login: MarketLoginPayload
  requestPasswordReset?: Maybe<Scalars['Boolean']>
  updateProfile?: Maybe<MarketUser>
  registerUser: MarketRegisterUserResult
  confirmPhoneOtp: MarketUser
  confirmUserRegistration: ConfirmUserRegistrationResult
  updateMyProfile: UpdateMyProfileResult
  cancelAppointment?: Maybe<MarketResourceBooking>
  updateEmail?: Maybe<MarketUser>
  updatePhone?: Maybe<MarketUser>
  updateLanguage?: Maybe<MarketUser>
  updateEmailSettings?: Maybe<MarketUser>
  updateNotificationPreferences?: Maybe<NotificationPreferences>
  changePassword?: Maybe<Scalars['Boolean']>
  confirmPasswordReset?: Maybe<MarketRegisterUserPayload>
  sendPhoneOtp?: Maybe<Scalars['Boolean']>
  createBooking?: Maybe<MarketResourceBooking>
  sendConfirmEmail: Scalars['Boolean']
}

export type MarketMutationUploadAvatarArgs = {
  file: Scalars['Upload']
}

export type MarketMutationLoginArgs = {
  input: MarketLoginInput
}

export type MarketMutationRequestPasswordResetArgs = {
  input?: Maybe<PasswordResetInput>
}

export type MarketMutationUpdateProfileArgs = {
  input: UpdateProfileInput
}

export type MarketMutationRegisterUserArgs = {
  input: RegisterClientInput
}

export type MarketMutationConfirmPhoneOtpArgs = {
  input: Scalars['String']
}

export type MarketMutationConfirmUserRegistrationArgs = {
  input: ConfirmRegistrationInput
}

export type MarketMutationUpdateMyProfileArgs = {
  input: UpdateMyProfileInput
}

export type MarketMutationCancelAppointmentArgs = {
  id: Scalars['ID']
}

export type MarketMutationUpdateEmailArgs = {
  input?: Maybe<UpdateEmailInput>
}

export type MarketMutationUpdatePhoneArgs = {
  input?: Maybe<UpdatePhoneInput>
}

export type MarketMutationUpdateLanguageArgs = {
  input?: Maybe<UpdateLanguageInput>
}

export type MarketMutationUpdateEmailSettingsArgs = {
  input?: Maybe<UpdateEmailSettingsInput>
}

export type MarketMutationUpdateNotificationPreferencesArgs = {
  input?: Maybe<UpdateNotificationPreferencesInput>
}

export type MarketMutationChangePasswordArgs = {
  input: ChangePasswordInput
}

export type MarketMutationConfirmPasswordResetArgs = {
  input?: Maybe<MarketConfirmPasswordResetInput>
}

export type MarketMutationCreateBookingArgs = {
  input?: Maybe<MarketResourceBookingInput>
}

export type MarketOrganization = {
  id?: Maybe<Scalars['ID']>
  orgName?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  vatId?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  zipCode?: Maybe<Scalars['String']>
  city?: Maybe<Scalars['String']>
  country?: Maybe<Country>
  phone?: Maybe<Scalars['String']>
  mobilePhone?: Maybe<Scalars['String']>
  webUrl?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  avatarUrl?: Maybe<Scalars['String']>
  currency?: Maybe<Currency>
  myLocations: Array<MarketLocation>
  locations: Array<MarketLocation>
  language?: Maybe<Scalars['String']>
  orgUrlName?: Maybe<Scalars['String']>
  color?: Maybe<Scalars['String']>
}

export type MarketOrganizationsResult = {
  my: Array<MarketOrganization>
  other: Array<MarketOrganization>
}

export type MarketProfession = {
  id?: Maybe<Scalars['ID']>
  name: Scalars['String']
  resources: Array<MarketResource>
}

export type MarketQuery = {
  freeSlots: Array<Maybe<FreeSlotsResult>>
  locations: MarketLocationsResult
  services: Array<MarketService>
  serviceTypes: Array<MarketServiceType>
  getLocation: MarketLocation
  professions: Array<MarketProfession>
  organizations: MarketOrganizationsResult
  getOrganization?: Maybe<MarketOrganization>
  getBooking?: Maybe<MarketResourceBooking>
  getByOrgUrlName: MarketOrganization
  me?: Maybe<MarketUser>
  getNotificationPreferences?: Maybe<NotificationPreferences>
  myBookings: Array<MarketResourceBooking>
}

export type MarketQueryFreeSlotsArgs = {
  input: FilterFreeSlotsInput
}

export type MarketQueryServicesArgs = {
  locationId?: Maybe<Scalars['ID']>
}

export type MarketQueryServiceTypesArgs = {
  locationId?: Maybe<Scalars['ID']>
}

export type MarketQueryGetLocationArgs = {
  id?: Maybe<Scalars['ID']>
}

export type MarketQueryGetOrganizationArgs = {
  id?: Maybe<Scalars['ID']>
}

export type MarketQueryGetBookingArgs = {
  id?: Maybe<Scalars['ID']>
}

export type MarketQueryGetByOrgUrlNameArgs = {
  orgUrlName?: Maybe<Scalars['String']>
}

export type MarketQueryGetNotificationPreferencesArgs = {
  token?: Maybe<Scalars['String']>
}

export type MarketQueryMyBookingsArgs = {
  input: MarketFilterResourceBookingInput
}

export type MarketRegisterUserPayload = {
  user?: Maybe<MarketUser>
  token?: Maybe<Scalars['String']>
}

export type MarketRegisterUserResult = {
  inviteId: Scalars['String']
}

export type MarketResource = {
  id?: Maybe<Scalars['ID']>
  code: Scalars['String']
  name: Scalars['String']
  color?: Maybe<Scalars['String']>
  kind?: Maybe<MarketResourceKindEnum>
  professions: Array<MarketProfession>
  locationSettings?: Maybe<MarketResourceLocation>
}

export type MarketResourceLocationSettingsArgs = {
  locationId?: Maybe<Scalars['ID']>
}

export type MarketResourceBooking = {
  id?: Maybe<Scalars['ID']>
  location: MarketLocation
  resource?: Maybe<MarketResource>
  startTime: Scalars['GraphQLTime']
  endTime: Scalars['GraphQLTime']
  description?: Maybe<Scalars['String']>
  durationMinutes?: Maybe<Scalars['Int']>
  services: Array<MarketService>
  status?: Maybe<Scalars['String']>
  appointmentId?: Maybe<Scalars['String']>
  message?: Maybe<Scalars['String']>
}

export type MarketResourceBookingInput = {
  id?: Maybe<Scalars['ID']>
  resourceId?: Maybe<Scalars['ID']>
  serviceId?: Maybe<Scalars['ID']>
  date: Scalars['GraphQLDate']
  startTime: Scalars['GraphQLTime']
  duration: Scalars['GraphQLTime']
  description?: Maybe<Scalars['String']>
  services?: Maybe<Array<Maybe<Scalars['ID']>>>
  message?: Maybe<Scalars['String']>
}

export enum MarketResourceKindEnum {
  User = 'USER',
  Resource = 'RESOURCE',
}

export type MarketResourceLocation = {
  resourceId?: Maybe<Scalars['ID']>
  locationId?: Maybe<Scalars['ID']>
  onlineBooking?: Maybe<OnLineBookingSettingEnum>
}

export type MarketService = {
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  color?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  bookingAllowed: Scalars['Boolean']
  durationMinutes?: Maybe<Scalars['Int']>
  price?: Maybe<Scalars['Float']>
  type?: Maybe<MarketServiceType>
  profession?: Maybe<MarketProfession>
  resources?: Maybe<Array<Maybe<MarketResource>>>
  sequence: Scalars['Int']
  hasBookingConfirmation: Scalars['Boolean']
}

export enum MarketServiceSelectionEnum {
  Many = 'MANY',
  One = 'ONE',
}

export type MarketServiceType = {
  id?: Maybe<Scalars['ID']>
  name?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  services: Array<MarketService>
}

export type MarketUser = {
  id: Scalars['ID']
  userName: Scalars['String']
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  avatarUrl?: Maybe<Scalars['String']>
  phone?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  gender?: Maybe<GenderEnum>
  role?: Maybe<UserRoleEnum>
  nickName?: Maybe<Scalars['String']>
  isEmailConfirmed?: Maybe<Scalars['Boolean']>
  isMobilePhoneConfirmed?: Maybe<Scalars['Boolean']>
  hasLocalAccount?: Maybe<Scalars['Boolean']>
  hasFbAccount?: Maybe<Scalars['Boolean']>
  hasGoogleAccount?: Maybe<Scalars['Boolean']>
  google_profilePicUrl?: Maybe<Scalars['String']>
  google_firstName?: Maybe<Scalars['String']>
  google_lastName?: Maybe<Scalars['String']>
  google_email?: Maybe<Scalars['String']>
  google_phone?: Maybe<Scalars['String']>
  fb_profilePicUrl?: Maybe<Scalars['String']>
  fb_firstName?: Maybe<Scalars['String']>
  fb_lastName?: Maybe<Scalars['String']>
  fb_email?: Maybe<Scalars['String']>
  fb_phone?: Maybe<Scalars['String']>
  language?: Maybe<Scalars['String']>
  notificationSettingsUrl?: Maybe<Scalars['String']>
  emailNotificationSettings?: Maybe<UserEmailSettings>
}

export type Mutation = {
  serverVersion?: Maybe<Scalars['String']>
  market: MarketMutation
}

export type NotificationPreferences = {
  userId?: Maybe<Scalars['ID']>
  clientId?: Maybe<Scalars['ID']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  sendHelpEmail?: Maybe<Scalars['Boolean']>
  sendNewsEmail?: Maybe<Scalars['Boolean']>
  sendBookingEmail?: Maybe<Scalars['Boolean']>
  sendReminderEmail?: Maybe<Scalars['Boolean']>
}

export enum OnLineBookingSettingEnum {
  Allowed = 'ALLOWED',
  Disabled = 'DISABLED',
}

export enum OnOffEnum {
  On = 'ON',
  Off = 'OFF',
}

export type OperationStatus = {
  success: Scalars['Boolean']
  errorMessage?: Maybe<Scalars['String']>
}

export type PasswordResetInput = {
  sendTo?: Maybe<Scalars['String']>
}

export type Query = {
  serverVersion?: Maybe<Scalars['String']>
  market: MarketQuery
  currency?: Maybe<CurrencyQuery>
  country?: Maybe<CountryQuery>
}

export type RegisterClientInput = {
  orgId?: Maybe<Scalars['String']>
  mobilePhone?: Maybe<Scalars['String']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
  email?: Maybe<Scalars['String']>
  userName?: Maybe<Scalars['String']>
  language?: Maybe<Scalars['String']>
}

export type RegisterClientResult = {
  inviteId: Scalars['String']
}

export enum ResourceBookingStatusEnum {
  Confirmed = 'confirmed',
  Canceled = 'canceled',
}

export enum ResourceKindEnum {
  User = 'USER',
  Resource = 'RESOURCE',
}

export type Subscription = {
  emailConfirmed?: Maybe<EmailConfirmedResult>
}

export enum TimeSlotKindEnum {
  Off = 'OFF',
  Busy = 'BUSY',
  Available = 'AVAILABLE',
  Holiday = 'HOLIDAY',
}

export type UpdateEmailInput = {
  userId?: Maybe<Scalars['ID']>
  email?: Maybe<Scalars['String']>
}

export type UpdateEmailSettingsInput = {
  userId?: Maybe<Scalars['ID']>
  sendHelpEmail?: Maybe<Scalars['Boolean']>
  sendNewsEmail?: Maybe<Scalars['Boolean']>
  sendBookingEmail?: Maybe<Scalars['Boolean']>
  sendReminderEmail?: Maybe<Scalars['Boolean']>
}

export type UpdateLanguageInput = {
  userId?: Maybe<Scalars['ID']>
  language?: Maybe<Scalars['String']>
}

export type UpdateMeInput = {
  id?: Maybe<Scalars['ID']>
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  gender?: Maybe<GenderEnum>
  nickName?: Maybe<Scalars['String']>
}

export type UpdateMyProfileInput = {
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  gender?: Maybe<GenderEnum>
  nickName?: Maybe<Scalars['String']>
}

export type UpdateMyProfileResult = {
  success?: Maybe<Scalars['Boolean']>
  errorMessage?: Maybe<Scalars['String']>
  payload?: Maybe<MarketUser>
}

export type UpdateNotificationPreferencesInput = {
  token?: Maybe<Scalars['String']>
  sendHelpEmail?: Maybe<Scalars['Boolean']>
  sendNewsEmail?: Maybe<Scalars['Boolean']>
  sendBookingEmail?: Maybe<Scalars['Boolean']>
  sendReminderEmail?: Maybe<Scalars['Boolean']>
}

export type UpdatePhoneInput = {
  userId?: Maybe<Scalars['ID']>
  phone?: Maybe<Scalars['String']>
}

export type UpdateProfileInput = {
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  gender?: Maybe<GenderEnum>
  nickName?: Maybe<Scalars['String']>
}

export type UserEmailSettings = {
  id?: Maybe<Scalars['ID']>
  sendHelpEmail?: Maybe<Scalars['Boolean']>
  sendNewsEmail?: Maybe<Scalars['Boolean']>
  sendBookingEmail?: Maybe<Scalars['Boolean']>
  sendReminderEmail?: Maybe<Scalars['Boolean']>
}

export enum UserRoleEnum {
  Owner = 'OWNER',
  Admin = 'ADMIN',
  User = 'USER',
  Client = 'CLIENT',
}

export type WorkShiftDaySchedule = {
  day: Scalars['Int']
  date?: Maybe<Scalars['GraphQLDate']>
  dayName: Scalars['String']
  startTime?: Maybe<Scalars['GraphQLTime']>
  endTime?: Maybe<Scalars['GraphQLTime']>
  dayOff?: Maybe<Scalars['Boolean']>
}

export type WorkShiftDayScheduleInput = {
  day: Scalars['Int']
  startTime: Scalars['GraphQLTime']
  endTime: Scalars['GraphQLTime']
  dayOff?: Maybe<Scalars['Boolean']>
  dayName: Scalars['String']
}

export enum YesNoEnum {
  Yes = 'YES',
  No = 'NO',
}

export type ResourceBookingFragment = Pick<
  MarketResourceBooking,
  'id' | 'status' | 'startTime' | 'endTime' | 'durationMinutes' | 'description'
> & {
  resource?: Maybe<Pick<MarketResource, 'id' | 'name'>>
  location: Pick<MarketLocation, 'id'>
  services: Array<
    Pick<MarketService, 'id' | 'name' | 'color' | 'price' | 'durationMinutes'>
  >
}

export type ServiceFragment = Pick<
  MarketService,
  | 'id'
  | 'name'
  | 'color'
  | 'price'
  | 'description'
  | 'bookingAllowed'
  | 'durationMinutes'
  | 'hasBookingConfirmation'
  | 'sequence'
> & {
  profession?: Maybe<Pick<MarketProfession, 'id' | 'name'>>
  type?: Maybe<Pick<MarketServiceType, 'id' | 'name' | 'description'>>
}

export type UserFragment = Pick<
  MarketUser,
  | 'id'
  | 'userName'
  | 'firstName'
  | 'lastName'
  | 'avatarUrl'
  | 'nickName'
  | 'email'
  | 'language'
  | 'phone'
  | 'address'
  | 'gender'
  | 'isEmailConfirmed'
  | 'isMobilePhoneConfirmed'
  | 'hasLocalAccount'
  | 'hasFbAccount'
  | 'hasGoogleAccount'
  | 'google_profilePicUrl'
  | 'fb_profilePicUrl'
  | 'role'
  | 'google_email'
  | 'google_firstName'
  | 'google_lastName'
  | 'google_phone'
  | 'fb_email'
  | 'fb_firstName'
  | 'fb_lastName'
  | 'fb_phone'
> & {
  emailNotificationSettings?: Maybe<
    Pick<
      UserEmailSettings,
      | 'sendBookingEmail'
      | 'sendHelpEmail'
      | 'sendNewsEmail'
      | 'sendReminderEmail'
    >
  >
}

export type ConfirmPhoneOtpMutationVariables = Exact<{
  input: Scalars['String']
}>

export type ConfirmPhoneOtpMutation = {
  market: { confirmPhoneOtp: UserFragment }
}

export type ConfirmUserRegistrationMutationVariables = Exact<{
  input: ConfirmRegistrationInput
}>

export type ConfirmUserRegistrationMutation = {
  market: {
    confirmUserRegistration: Pick<ConfirmUserRegistrationResult, 'token'> & {
      user: UserFragment
    }
  }
}

export type CreateBookingMutationVariables = Exact<{
  input: MarketResourceBookingInput
}>

export type CreateBookingMutation = {
  market: { createBooking?: Maybe<ResourceBookingFragment> }
}

export type LoginMutationVariables = Exact<{
  userName?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
}>

export type LoginMutation = {
  market: { login: Pick<MarketLoginPayload, 'token'> & { user: UserFragment } }
}

export type RegisterUserMutationVariables = Exact<{
  input: RegisterClientInput
}>

export type RegisterUserMutation = {
  market: { registerUser: Pick<MarketRegisterUserResult, 'inviteId'> }
}

export type MarketCancelAppointmentMutationVariables = Exact<{
  id: Scalars['ID']
}>

export type MarketCancelAppointmentMutation = {
  market: {
    cancelAppointment?: Maybe<
      Pick<MarketResourceBooking, 'id' | 'message'> & {
        services: Array<Pick<MarketService, 'id' | 'name'>>
      }
    >
  }
}

export type SendConfirmEmailMutationVariables = Exact<{ [key: string]: never }>

export type SendConfirmEmailMutation = {
  market: Pick<MarketMutation, 'sendConfirmEmail'>
}

export type SendPhoneOtpMutationVariables = Exact<{ [key: string]: never }>

export type SendPhoneOtpMutation = {
  market: Pick<MarketMutation, 'sendPhoneOtp'>
}

export type UpdateEmailSettingsMutationVariables = Exact<{
  input: UpdateEmailSettingsInput
}>

export type UpdateEmailSettingsMutation = {
  market: { updateEmailSettings?: Maybe<UserFragment> }
}

export type UserUpdateNotificationPreferencesMutationVariables = Exact<{
  input?: Maybe<UpdateNotificationPreferencesInput>
}>

export type UserUpdateNotificationPreferencesMutation = {
  market: {
    updateNotificationPreferences?: Maybe<
      Pick<
        NotificationPreferences,
        | 'userId'
        | 'sendBookingEmail'
        | 'sendHelpEmail'
        | 'sendNewsEmail'
        | 'sendReminderEmail'
      >
    >
  }
}

export type UpdateUserEmailMutationVariables = Exact<{
  input: UpdateEmailInput
}>

export type UpdateUserEmailMutation = {
  market: { updateEmail?: Maybe<UserFragment> }
}

export type UpdateUserLanguageMutationVariables = Exact<{
  input: UpdateLanguageInput
}>

export type UpdateUserLanguageMutation = {
  market: { updateLanguage?: Maybe<UserFragment> }
}

export type UpdateUserPhoneMutationVariables = Exact<{
  input: UpdatePhoneInput
}>

export type UpdateUserPhoneMutation = {
  market: { updatePhone?: Maybe<UserFragment> }
}

export type UploadAvatarMutationVariables = Exact<{
  file: Scalars['Upload']
}>

export type UploadAvatarMutation = {
  market: { uploadAvatar?: Maybe<Pick<FileImportResult, 'filename'>> }
}

export type UserConfirmPasswordResetMutationVariables = Exact<{
  userName?: Maybe<Scalars['String']>
  otp?: Maybe<Scalars['String']>
  password?: Maybe<Scalars['String']>
}>

export type UserConfirmPasswordResetMutation = {
  market: {
    confirmPasswordReset?: Maybe<Pick<MarketRegisterUserPayload, 'token'>>
  }
}

export type UserMeChangePasswordMutationVariables = Exact<{
  input: ChangePasswordInput
}>

export type UserMeChangePasswordMutation = {
  market: Pick<MarketMutation, 'changePassword'>
}

export type UserMeUpdateMutationVariables = Exact<{
  input: UpdateProfileInput
}>

export type UserMeUpdateMutation = {
  market: { updateProfile?: Maybe<UserFragment> }
}

export type UserRequestPasswordRestMutationVariables = Exact<{
  sendTo?: Maybe<Scalars['String']>
}>

export type UserRequestPasswordRestMutation = {
  market: Pick<MarketMutation, 'requestPasswordReset'>
}

export type CountryAllQueryVariables = Exact<{ [key: string]: never }>

export type CountryAllQuery = {
  country?: Maybe<{
    all?: Maybe<
      Array<
        Maybe<
          Pick<Country, 'id' | 'countryCode' | 'countryName'> & {
            currency?: Maybe<
              Pick<Currency, 'id' | 'currencyCode' | 'currencyName'>
            >
          }
        >
      >
    >
  }>
}

export type CurrencyAllQueryVariables = Exact<{ [key: string]: never }>

export type CurrencyAllQuery = {
  currency?: Maybe<{
    all?: Maybe<
      Array<Maybe<Pick<Currency, 'id' | 'currencyCode' | 'currencyName'>>>
    >
  }>
}

export type FreeSlotsQueryVariables = Exact<{
  locationId: Scalars['ID']
  services: Array<Maybe<Scalars['ID']>>
  dateFrom: Scalars['GraphQLDate']
  resourceId?: Maybe<Scalars['ID']>
}>

export type FreeSlotsQuery = {
  market: {
    freeSlots: Array<
      Maybe<
        Pick<FreeSlotsResult, 'date'> & {
          timeSlots: Array<Maybe<Pick<FreeSlot, 'startTime' | 'resources'>>>
        }
      >
    >
  }
}

export type GetNotificationPreferencesQueryVariables = Exact<{
  token?: Maybe<Scalars['String']>
}>

export type GetNotificationPreferencesQuery = {
  market: {
    getNotificationPreferences?: Maybe<
      Pick<
        NotificationPreferences,
        | 'userId'
        | 'clientId'
        | 'firstName'
        | 'lastName'
        | 'sendHelpEmail'
        | 'sendNewsEmail'
        | 'sendBookingEmail'
        | 'sendReminderEmail'
      >
    >
  }
}

export type GetOrganizationByUrlNameQueryVariables = Exact<{
  orgUrlName?: Maybe<Scalars['String']>
}>

export type GetOrganizationByUrlNameQuery = {
  market: {
    getByOrgUrlName: Pick<
      MarketOrganization,
      'id' | 'orgName' | 'description'
    > & {
      locations: Array<
        Pick<
          MarketLocation,
          | 'id'
          | 'orgId'
          | 'code'
          | 'name'
          | 'isActive'
          | 'mobilePhone'
          | 'description'
          | 'phone'
          | 'email'
          | 'city'
          | 'zipCode'
          | 'address'
          | 'imageUrl'
          | 'onlineBooking_status'
        >
      >
    }
  }
}

export type GetOrganizationsQueryVariables = Exact<{ [key: string]: never }>

export type GetOrganizationsQuery = {
  market: {
    organizations: {
      my: Array<
        Pick<
          MarketOrganization,
          'id' | 'orgName' | 'description' | 'avatarUrl'
        > & { locations: Array<Pick<MarketLocation, 'id' | 'name'>> }
      >
      other: Array<
        Pick<
          MarketOrganization,
          'id' | 'orgName' | 'description' | 'avatarUrl'
        > & { locations: Array<Pick<MarketLocation, 'id' | 'name'>> }
      >
    }
  }
}

export type MarketFreeSlotsQueryVariables = Exact<{
  locationId: Scalars['ID']
  services: Array<Maybe<Scalars['ID']>>
  dateFrom: Scalars['GraphQLDate']
  resourceId?: Maybe<Scalars['ID']>
}>

export type MarketFreeSlotsQuery = {
  market: {
    freeSlots: Array<
      Maybe<
        Pick<FreeSlotsResult, 'date'> & {
          timeSlots: Array<Maybe<Pick<FreeSlot, 'startTime' | 'resources'>>>
        }
      >
    >
  }
}

export type MarketGetLocationQueryVariables = Exact<{
  locationId: Scalars['ID']
}>

export type MarketGetLocationQuery = {
  market: {
    getLocation: Pick<
      MarketLocation,
      | 'id'
      | 'code'
      | 'name'
      | 'orgId'
      | 'description'
      | 'imageUrl'
      | 'facebookUrl'
      | 'instagramUrl'
      | 'emailNote'
      | 'timeSlotDuration'
      | 'onlineBooking_status'
      | 'onlineBooking_showPrices'
      | 'onlineBooking_showAddress'
      | 'onlineBooking_showEmail'
      | 'onlineBooking_showPhone'
      | 'onlineBooking_serviceSelection'
      | 'onlineBooking_minHrsBefore'
      | 'onlineBooking_minDaysBefore'
      | 'shortDescription'
      | 'webUrl'
      | 'mobilePhone'
      | 'phone'
      | 'email'
      | 'city'
      | 'zipCode'
      | 'address'
      | 'lat'
      | 'lng'
    > & {
      workShifts: Array<
        Pick<MarketLocationWorkShift, 'name'> & {
          openHours: Array<
            Pick<
              WorkShiftDaySchedule,
              'day' | 'dayName' | 'startTime' | 'endTime' | 'dayOff'
            >
          >
        }
      >
      country?: Maybe<
        Pick<Country, 'id' | 'countryCode' | 'countryName'> & {
          currency?: Maybe<
            Pick<Currency, 'id' | 'currencyCode' | 'currencyName'>
          >
        }
      >
      resources: Array<
        Pick<MarketResource, 'id' | 'name'> & {
          professions: Array<Pick<MarketProfession, 'id' | 'name'>>
          locationSettings?: Maybe<
            Pick<
              MarketResourceLocation,
              'resourceId' | 'locationId' | 'onlineBooking'
            >
          >
        }
      >
    }
  }
}

export type MarketMeQueryVariables = Exact<{ [key: string]: never }>

export type MarketMeQuery = { market: { me?: Maybe<UserFragment> } }

export type MarketServiceTypesQueryVariables = Exact<{
  locationId?: Maybe<Scalars['ID']>
}>

export type MarketServiceTypesQuery = {
  market: {
    serviceTypes: Array<
      Pick<MarketServiceType, 'id' | 'name' | 'description'> & {
        services: Array<ServiceFragment>
      }
    >
  }
}

export type MarketServicesQueryVariables = Exact<{
  locationId: Scalars['ID']
}>

export type MarketServicesQuery = {
  market: { services: Array<ServiceFragment> }
}

export type MyBookingsQueryVariables = Exact<{
  dateFrom: Scalars['GraphQLDate']
  dateTo: Scalars['GraphQLDate']
}>

export type MyBookingsQuery = {
  market: {
    myBookings: Array<
      Pick<
        MarketResourceBooking,
        | 'id'
        | 'startTime'
        | 'endTime'
        | 'appointmentId'
        | 'status'
        | 'description'
        | 'durationMinutes'
      > & {
        services: Array<
          Pick<
            MarketService,
            'id' | 'name' | 'color' | 'price' | 'durationMinutes'
          >
        >
        resource?: Maybe<Pick<MarketResource, 'id' | 'code' | 'name'>>
        location: Pick<
          MarketLocation,
          | 'id'
          | 'code'
          | 'name'
          | 'imageUrl'
          | 'orgId'
          | 'address'
          | 'city'
          | 'zipCode'
        >
      }
    >
  }
}

export type GetOrganizationQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>
}>

export type GetOrganizationQuery = {
  market: {
    getOrganization?: Maybe<
      Pick<
        MarketOrganization,
        | 'id'
        | 'orgName'
        | 'description'
        | 'avatarUrl'
        | 'webUrl'
        | 'vatId'
        | 'address'
        | 'zipCode'
        | 'phone'
        | 'mobilePhone'
        | 'city'
        | 'orgUrlName'
        | 'email'
      > & {
        country?: Maybe<Pick<Country, 'id' | 'countryName' | 'countryCode'>>
        locations: Array<
          Pick<
            MarketLocation,
            | 'id'
            | 'orgId'
            | 'code'
            | 'name'
            | 'isActive'
            | 'mobilePhone'
            | 'description'
            | 'phone'
            | 'email'
            | 'city'
            | 'zipCode'
            | 'address'
            | 'imageUrl'
            | 'onlineBooking_status'
            | 'shortDescription'
          >
        >
      }
    >
  }
}

export type ProfessionAllQueryVariables = Exact<{ [key: string]: never }>

export type ProfessionAllQuery = {
  market: {
    professions: Array<
      Pick<MarketProfession, 'id' | 'name'> & {
        resources: Array<Pick<MarketResource, 'id' | 'name'>>
      }
    >
  }
}

export type ResourceBookingGetQueryVariables = Exact<{
  id: Scalars['ID']
}>

export type ResourceBookingGetQuery = {
  market: {
    getBooking?: Maybe<
      Pick<
        MarketResourceBooking,
        | 'id'
        | 'startTime'
        | 'endTime'
        | 'durationMinutes'
        | 'status'
        | 'description'
        | 'message'
      > & {
        services: Array<
          Pick<
            MarketService,
            'id' | 'name' | 'color' | 'price' | 'durationMinutes'
          >
        >
        location: Pick<MarketLocation, 'id' | 'name' | 'imageUrl' | 'orgId'>
        resource?: Maybe<Pick<MarketResource, 'id' | 'name'>>
      }
    >
  }
}

export type EmailConfirmedSubscriptionVariables = Exact<{
  [key: string]: never
}>

export type EmailConfirmedSubscription = {
  emailConfirmed?: Maybe<Pick<EmailConfirmedResult, 'email'>>
}

export const ResourceBookingFragmentDoc: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceBookingFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketResourceBooking' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resource' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'durationMinutes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'services' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'durationMinutes' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}
export const ServiceFragmentDoc: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketService' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bookingAllowed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'durationMinutes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasBookingConfirmation' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
}
export const UserFragmentDoc: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketUser' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nickName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailNotificationSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendBookingEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendHelpEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendNewsEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendReminderEmail' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailConfirmed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isMobilePhoneConfirmed' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hasLocalAccount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasFbAccount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasGoogleAccount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'google_profilePicUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_profilePicUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_phone' } },
        ],
      },
    },
  ],
}
export const ConfirmPhoneOtpDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'confirmPhoneOtp' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'String' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'confirmPhoneOtp' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketUser' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nickName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailNotificationSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendBookingEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendHelpEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendNewsEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendReminderEmail' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailConfirmed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isMobilePhoneConfirmed' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hasLocalAccount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasFbAccount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasGoogleAccount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'google_profilePicUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_profilePicUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_phone' } },
        ],
      },
    },
  ],
}
export type ConfirmPhoneOtpMutationFn = Apollo.MutationFunction<
  ConfirmPhoneOtpMutation,
  ConfirmPhoneOtpMutationVariables
>

/**
 * __useConfirmPhoneOtpMutation__
 *
 * To run a mutation, you first call `useConfirmPhoneOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmPhoneOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmPhoneOtpMutation, { data, loading, error }] = useConfirmPhoneOtpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmPhoneOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmPhoneOtpMutation,
    ConfirmPhoneOtpMutationVariables
  >
) {
  return Apollo.useMutation<
    ConfirmPhoneOtpMutation,
    ConfirmPhoneOtpMutationVariables
  >(ConfirmPhoneOtpDocument, baseOptions)
}
export type ConfirmPhoneOtpMutationHookResult = ReturnType<
  typeof useConfirmPhoneOtpMutation
>
export type ConfirmPhoneOtpMutationResult = Apollo.MutationResult<
  ConfirmPhoneOtpMutation
>
export type ConfirmPhoneOtpMutationOptions = Apollo.BaseMutationOptions<
  ConfirmPhoneOtpMutation,
  ConfirmPhoneOtpMutationVariables
>
export const ConfirmUserRegistrationDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'confirmUserRegistration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ConfirmRegistrationInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'confirmUserRegistration' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'UserFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketUser' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nickName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailNotificationSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendBookingEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendHelpEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendNewsEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendReminderEmail' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailConfirmed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isMobilePhoneConfirmed' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hasLocalAccount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasFbAccount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasGoogleAccount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'google_profilePicUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_profilePicUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_phone' } },
        ],
      },
    },
  ],
}
export type ConfirmUserRegistrationMutationFn = Apollo.MutationFunction<
  ConfirmUserRegistrationMutation,
  ConfirmUserRegistrationMutationVariables
>

/**
 * __useConfirmUserRegistrationMutation__
 *
 * To run a mutation, you first call `useConfirmUserRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmUserRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmUserRegistrationMutation, { data, loading, error }] = useConfirmUserRegistrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmUserRegistrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfirmUserRegistrationMutation,
    ConfirmUserRegistrationMutationVariables
  >
) {
  return Apollo.useMutation<
    ConfirmUserRegistrationMutation,
    ConfirmUserRegistrationMutationVariables
  >(ConfirmUserRegistrationDocument, baseOptions)
}
export type ConfirmUserRegistrationMutationHookResult = ReturnType<
  typeof useConfirmUserRegistrationMutation
>
export type ConfirmUserRegistrationMutationResult = Apollo.MutationResult<
  ConfirmUserRegistrationMutation
>
export type ConfirmUserRegistrationMutationOptions = Apollo.BaseMutationOptions<
  ConfirmUserRegistrationMutation,
  ConfirmUserRegistrationMutationVariables
>
export const CreateBookingDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createBooking' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'MarketResourceBookingInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'createBooking' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'ResourceBookingFragment',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ResourceBookingFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketResourceBooking' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'resource' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'startTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'endTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'durationMinutes' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'location' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'services' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'color' } },
                { kind: 'Field', name: { kind: 'Name', value: 'price' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'durationMinutes' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}
export type CreateBookingMutationFn = Apollo.MutationFunction<
  CreateBookingMutation,
  CreateBookingMutationVariables
>

/**
 * __useCreateBookingMutation__
 *
 * To run a mutation, you first call `useCreateBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBookingMutation, { data, loading, error }] = useCreateBookingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBookingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateBookingMutation,
    CreateBookingMutationVariables
  >
) {
  return Apollo.useMutation<
    CreateBookingMutation,
    CreateBookingMutationVariables
  >(CreateBookingDocument, baseOptions)
}
export type CreateBookingMutationHookResult = ReturnType<
  typeof useCreateBookingMutation
>
export type CreateBookingMutationResult = Apollo.MutationResult<
  CreateBookingMutation
>
export type CreateBookingMutationOptions = Apollo.BaseMutationOptions<
  CreateBookingMutation,
  CreateBookingMutationVariables
>
export const LoginDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'login' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'password' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'login' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'userName' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userName' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'password' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'password' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'user' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'UserFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketUser' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nickName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailNotificationSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendBookingEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendHelpEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendNewsEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendReminderEmail' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailConfirmed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isMobilePhoneConfirmed' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hasLocalAccount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasFbAccount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasGoogleAccount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'google_profilePicUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_profilePicUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_phone' } },
        ],
      },
    },
  ],
}
export type LoginMutationFn = Apollo.MutationFunction<
  LoginMutation,
  LoginMutationVariables
>

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      userName: // value for 'userName'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LoginMutation,
    LoginMutationVariables
  >
) {
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(
    LoginDocument,
    baseOptions
  )
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>
export type LoginMutationOptions = Apollo.BaseMutationOptions<
  LoginMutation,
  LoginMutationVariables
>
export const RegisterUserDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'registerUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RegisterClientInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'registerUser' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'inviteId' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}
export type RegisterUserMutationFn = Apollo.MutationFunction<
  RegisterUserMutation,
  RegisterUserMutationVariables
>

/**
 * __useRegisterUserMutation__
 *
 * To run a mutation, you first call `useRegisterUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserMutation, { data, loading, error }] = useRegisterUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >
) {
  return Apollo.useMutation<
    RegisterUserMutation,
    RegisterUserMutationVariables
  >(RegisterUserDocument, baseOptions)
}
export type RegisterUserMutationHookResult = ReturnType<
  typeof useRegisterUserMutation
>
export type RegisterUserMutationResult = Apollo.MutationResult<
  RegisterUserMutation
>
export type RegisterUserMutationOptions = Apollo.BaseMutationOptions<
  RegisterUserMutation,
  RegisterUserMutationVariables
>
export const MarketCancelAppointmentDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'marketCancelAppointment' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'cancelAppointment' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'services' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}
export type MarketCancelAppointmentMutationFn = Apollo.MutationFunction<
  MarketCancelAppointmentMutation,
  MarketCancelAppointmentMutationVariables
>

/**
 * __useMarketCancelAppointmentMutation__
 *
 * To run a mutation, you first call `useMarketCancelAppointmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarketCancelAppointmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [marketCancelAppointmentMutation, { data, loading, error }] = useMarketCancelAppointmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarketCancelAppointmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarketCancelAppointmentMutation,
    MarketCancelAppointmentMutationVariables
  >
) {
  return Apollo.useMutation<
    MarketCancelAppointmentMutation,
    MarketCancelAppointmentMutationVariables
  >(MarketCancelAppointmentDocument, baseOptions)
}
export type MarketCancelAppointmentMutationHookResult = ReturnType<
  typeof useMarketCancelAppointmentMutation
>
export type MarketCancelAppointmentMutationResult = Apollo.MutationResult<
  MarketCancelAppointmentMutation
>
export type MarketCancelAppointmentMutationOptions = Apollo.BaseMutationOptions<
  MarketCancelAppointmentMutation,
  MarketCancelAppointmentMutationVariables
>
export const SendConfirmEmailDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'sendConfirmEmail' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendConfirmEmail' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}
export type SendConfirmEmailMutationFn = Apollo.MutationFunction<
  SendConfirmEmailMutation,
  SendConfirmEmailMutationVariables
>

/**
 * __useSendConfirmEmailMutation__
 *
 * To run a mutation, you first call `useSendConfirmEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendConfirmEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendConfirmEmailMutation, { data, loading, error }] = useSendConfirmEmailMutation({
 *   variables: {
 *   },
 * });
 */
export function useSendConfirmEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendConfirmEmailMutation,
    SendConfirmEmailMutationVariables
  >
) {
  return Apollo.useMutation<
    SendConfirmEmailMutation,
    SendConfirmEmailMutationVariables
  >(SendConfirmEmailDocument, baseOptions)
}
export type SendConfirmEmailMutationHookResult = ReturnType<
  typeof useSendConfirmEmailMutation
>
export type SendConfirmEmailMutationResult = Apollo.MutationResult<
  SendConfirmEmailMutation
>
export type SendConfirmEmailMutationOptions = Apollo.BaseMutationOptions<
  SendConfirmEmailMutation,
  SendConfirmEmailMutationVariables
>
export const SendPhoneOtpDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'sendPhoneOtp' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendPhoneOtp' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}
export type SendPhoneOtpMutationFn = Apollo.MutationFunction<
  SendPhoneOtpMutation,
  SendPhoneOtpMutationVariables
>

/**
 * __useSendPhoneOtpMutation__
 *
 * To run a mutation, you first call `useSendPhoneOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendPhoneOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendPhoneOtpMutation, { data, loading, error }] = useSendPhoneOtpMutation({
 *   variables: {
 *   },
 * });
 */
export function useSendPhoneOtpMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendPhoneOtpMutation,
    SendPhoneOtpMutationVariables
  >
) {
  return Apollo.useMutation<
    SendPhoneOtpMutation,
    SendPhoneOtpMutationVariables
  >(SendPhoneOtpDocument, baseOptions)
}
export type SendPhoneOtpMutationHookResult = ReturnType<
  typeof useSendPhoneOtpMutation
>
export type SendPhoneOtpMutationResult = Apollo.MutationResult<
  SendPhoneOtpMutation
>
export type SendPhoneOtpMutationOptions = Apollo.BaseMutationOptions<
  SendPhoneOtpMutation,
  SendPhoneOtpMutationVariables
>
export const UpdateEmailSettingsDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateEmailSettings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateEmailSettingsInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updateEmailSettings' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketUser' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nickName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailNotificationSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendBookingEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendHelpEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendNewsEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendReminderEmail' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailConfirmed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isMobilePhoneConfirmed' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hasLocalAccount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasFbAccount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasGoogleAccount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'google_profilePicUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_profilePicUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_phone' } },
        ],
      },
    },
  ],
}
export type UpdateEmailSettingsMutationFn = Apollo.MutationFunction<
  UpdateEmailSettingsMutation,
  UpdateEmailSettingsMutationVariables
>

/**
 * __useUpdateEmailSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateEmailSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEmailSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEmailSettingsMutation, { data, loading, error }] = useUpdateEmailSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateEmailSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateEmailSettingsMutation,
    UpdateEmailSettingsMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateEmailSettingsMutation,
    UpdateEmailSettingsMutationVariables
  >(UpdateEmailSettingsDocument, baseOptions)
}
export type UpdateEmailSettingsMutationHookResult = ReturnType<
  typeof useUpdateEmailSettingsMutation
>
export type UpdateEmailSettingsMutationResult = Apollo.MutationResult<
  UpdateEmailSettingsMutation
>
export type UpdateEmailSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateEmailSettingsMutation,
  UpdateEmailSettingsMutationVariables
>
export const UserUpdateNotificationPreferencesDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'userUpdateNotificationPreferences' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'UpdateNotificationPreferencesInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: {
                    kind: 'Name',
                    value: 'updateNotificationPreferences',
                  },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sendBookingEmail' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sendHelpEmail' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sendNewsEmail' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sendReminderEmail' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}
export type UserUpdateNotificationPreferencesMutationFn = Apollo.MutationFunction<
  UserUpdateNotificationPreferencesMutation,
  UserUpdateNotificationPreferencesMutationVariables
>

/**
 * __useUserUpdateNotificationPreferencesMutation__
 *
 * To run a mutation, you first call `useUserUpdateNotificationPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateNotificationPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateNotificationPreferencesMutation, { data, loading, error }] = useUserUpdateNotificationPreferencesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserUpdateNotificationPreferencesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserUpdateNotificationPreferencesMutation,
    UserUpdateNotificationPreferencesMutationVariables
  >
) {
  return Apollo.useMutation<
    UserUpdateNotificationPreferencesMutation,
    UserUpdateNotificationPreferencesMutationVariables
  >(UserUpdateNotificationPreferencesDocument, baseOptions)
}
export type UserUpdateNotificationPreferencesMutationHookResult = ReturnType<
  typeof useUserUpdateNotificationPreferencesMutation
>
export type UserUpdateNotificationPreferencesMutationResult = Apollo.MutationResult<
  UserUpdateNotificationPreferencesMutation
>
export type UserUpdateNotificationPreferencesMutationOptions = Apollo.BaseMutationOptions<
  UserUpdateNotificationPreferencesMutation,
  UserUpdateNotificationPreferencesMutationVariables
>
export const UpdateUserEmailDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateUserEmail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateEmailInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updateEmail' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketUser' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nickName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailNotificationSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendBookingEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendHelpEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendNewsEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendReminderEmail' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailConfirmed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isMobilePhoneConfirmed' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hasLocalAccount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasFbAccount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasGoogleAccount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'google_profilePicUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_profilePicUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_phone' } },
        ],
      },
    },
  ],
}
export type UpdateUserEmailMutationFn = Apollo.MutationFunction<
  UpdateUserEmailMutation,
  UpdateUserEmailMutationVariables
>

/**
 * __useUpdateUserEmailMutation__
 *
 * To run a mutation, you first call `useUpdateUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserEmailMutation, { data, loading, error }] = useUpdateUserEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserEmailMutation,
    UpdateUserEmailMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateUserEmailMutation,
    UpdateUserEmailMutationVariables
  >(UpdateUserEmailDocument, baseOptions)
}
export type UpdateUserEmailMutationHookResult = ReturnType<
  typeof useUpdateUserEmailMutation
>
export type UpdateUserEmailMutationResult = Apollo.MutationResult<
  UpdateUserEmailMutation
>
export type UpdateUserEmailMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserEmailMutation,
  UpdateUserEmailMutationVariables
>
export const UpdateUserLanguageDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateUserLanguage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateLanguageInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updateLanguage' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketUser' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nickName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailNotificationSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendBookingEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendHelpEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendNewsEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendReminderEmail' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailConfirmed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isMobilePhoneConfirmed' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hasLocalAccount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasFbAccount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasGoogleAccount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'google_profilePicUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_profilePicUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_phone' } },
        ],
      },
    },
  ],
}
export type UpdateUserLanguageMutationFn = Apollo.MutationFunction<
  UpdateUserLanguageMutation,
  UpdateUserLanguageMutationVariables
>

/**
 * __useUpdateUserLanguageMutation__
 *
 * To run a mutation, you first call `useUpdateUserLanguageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserLanguageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserLanguageMutation, { data, loading, error }] = useUpdateUserLanguageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserLanguageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserLanguageMutation,
    UpdateUserLanguageMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateUserLanguageMutation,
    UpdateUserLanguageMutationVariables
  >(UpdateUserLanguageDocument, baseOptions)
}
export type UpdateUserLanguageMutationHookResult = ReturnType<
  typeof useUpdateUserLanguageMutation
>
export type UpdateUserLanguageMutationResult = Apollo.MutationResult<
  UpdateUserLanguageMutation
>
export type UpdateUserLanguageMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserLanguageMutation,
  UpdateUserLanguageMutationVariables
>
export const UpdateUserPhoneDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'updateUserPhone' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdatePhoneInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updatePhone' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketUser' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nickName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailNotificationSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendBookingEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendHelpEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendNewsEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendReminderEmail' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailConfirmed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isMobilePhoneConfirmed' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hasLocalAccount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasFbAccount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasGoogleAccount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'google_profilePicUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_profilePicUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_phone' } },
        ],
      },
    },
  ],
}
export type UpdateUserPhoneMutationFn = Apollo.MutationFunction<
  UpdateUserPhoneMutation,
  UpdateUserPhoneMutationVariables
>

/**
 * __useUpdateUserPhoneMutation__
 *
 * To run a mutation, you first call `useUpdateUserPhoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserPhoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserPhoneMutation, { data, loading, error }] = useUpdateUserPhoneMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserPhoneMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserPhoneMutation,
    UpdateUserPhoneMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateUserPhoneMutation,
    UpdateUserPhoneMutationVariables
  >(UpdateUserPhoneDocument, baseOptions)
}
export type UpdateUserPhoneMutationHookResult = ReturnType<
  typeof useUpdateUserPhoneMutation
>
export type UpdateUserPhoneMutationResult = Apollo.MutationResult<
  UpdateUserPhoneMutation
>
export type UpdateUserPhoneMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserPhoneMutation,
  UpdateUserPhoneMutationVariables
>
export const UploadAvatarDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'uploadAvatar' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'file' } },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'Upload' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'uploadAvatar' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'file' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'file' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'filename' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}
export type UploadAvatarMutationFn = Apollo.MutationFunction<
  UploadAvatarMutation,
  UploadAvatarMutationVariables
>

/**
 * __useUploadAvatarMutation__
 *
 * To run a mutation, you first call `useUploadAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadAvatarMutation, { data, loading, error }] = useUploadAvatarMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadAvatarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadAvatarMutation,
    UploadAvatarMutationVariables
  >
) {
  return Apollo.useMutation<
    UploadAvatarMutation,
    UploadAvatarMutationVariables
  >(UploadAvatarDocument, baseOptions)
}
export type UploadAvatarMutationHookResult = ReturnType<
  typeof useUploadAvatarMutation
>
export type UploadAvatarMutationResult = Apollo.MutationResult<
  UploadAvatarMutation
>
export type UploadAvatarMutationOptions = Apollo.BaseMutationOptions<
  UploadAvatarMutation,
  UploadAvatarMutationVariables
>
export const UserConfirmPasswordResetDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'userConfirmPasswordReset' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'otp' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'password' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'confirmPasswordReset' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'userName' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'userName' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'otp' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'otp' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'password' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'password' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}
export type UserConfirmPasswordResetMutationFn = Apollo.MutationFunction<
  UserConfirmPasswordResetMutation,
  UserConfirmPasswordResetMutationVariables
>

/**
 * __useUserConfirmPasswordResetMutation__
 *
 * To run a mutation, you first call `useUserConfirmPasswordResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserConfirmPasswordResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userConfirmPasswordResetMutation, { data, loading, error }] = useUserConfirmPasswordResetMutation({
 *   variables: {
 *      userName: // value for 'userName'
 *      otp: // value for 'otp'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUserConfirmPasswordResetMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserConfirmPasswordResetMutation,
    UserConfirmPasswordResetMutationVariables
  >
) {
  return Apollo.useMutation<
    UserConfirmPasswordResetMutation,
    UserConfirmPasswordResetMutationVariables
  >(UserConfirmPasswordResetDocument, baseOptions)
}
export type UserConfirmPasswordResetMutationHookResult = ReturnType<
  typeof useUserConfirmPasswordResetMutation
>
export type UserConfirmPasswordResetMutationResult = Apollo.MutationResult<
  UserConfirmPasswordResetMutation
>
export type UserConfirmPasswordResetMutationOptions = Apollo.BaseMutationOptions<
  UserConfirmPasswordResetMutation,
  UserConfirmPasswordResetMutationVariables
>
export const UserMeChangePasswordDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'userMeChangePassword' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ChangePasswordInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'changePassword' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
}
export type UserMeChangePasswordMutationFn = Apollo.MutationFunction<
  UserMeChangePasswordMutation,
  UserMeChangePasswordMutationVariables
>

/**
 * __useUserMeChangePasswordMutation__
 *
 * To run a mutation, you first call `useUserMeChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserMeChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userMeChangePasswordMutation, { data, loading, error }] = useUserMeChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserMeChangePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserMeChangePasswordMutation,
    UserMeChangePasswordMutationVariables
  >
) {
  return Apollo.useMutation<
    UserMeChangePasswordMutation,
    UserMeChangePasswordMutationVariables
  >(UserMeChangePasswordDocument, baseOptions)
}
export type UserMeChangePasswordMutationHookResult = ReturnType<
  typeof useUserMeChangePasswordMutation
>
export type UserMeChangePasswordMutationResult = Apollo.MutationResult<
  UserMeChangePasswordMutation
>
export type UserMeChangePasswordMutationOptions = Apollo.BaseMutationOptions<
  UserMeChangePasswordMutation,
  UserMeChangePasswordMutationVariables
>
export const UserMeUpdateDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'userMeUpdate' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'UpdateProfileInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'updateProfile' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'input' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketUser' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nickName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailNotificationSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendBookingEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendHelpEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendNewsEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendReminderEmail' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailConfirmed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isMobilePhoneConfirmed' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hasLocalAccount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasFbAccount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasGoogleAccount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'google_profilePicUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_profilePicUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_phone' } },
        ],
      },
    },
  ],
}
export type UserMeUpdateMutationFn = Apollo.MutationFunction<
  UserMeUpdateMutation,
  UserMeUpdateMutationVariables
>

/**
 * __useUserMeUpdateMutation__
 *
 * To run a mutation, you first call `useUserMeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserMeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userMeUpdateMutation, { data, loading, error }] = useUserMeUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserMeUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserMeUpdateMutation,
    UserMeUpdateMutationVariables
  >
) {
  return Apollo.useMutation<
    UserMeUpdateMutation,
    UserMeUpdateMutationVariables
  >(UserMeUpdateDocument, baseOptions)
}
export type UserMeUpdateMutationHookResult = ReturnType<
  typeof useUserMeUpdateMutation
>
export type UserMeUpdateMutationResult = Apollo.MutationResult<
  UserMeUpdateMutation
>
export type UserMeUpdateMutationOptions = Apollo.BaseMutationOptions<
  UserMeUpdateMutation,
  UserMeUpdateMutationVariables
>
export const UserRequestPasswordRestDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'userRequestPasswordRest' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'sendTo' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'requestPasswordReset' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'sendTo' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'sendTo' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
}
export type UserRequestPasswordRestMutationFn = Apollo.MutationFunction<
  UserRequestPasswordRestMutation,
  UserRequestPasswordRestMutationVariables
>

/**
 * __useUserRequestPasswordRestMutation__
 *
 * To run a mutation, you first call `useUserRequestPasswordRestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserRequestPasswordRestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userRequestPasswordRestMutation, { data, loading, error }] = useUserRequestPasswordRestMutation({
 *   variables: {
 *      sendTo: // value for 'sendTo'
 *   },
 * });
 */
export function useUserRequestPasswordRestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UserRequestPasswordRestMutation,
    UserRequestPasswordRestMutationVariables
  >
) {
  return Apollo.useMutation<
    UserRequestPasswordRestMutation,
    UserRequestPasswordRestMutationVariables
  >(UserRequestPasswordRestDocument, baseOptions)
}
export type UserRequestPasswordRestMutationHookResult = ReturnType<
  typeof useUserRequestPasswordRestMutation
>
export type UserRequestPasswordRestMutationResult = Apollo.MutationResult<
  UserRequestPasswordRestMutation
>
export type UserRequestPasswordRestMutationOptions = Apollo.BaseMutationOptions<
  UserRequestPasswordRestMutation,
  UserRequestPasswordRestMutationVariables
>
export const CountryAllDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'countryAll' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'country' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'all' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'countryCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'countryName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currency' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currencyCode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currencyName' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useCountryAllQuery__
 *
 * To run a query within a React component, call `useCountryAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountryAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountryAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountryAllQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CountryAllQuery,
    CountryAllQueryVariables
  >
) {
  return Apollo.useQuery<CountryAllQuery, CountryAllQueryVariables>(
    CountryAllDocument,
    baseOptions
  )
}
export function useCountryAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CountryAllQuery,
    CountryAllQueryVariables
  >
) {
  return Apollo.useLazyQuery<CountryAllQuery, CountryAllQueryVariables>(
    CountryAllDocument,
    baseOptions
  )
}
export type CountryAllQueryHookResult = ReturnType<typeof useCountryAllQuery>
export type CountryAllLazyQueryHookResult = ReturnType<
  typeof useCountryAllLazyQuery
>
export type CountryAllQueryResult = Apollo.QueryResult<
  CountryAllQuery,
  CountryAllQueryVariables
>
export const CurrencyAllDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'currencyAll' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currency' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'all' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currencyCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'currencyName' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useCurrencyAllQuery__
 *
 * To run a query within a React component, call `useCurrencyAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrencyAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrencyAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrencyAllQuery(
  baseOptions?: Apollo.QueryHookOptions<
    CurrencyAllQuery,
    CurrencyAllQueryVariables
  >
) {
  return Apollo.useQuery<CurrencyAllQuery, CurrencyAllQueryVariables>(
    CurrencyAllDocument,
    baseOptions
  )
}
export function useCurrencyAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    CurrencyAllQuery,
    CurrencyAllQueryVariables
  >
) {
  return Apollo.useLazyQuery<CurrencyAllQuery, CurrencyAllQueryVariables>(
    CurrencyAllDocument,
    baseOptions
  )
}
export type CurrencyAllQueryHookResult = ReturnType<typeof useCurrencyAllQuery>
export type CurrencyAllLazyQueryHookResult = ReturnType<
  typeof useCurrencyAllLazyQuery
>
export type CurrencyAllQueryResult = Apollo.QueryResult<
  CurrencyAllQuery,
  CurrencyAllQueryVariables
>
export const FreeSlotsDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'freeSlots' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'services' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateFrom' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GraphQLDate' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'freeSlots' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'locationId' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'locationId' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'services' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'services' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'dateFrom' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dateFrom' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'resourceId' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'resourceId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timeSlots' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startTime' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resources' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useFreeSlotsQuery__
 *
 * To run a query within a React component, call `useFreeSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFreeSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFreeSlotsQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      services: // value for 'services'
 *      dateFrom: // value for 'dateFrom'
 *      resourceId: // value for 'resourceId'
 *   },
 * });
 */
export function useFreeSlotsQuery(
  baseOptions: Apollo.QueryHookOptions<FreeSlotsQuery, FreeSlotsQueryVariables>
) {
  return Apollo.useQuery<FreeSlotsQuery, FreeSlotsQueryVariables>(
    FreeSlotsDocument,
    baseOptions
  )
}
export function useFreeSlotsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FreeSlotsQuery,
    FreeSlotsQueryVariables
  >
) {
  return Apollo.useLazyQuery<FreeSlotsQuery, FreeSlotsQueryVariables>(
    FreeSlotsDocument,
    baseOptions
  )
}
export type FreeSlotsQueryHookResult = ReturnType<typeof useFreeSlotsQuery>
export type FreeSlotsLazyQueryHookResult = ReturnType<
  typeof useFreeSlotsLazyQuery
>
export type FreeSlotsQueryResult = Apollo.QueryResult<
  FreeSlotsQuery,
  FreeSlotsQueryVariables
>
export const GetNotificationPreferencesDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getNotificationPreferences' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'token' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'getNotificationPreferences' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'clientId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'firstName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'lastName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sendHelpEmail' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sendNewsEmail' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sendBookingEmail' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'sendReminderEmail' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useGetNotificationPreferencesQuery__
 *
 * To run a query within a React component, call `useGetNotificationPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationPreferencesQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetNotificationPreferencesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetNotificationPreferencesQuery,
    GetNotificationPreferencesQueryVariables
  >
) {
  return Apollo.useQuery<
    GetNotificationPreferencesQuery,
    GetNotificationPreferencesQueryVariables
  >(GetNotificationPreferencesDocument, baseOptions)
}
export function useGetNotificationPreferencesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNotificationPreferencesQuery,
    GetNotificationPreferencesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetNotificationPreferencesQuery,
    GetNotificationPreferencesQueryVariables
  >(GetNotificationPreferencesDocument, baseOptions)
}
export type GetNotificationPreferencesQueryHookResult = ReturnType<
  typeof useGetNotificationPreferencesQuery
>
export type GetNotificationPreferencesLazyQueryHookResult = ReturnType<
  typeof useGetNotificationPreferencesLazyQuery
>
export type GetNotificationPreferencesQueryResult = Apollo.QueryResult<
  GetNotificationPreferencesQuery,
  GetNotificationPreferencesQueryVariables
>
export const GetOrganizationByUrlNameDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizationByUrlName' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'orgUrlName' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'getByOrgUrlName' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'orgUrlName' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'orgUrlName' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orgName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'code' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isActive' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mobilePhone' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'phone' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'city' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'zipCode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageUrl' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'onlineBooking_status',
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useGetOrganizationByUrlNameQuery__
 *
 * To run a query within a React component, call `useGetOrganizationByUrlNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationByUrlNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationByUrlNameQuery({
 *   variables: {
 *      orgUrlName: // value for 'orgUrlName'
 *   },
 * });
 */
export function useGetOrganizationByUrlNameQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrganizationByUrlNameQuery,
    GetOrganizationByUrlNameQueryVariables
  >
) {
  return Apollo.useQuery<
    GetOrganizationByUrlNameQuery,
    GetOrganizationByUrlNameQueryVariables
  >(GetOrganizationByUrlNameDocument, baseOptions)
}
export function useGetOrganizationByUrlNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationByUrlNameQuery,
    GetOrganizationByUrlNameQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetOrganizationByUrlNameQuery,
    GetOrganizationByUrlNameQueryVariables
  >(GetOrganizationByUrlNameDocument, baseOptions)
}
export type GetOrganizationByUrlNameQueryHookResult = ReturnType<
  typeof useGetOrganizationByUrlNameQuery
>
export type GetOrganizationByUrlNameLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationByUrlNameLazyQuery
>
export type GetOrganizationByUrlNameQueryResult = Apollo.QueryResult<
  GetOrganizationByUrlNameQuery,
  GetOrganizationByUrlNameQueryVariables
>
export const GetOrganizationsDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganizations' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'organizations' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'my' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'orgName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatarUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'locations' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'other' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'orgName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'avatarUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'locations' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useGetOrganizationsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganizationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrganizationsQuery,
    GetOrganizationsQueryVariables
  >
) {
  return Apollo.useQuery<GetOrganizationsQuery, GetOrganizationsQueryVariables>(
    GetOrganizationsDocument,
    baseOptions
  )
}
export function useGetOrganizationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationsQuery,
    GetOrganizationsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetOrganizationsQuery,
    GetOrganizationsQueryVariables
  >(GetOrganizationsDocument, baseOptions)
}
export type GetOrganizationsQueryHookResult = ReturnType<
  typeof useGetOrganizationsQuery
>
export type GetOrganizationsLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationsLazyQuery
>
export type GetOrganizationsQueryResult = Apollo.QueryResult<
  GetOrganizationsQuery,
  GetOrganizationsQueryVariables
>
export const MarketFreeSlotsDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'marketFreeSlots' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'services' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'ListType',
              type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateFrom' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GraphQLDate' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'resourceId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'freeSlots' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'locationId' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'locationId' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'services' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'services' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'dateFrom' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dateFrom' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'resourceId' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'resourceId' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timeSlots' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'startTime' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'resources' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useMarketFreeSlotsQuery__
 *
 * To run a query within a React component, call `useMarketFreeSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketFreeSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketFreeSlotsQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      services: // value for 'services'
 *      dateFrom: // value for 'dateFrom'
 *      resourceId: // value for 'resourceId'
 *   },
 * });
 */
export function useMarketFreeSlotsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MarketFreeSlotsQuery,
    MarketFreeSlotsQueryVariables
  >
) {
  return Apollo.useQuery<MarketFreeSlotsQuery, MarketFreeSlotsQueryVariables>(
    MarketFreeSlotsDocument,
    baseOptions
  )
}
export function useMarketFreeSlotsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MarketFreeSlotsQuery,
    MarketFreeSlotsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    MarketFreeSlotsQuery,
    MarketFreeSlotsQueryVariables
  >(MarketFreeSlotsDocument, baseOptions)
}
export type MarketFreeSlotsQueryHookResult = ReturnType<
  typeof useMarketFreeSlotsQuery
>
export type MarketFreeSlotsLazyQueryHookResult = ReturnType<
  typeof useMarketFreeSlotsLazyQuery
>
export type MarketFreeSlotsQueryResult = Apollo.QueryResult<
  MarketFreeSlotsQuery,
  MarketFreeSlotsQueryVariables
>
export const MarketGetLocationDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'marketGetLocation' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'getLocation' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locationId' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'code' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'orgId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'imageUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'facebookUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'instagramUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'emailNote' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'timeSlotDuration' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'onlineBooking_status' },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'onlineBooking_showPrices',
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'onlineBooking_showAddress',
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'onlineBooking_showEmail',
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'onlineBooking_showPhone',
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'onlineBooking_serviceSelection',
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'onlineBooking_minHrsBefore',
                        },
                      },
                      {
                        kind: 'Field',
                        name: {
                          kind: 'Name',
                          value: 'onlineBooking_minDaysBefore',
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'shortDescription' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'webUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'workShifts' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'openHours' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'day' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dayName' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'startTime' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'endTime' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'dayOff' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mobilePhone' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'zipCode' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'lat' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'lng' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'countryCode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'countryName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'currency' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'currencyCode',
                                    },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'currencyName',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'resources' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'professions' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'id' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'name' },
                                  },
                                ],
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'locationSettings' },
                              arguments: [
                                {
                                  kind: 'Argument',
                                  name: { kind: 'Name', value: 'locationId' },
                                  value: {
                                    kind: 'Variable',
                                    name: { kind: 'Name', value: 'locationId' },
                                  },
                                },
                              ],
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'resourceId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'locationId' },
                                  },
                                  {
                                    kind: 'Field',
                                    name: {
                                      kind: 'Name',
                                      value: 'onlineBooking',
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useMarketGetLocationQuery__
 *
 * To run a query within a React component, call `useMarketGetLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketGetLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketGetLocationQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useMarketGetLocationQuery(
  baseOptions: Apollo.QueryHookOptions<
    MarketGetLocationQuery,
    MarketGetLocationQueryVariables
  >
) {
  return Apollo.useQuery<
    MarketGetLocationQuery,
    MarketGetLocationQueryVariables
  >(MarketGetLocationDocument, baseOptions)
}
export function useMarketGetLocationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MarketGetLocationQuery,
    MarketGetLocationQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    MarketGetLocationQuery,
    MarketGetLocationQueryVariables
  >(MarketGetLocationDocument, baseOptions)
}
export type MarketGetLocationQueryHookResult = ReturnType<
  typeof useMarketGetLocationQuery
>
export type MarketGetLocationLazyQueryHookResult = ReturnType<
  typeof useMarketGetLocationLazyQuery
>
export type MarketGetLocationQueryResult = Apollo.QueryResult<
  MarketGetLocationQuery,
  MarketGetLocationQueryVariables
>
export const MarketMeDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'marketMe' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'me' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketUser' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'avatarUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'nickName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'language' } },
          { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailNotificationSettings' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendBookingEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendHelpEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendNewsEmail' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'sendReminderEmail' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'address' } },
          { kind: 'Field', name: { kind: 'Name', value: 'gender' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isEmailConfirmed' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isMobilePhoneConfirmed' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'hasLocalAccount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasFbAccount' } },
          { kind: 'Field', name: { kind: 'Name', value: 'hasGoogleAccount' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'google_profilePicUrl' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_profilePicUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'role' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'google_phone' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_email' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_firstName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_lastName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'fb_phone' } },
        ],
      },
    },
  ],
}

/**
 * __useMarketMeQuery__
 *
 * To run a query within a React component, call `useMarketMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMarketMeQuery(
  baseOptions?: Apollo.QueryHookOptions<MarketMeQuery, MarketMeQueryVariables>
) {
  return Apollo.useQuery<MarketMeQuery, MarketMeQueryVariables>(
    MarketMeDocument,
    baseOptions
  )
}
export function useMarketMeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MarketMeQuery,
    MarketMeQueryVariables
  >
) {
  return Apollo.useLazyQuery<MarketMeQuery, MarketMeQueryVariables>(
    MarketMeDocument,
    baseOptions
  )
}
export type MarketMeQueryHookResult = ReturnType<typeof useMarketMeQuery>
export type MarketMeLazyQueryHookResult = ReturnType<
  typeof useMarketMeLazyQuery
>
export type MarketMeQueryResult = Apollo.QueryResult<
  MarketMeQuery,
  MarketMeQueryVariables
>
export const MarketServiceTypesDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'marketServiceTypes' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locationId' },
          },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'serviceTypes' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'locationId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locationId' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'services' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: { kind: 'Name', value: 'ServiceFragment' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketService' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bookingAllowed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'durationMinutes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasBookingConfirmation' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useMarketServiceTypesQuery__
 *
 * To run a query within a React component, call `useMarketServiceTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketServiceTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketServiceTypesQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useMarketServiceTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    MarketServiceTypesQuery,
    MarketServiceTypesQueryVariables
  >
) {
  return Apollo.useQuery<
    MarketServiceTypesQuery,
    MarketServiceTypesQueryVariables
  >(MarketServiceTypesDocument, baseOptions)
}
export function useMarketServiceTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MarketServiceTypesQuery,
    MarketServiceTypesQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    MarketServiceTypesQuery,
    MarketServiceTypesQueryVariables
  >(MarketServiceTypesDocument, baseOptions)
}
export type MarketServiceTypesQueryHookResult = ReturnType<
  typeof useMarketServiceTypesQuery
>
export type MarketServiceTypesLazyQueryHookResult = ReturnType<
  typeof useMarketServiceTypesLazyQuery
>
export type MarketServiceTypesQueryResult = Apollo.QueryResult<
  MarketServiceTypesQuery,
  MarketServiceTypesQueryVariables
>
export const MarketServicesDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'marketServices' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'locationId' },
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'services' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'locationId' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'locationId' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ServiceFragment' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ServiceFragment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MarketService' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'color' } },
          { kind: 'Field', name: { kind: 'Name', value: 'price' } },
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'bookingAllowed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'durationMinutes' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hasBookingConfirmation' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'sequence' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'profession' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'type' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useMarketServicesQuery__
 *
 * To run a query within a React component, call `useMarketServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMarketServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMarketServicesQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useMarketServicesQuery(
  baseOptions: Apollo.QueryHookOptions<
    MarketServicesQuery,
    MarketServicesQueryVariables
  >
) {
  return Apollo.useQuery<MarketServicesQuery, MarketServicesQueryVariables>(
    MarketServicesDocument,
    baseOptions
  )
}
export function useMarketServicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MarketServicesQuery,
    MarketServicesQueryVariables
  >
) {
  return Apollo.useLazyQuery<MarketServicesQuery, MarketServicesQueryVariables>(
    MarketServicesDocument,
    baseOptions
  )
}
export type MarketServicesQueryHookResult = ReturnType<
  typeof useMarketServicesQuery
>
export type MarketServicesLazyQueryHookResult = ReturnType<
  typeof useMarketServicesLazyQuery
>
export type MarketServicesQueryResult = Apollo.QueryResult<
  MarketServicesQuery,
  MarketServicesQueryVariables
>
export const MyBookingsDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'myBookings' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateFrom' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GraphQLDate' },
            },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'dateTo' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'GraphQLDate' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'myBookings' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'input' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'dateFrom' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dateFrom' },
                            },
                          },
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'dateTo' },
                            value: {
                              kind: 'Variable',
                              name: { kind: 'Name', value: 'dateTo' },
                            },
                          },
                        ],
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startTime' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endTime' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'appointmentId' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'services' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'color' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'price' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'durationMinutes' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'resource' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'code' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'code' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'city' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'zipCode' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'durationMinutes' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useMyBookingsQuery__
 *
 * To run a query within a React component, call `useMyBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyBookingsQuery({
 *   variables: {
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *   },
 * });
 */
export function useMyBookingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    MyBookingsQuery,
    MyBookingsQueryVariables
  >
) {
  return Apollo.useQuery<MyBookingsQuery, MyBookingsQueryVariables>(
    MyBookingsDocument,
    baseOptions
  )
}
export function useMyBookingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    MyBookingsQuery,
    MyBookingsQueryVariables
  >
) {
  return Apollo.useLazyQuery<MyBookingsQuery, MyBookingsQueryVariables>(
    MyBookingsDocument,
    baseOptions
  )
}
export type MyBookingsQueryHookResult = ReturnType<typeof useMyBookingsQuery>
export type MyBookingsLazyQueryHookResult = ReturnType<
  typeof useMyBookingsLazyQuery
>
export type MyBookingsQueryResult = Apollo.QueryResult<
  MyBookingsQuery,
  MyBookingsQueryVariables
>
export const GetOrganizationDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getOrganization' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'getOrganization' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orgName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatarUrl' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'webUrl' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'vatId' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'address' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'zipCode' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'phone' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'mobilePhone' },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'city' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orgUrlName' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'country' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'countryName' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'countryCode' },
                            },
                          ],
                        },
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'email' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'locations' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'code' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'isActive' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'mobilePhone' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'description' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'phone' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'email' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'city' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'zipCode' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'address' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageUrl' },
                            },
                            {
                              kind: 'Field',
                              name: {
                                kind: 'Name',
                                value: 'onlineBooking_status',
                              },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'shortDescription' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useGetOrganizationQuery__
 *
 * To run a query within a React component, call `useGetOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrganizationQuery,
    GetOrganizationQueryVariables
  >
) {
  return Apollo.useQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(
    GetOrganizationDocument,
    baseOptions
  )
}
export function useGetOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganizationQuery,
    GetOrganizationQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetOrganizationQuery,
    GetOrganizationQueryVariables
  >(GetOrganizationDocument, baseOptions)
}
export type GetOrganizationQueryHookResult = ReturnType<
  typeof useGetOrganizationQuery
>
export type GetOrganizationLazyQueryHookResult = ReturnType<
  typeof useGetOrganizationLazyQuery
>
export type GetOrganizationQueryResult = Apollo.QueryResult<
  GetOrganizationQuery,
  GetOrganizationQueryVariables
>
export const ProfessionAllDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'professionAll' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'professions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'resources' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useProfessionAllQuery__
 *
 * To run a query within a React component, call `useProfessionAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfessionAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfessionAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useProfessionAllQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ProfessionAllQuery,
    ProfessionAllQueryVariables
  >
) {
  return Apollo.useQuery<ProfessionAllQuery, ProfessionAllQueryVariables>(
    ProfessionAllDocument,
    baseOptions
  )
}
export function useProfessionAllLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProfessionAllQuery,
    ProfessionAllQueryVariables
  >
) {
  return Apollo.useLazyQuery<ProfessionAllQuery, ProfessionAllQueryVariables>(
    ProfessionAllDocument,
    baseOptions
  )
}
export type ProfessionAllQueryHookResult = ReturnType<
  typeof useProfessionAllQuery
>
export type ProfessionAllLazyQueryHookResult = ReturnType<
  typeof useProfessionAllLazyQuery
>
export type ProfessionAllQueryResult = Apollo.QueryResult<
  ProfessionAllQuery,
  ProfessionAllQueryVariables
>
export const ResourceBookingGetDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'resourceBookingGet' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'id' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'market' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'getBooking' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'id' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'id' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'services' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'color' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'price' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'durationMinutes' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'location' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'imageUrl' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'orgId' },
                            },
                          ],
                        },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'startTime' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'endTime' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'durationMinutes' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'status' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'description' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'message' },
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'resource' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'id' },
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'name' },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useResourceBookingGetQuery__
 *
 * To run a query within a React component, call `useResourceBookingGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceBookingGetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceBookingGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResourceBookingGetQuery(
  baseOptions: Apollo.QueryHookOptions<
    ResourceBookingGetQuery,
    ResourceBookingGetQueryVariables
  >
) {
  return Apollo.useQuery<
    ResourceBookingGetQuery,
    ResourceBookingGetQueryVariables
  >(ResourceBookingGetDocument, baseOptions)
}
export function useResourceBookingGetLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ResourceBookingGetQuery,
    ResourceBookingGetQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    ResourceBookingGetQuery,
    ResourceBookingGetQueryVariables
  >(ResourceBookingGetDocument, baseOptions)
}
export type ResourceBookingGetQueryHookResult = ReturnType<
  typeof useResourceBookingGetQuery
>
export type ResourceBookingGetLazyQueryHookResult = ReturnType<
  typeof useResourceBookingGetLazyQuery
>
export type ResourceBookingGetQueryResult = Apollo.QueryResult<
  ResourceBookingGetQuery,
  ResourceBookingGetQueryVariables
>
export const EmailConfirmedDocument: DocumentNode = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'subscription',
      name: { kind: 'Name', value: 'emailConfirmed' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'emailConfirmed' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'email' } },
              ],
            },
          },
        ],
      },
    },
  ],
}

/**
 * __useEmailConfirmedSubscription__
 *
 * To run a query within a React component, call `useEmailConfirmedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useEmailConfirmedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailConfirmedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useEmailConfirmedSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    EmailConfirmedSubscription,
    EmailConfirmedSubscriptionVariables
  >
) {
  return Apollo.useSubscription<
    EmailConfirmedSubscription,
    EmailConfirmedSubscriptionVariables
  >(EmailConfirmedDocument, baseOptions)
}
export type EmailConfirmedSubscriptionHookResult = ReturnType<
  typeof useEmailConfirmedSubscription
>
export type EmailConfirmedSubscriptionResult = Apollo.SubscriptionResult<
  EmailConfirmedSubscription
>
