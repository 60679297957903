import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  LoginForm,
  LoginFormWrapper,
  LoginPage,
  LogoColor,
  LoginHeader,
  LoginFormHeader,
  LoginTitle,
  LoginLogoTitle,
  LoginHint,
  LoginPartnerRedirect,
} from '../Login/styles'
import { Button, Form, FormField, Loader, AnimatedDiv } from 'components'
import { useSdk } from 'sdk'
import { FieldRow } from '../Settings/styles'
import moment from 'moment'
import { analyticsTrack, getLocationId, getOrgId } from 'helpers'
import { useConfirmUserRegistrationMutation } from 'state/graphql'
export const CodeVerification = () => {
  const { appServices, t, navigateTo } = useSdk()
  const { inviteId } = useParams<any>()
  const [isBusy, setIsBusy] = useState(false)
  const [confirmOtp] = useConfirmUserRegistrationMutation()

  async function createNewUser(values) {
    try {
      setIsBusy(true)

      let results = await confirmOtp({
        variables: {
          input: {
            inviteId,
            otp: values.verificationCode,
          },
        },
      })

      if (!results.errors) {
        const metadata = {
          time: moment().format('DD.MM.YYYY HH:mm'),
          orgId: getOrgId(),
          locationId: getLocationId(),
        }
        analyticsTrack('Client registered', metadata)

        const token = results?.data?.market?.confirmUserRegistration?.token
        localStorage.setItem('token', token)

        navigateTo.myLocations({}, { replace: true })

        appServices.toast.success(
          t('translation.CodeVerification.toast-account-created')
        )
      } else {
        appServices.toast.danger(results?.errors?.[0]?.message)
      }
    } finally {
      setIsBusy(false)
    }
  }
  return (
    <AnimatedDiv
      style={{
        display: 'flex',
        height: 'calc((var(--vh, 1vh) * 100))',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <LoginPage>
        <LoginForm>
          <LoginFormHeader onClick={navigateTo.myLocations}>
            <LoginHeader>
              <LogoColor
                src={process.env.PUBLIC_URL + '/assets/eRedLogo.svg'}
              />
              <LoginLogoTitle>{'powered by AKD'}</LoginLogoTitle>
            </LoginHeader>
          </LoginFormHeader>
          <LoginFormWrapper>
            <LoginTitle>
              {t('translation.CodeVerification.title-enter-sms-code')}
            </LoginTitle>
            <Form
              id={'tfa-form'}
              onSubmit={createNewUser}
              initialValues={{}}
              isInitialValid={true}
              isLoading={isBusy}
            >
              {({ values }) => (
                <>
                  <FieldRow>
                    <FormField.VerificationInput
                      name={'verificationCode'}
                      fields={6}
                      autoFocus
                    />
                  </FieldRow>
                  <Button
                    style={{ marginTop: 0 }}
                    type={'submit'}
                    buttonType={'primary'}
                    label={t('translation.CodeVerification.label-verify-code')}
                    fullWidth
                    disabled={!values.verificationCode}
                    name={'verify'}
                    size={'large'}
                  />
                  <LoginHint>
                    <LoginPartnerRedirect
                      onClick={() => navigateTo.partnerRegister()}
                    >
                      {t('translation.Step7Login.label-backToSelection')}
                    </LoginPartnerRedirect>
                  </LoginHint>
                </>
              )}
            </Form>
          </LoginFormWrapper>
          {isBusy ? <Loader /> : null}
        </LoginForm>
      </LoginPage>
    </AnimatedDiv>
  )
}
