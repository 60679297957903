import styled from 'styled-components'
import { FlexColumn, FlexRow } from 'components'
import * as colors from '../style/colors'
import { fontFamily, fontSize, fontWeight, lineHeight } from '../style/fonts'
import { inputPadding } from '../Input/style'
import Input from 'components/Input'

export const StyledSelectContainer = styled.div``
export const StyledSelectValueInputContainer = styled.div`
  display: inline-block;
  border: none;
  outline: none;
  min-width: 0px;

  align-self: flex-start;
  flex-grow: 0;
`
export const StyledSelectValueInput = styled.input`
  border: none;
  outline: none;
  width: 2px;
  min-width: 0px;
  align-self: flex-start;
  flex-grow: 0;
  box-sizing: content-box;
`
export const StyledSelectValue = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-content: stretch;
  align-self: stretch;
  flex-wrap: wrap;
  justify-content: flex-start;
`
export const StyledSelectValueContainer = styled.div<any>`
  display: flex;
  flex-direction: row;
  position: relative;
  box-sizing: border-box;
  margin: ${props => (props.size === 'small' ? '0 0 8px 0' : '0px')};
  background: ${({ hasError }) => (hasError ? colors.redLight : colors.white)};
  border-radius: 4px;
  border: 1px solid
    ${({ hasError }) => (hasError ? colors.red : colors.grayLight)};
  box-shadow: 2px 2px 0 2px transparent;
  transition-property: border-width, border-color, box-shadow;
  transition-duration: 0.1s;
  transition-timing-function: ease-in;
  color: ${({ hasError }) => (hasError ? colors.red : colors.grayDarker)};
  font-family: ${fontFamily};
  font-size: ${fontSize};
  font-weight: ${fontWeight};
  line-height: ${lineHeight};
  ${inputPadding};
  padding-left: ${props => (props.hasIcon ? '40px' : 'auto')};
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  ${({ prefix }) => (prefix ? `padding-left: ${prefix.paddingLeft};` : '')};
  cursor: ${props => (props.isCalendar ? 'pointer' : 'inherit')};

  &:focus {
    border: 1px solid
      ${({ hasError }) => (hasError ? colors.red : colors.blueBorder)};
    box-shadow: 0px 0px 0px 3px
      ${({ hasError }) =>
        hasError ? colors.redLighter : colors.blueLightBorder};
    outline: none;
    transition-property: border-width, border-color, box-shadow;
    transition-duration: 0.1s;
    transition-timing-function: ease-in;
  }

  &:disabled {
    background: ${colors.grayLight};
    border: 1px solid ${colors.grayLight};
    color: ${colors.grayDark};
    cursor: not-allowed;

    &::placeholder {
      color: ${props => props.theme.colors.secondaryDark};
    }
  }
`

export const StyleDropDownOverlay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1000000;
  backdrop-filter: blur(2px);
  background: rgba(128, 128, 128, 0.2);
  bottom: 0;
`
export const StyledDropDownContainer = styled(FlexColumn)<{ height?: any }>`
  align-self: flex-end;
  justify-content: flex-end;
  height: ${props => props.height || '50vh'};
  width: 100%;
  animation-name: anim-open;
  animation-duration: 0.4s;
  @keyframes anim-open {
    0% {
      height: 0;
      min-height: 0;
    }
    100% {
      height: ${props => props.height || '50vh'};
      min-height: ${props => props.height || '50vh'};
    }
  }
`

export const StyledDropDownContent = styled(FlexColumn)`
  background: #f4f4ff;
  border-top: 2px solid ${colors.blue};
  flex: 1;
  padding: 3px;
`
export const StyledMenuListContainer = styled(FlexColumn)`
  overflow-y: auto;
  flex: 1;
`

export const StyleDropDownFilterPanel = styled.div`
  padding: 8px;
`

export const StyledDropDownFooterContainer = styled(FlexRow)`
  padding-right: 16px;
  padding-top: 8px;
`
export const StyledCloseButton = styled.button`
  background: none;
  outline: none;
  border: none;
  align-self: flex-end;
  padding: 3px;
  color: ${colors.blue};
`
export const StyledOptionContainer = styled.div``
export const StyledFilterInput = styled(Input)``
export const StyledOptionValue = styled.div`
  padding: 9px;
  font-size: 16px;
`
export const StyledChevronContainer = styled.span`
  display: flex;
  justify-content: center;
  align-self: center;
`
