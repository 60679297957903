type TRouteBolder = {
  [key: string]: (params?: any, options?: { replace: boolean }) => any
}
export const routeBuilder: TRouteBolder = {
  root: params => `/`,
  myReservations: () => `/termini`,
  myReservation: params => `/termin/${params.locationId}`,
  bookingWizard: params =>
    `/${params.orgId}/${params.locationId}/dan/${params.day}/korak/${
      params.stepId
    }${params.employeeId ? '/' + params.employeeId : ''}${
      params.timeId ? '/' + params.timeId : ''
    }${params.inviteId ? '/' + params.inviteId : ''}`,

  login: params => '/prijava',
  verifyRegistrationOtp: params => `/registracija/potvrda/${params.inviteId}`,
  myLocations: () => `/mjesta`,
  selectedOrg: params => `/${params.orgId}`,
  selectedLocation: params => `/${params.orgId}/${params.locationId}`,
  partnerRegister: () => `/registracija`,
  newOrgRegister: () => `/registracija`,
  passwordReset: () => `/obnovalozinke`,
  myAccountClient: () => `/racun`,
  mySecurityClient: () => `/racun/sigurnost`,
  myContactClient: () => `/racun/kontakt`,
  myAccountClientSecurity: () => `/racun/sigurnost`,
  myAccountClientContact: () => `/racun/kontakt`,
  myAccountClientSettings: () => `/racun/postavke`,

  bookingSettings: () => `/client/*/settings`,
  myBooking: params => `/termini/${params.bookingId}`,

  thankYou: () => `/hvala`,
  myAccount: params => `racun`,
}
