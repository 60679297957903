import styled from 'styled-components'
import { ButtonLabel } from '../Button/style'
export const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: calc((var(--vw, 1vw) * 100 - 40px));
  margin-right: ${props => (props.hasMargin ? '14px' : '0')};
  label {
    margin: ${props => (props.isMobile ? '0px !important' : 'inherit')};
    // margin-top: 10px;
  }

  .mbsc-mobiscroll.mbsc-input .mbsc-color-input {
    display: none;
    height: 0px;
  }
`

export const DropdownButton = styled.div`
  display: flex;
  flex-direction: column;

  ${ButtonLabel} {
    color: ${props =>
      props.isSelected && props.buttonType === 'secondary'
        ? props.theme.colors.text
        : 'auto'};
  }

  .mbsc-input {
    visibility: hidden;
    height: 0px;
    margin: 0px !important;
  }

  label {
    margin-bottom: 8px;
  }
`

export const ButtonError = styled.div`
  margin-top: 4px;
`
