import styled from 'themes'

export const SidebarRow = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
`

export const SettingsDivider = styled.div`
  height: 40px;
  width: 100%;
  min-height: 40px;
  background: ${props => props.theme.colors.lightBackground};
  margin-top: -2px;
  z-index: 999;
`

export const SidebarIconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
type TOrganizationSwitchIconProps = {
  size?: 'small' | 'large' | 'medium' | 'extraLarge'
  background?: string
}
export const OrganizationSwitchIcon = styled.div<TOrganizationSwitchIconProps>`
  height: ${props =>
    props.size === 'medium'
      ? '40px'
      : props.size === 'small'
      ? '20px'
      : props.size === 'large'
      ? '34px'
      : props.size === 'extraLarge'
      ? '64px'
      : '26px'};
  width: ${props =>
    props.size === 'medium'
      ? '40px'
      : props.size === 'small'
      ? '20px'
      : props.size === 'large'
      ? '34px'
      : props.size === 'extraLarge'
      ? '64px'
      : '26px'};
  font-style: normal;
  font-weight: 500;
  font-size: 0.786rem;
  line-height: 1.143rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.colors.light};
  background: ${props => props.background};
  border-radius: 100px;
  min-width: 20px;
  text-transform: uppercase;
  min-height: 20px;
  margin-left: auto;
`
