import React, {
  useState,
  useEffect,
  useMemo,
  lazy,
  Suspense,
  useCallback,
  useContext,
} from 'react'
import { AppServices } from './sdk/appServices'
import { Router, Switch, Route, withRouter } from 'react-router-dom'
import { AppContainer } from './styles'
import { ApolloProvider } from '@apollo/client'
import * as OfflinePluginRuntime from 'offline-plugin/runtime'
import mobiscroll from '@mobiscroll/react'
import { ThemeProvider } from 'styled-components'
import './themes.scss'
import { themes } from './themes'
import 'index.css'
import { AppContext } from './sdk/appServices'
import { PrivateRoute } from './sdk/PrivateRoute'
import { createBrowserHistory } from 'history'
import { useParams } from 'react-router'
import { getCurrentLanguage } from 'localization'
import { getISODate, useViewportDimensions } from './helpers'
import 'localization'
import GlobalStyles from './GlobalStyles'
import 'react-toastify/dist/ReactToastify.css'
import { CodeVerification } from './views/Signup/CodeVerification'
import { ClientRoutes } from './clientRoutes'
import { apolloClient } from './Apollo'
import { Loader } from 'components'
import Helmet from 'react-helmet'
import { ConsentManager } from '@segment/consent-manager'
import { useMarketMeQuery } from './state/graphql'
import { UserContext } from 'sdk/useAuth'

const Login = lazy(() => import('./views/Login/Login'))
const Signup = lazy(() => import('./views/Signup/Signup'))
const EmailConfirmation = lazy(
  () => import('./views/EmailConfirmation/EmailConfirmation')
)
const NotFound = lazy(() => import('./components/NotFound/NotFound'))
const ForgotPassword = lazy(
  () => import('./views/PasswordReset/ForgotPassword')
)

const NotificationPreferences = lazy(
  () =>
    import('./views/Settings/NotificationPreferences/NotificationPreferences')
)

const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
})
mobiscroll.setupReactRouter(Route, withRouter)

mobiscroll.customTheme('default', 'ios')
mobiscroll.customTheme('dark', 'ios')

OfflinePluginRuntime.install()
export const MobileContext = React.createContext(false)
export const PromptToInstall = React.createContext<any>(null)
export const ThemeContext = React.createContext({
  theme: themes['default'],
})

const appServices = new AppServices(apolloClient, history)

const App = () => {
  const [user, setUser] = useState()
  const userContextValue = useMemo(() => ({ user, setUser }), [user, setUser])

  const [selectedTheme, setSelectedTheme] = useState('default')
  const { isMobile } = useViewportDimensions()

  localStorage.removeItem('selectedEmployeeIds')

  const [deferredPrompt, setDeferredPrompt] = useState(null)

  const hidePrompt = useCallback(() => {
    setDeferredPrompt(null)
  }, [])

  useEffect(() => {
    const ready = e => {
      e.preventDefault()
      setDeferredPrompt(e)
    }

    window.addEventListener('beforeinstallprompt', ready)

    return () => {
      window.removeEventListener('beforeinstallprompt', ready)
    }
  }, [])

  useEffect(() => {
    localStorage.removeItem('selectedHour')
    localStorage.removeItem('selectedMinute')
  }, [])

  return (
    <PromptToInstall.Provider value={{ deferredPrompt, hidePrompt }}>
      <AppContext.Provider value={appServices}>
        <UserContext.Provider value={userContextValue}>
          <ApolloProvider client={apolloClient}>
            <MobileContext.Provider value={isMobile}>
              <div className={'kiamaki ' + (isMobile ? 'mobile' : 'desktop')}>
                <ThemeProvider
                  theme={{ ...themes[selectedTheme], name: selectedTheme }}
                >
                  <Helmet>
                    <link
                      rel="icon"
                      href={`${process.env.PUBLIC_URL}/favicon.ico`}
                    />
                    <meta property="og:locale" content="en_GB" />
                    <meta property="og:locale:alternate" content="hr_HR" />
                    <meta
                      name="eRed"
                      content="eRed - Aplikacija za naručivanj"
                    />
                    <meta
                      property="og:title"
                      content="eRed - Aplikacija za naručivanj"
                    />
                    <meta
                      property="og:description"
                      content="eRed - Aplikacija za naručivanj"
                    />
                    {getCurrentLanguage() === 'en' ? (
                      <title>{'eRed - Aplikacija za naručivanj'}</title>
                    ) : (
                      <title>{'eRed - Aplikacija za naručivanj'}</title>
                    )}
                  </Helmet>
                  <GlobalStyles
                    selectedTheme={themes[selectedTheme]}
                    theme={selectedTheme}
                  />
                  <Router history={history}>
                    <Suspense fallback={<Loader isCompomnent />}>
                      <Switch>
                        {/* <Route exact path={'/review/:token'}>
                        <ReviewPage />
                      </Route> */}
                        {/* <Route exact path={'/thankYou'}>
                        <ThankYouPage />
                      </Route> */}

                        <Route exact path={'/prijava'}>
                          <Login />
                        </Route>
                        <Route exact path={'/obnovalozinke/:token'}>
                          <ForgotPassword />
                        </Route>
                        <Route exact path={'/obnovalozinke'}>
                          <ForgotPassword />
                        </Route>
                        <Route exact path={'/postavkeobavijesti/:token'}>
                          <NotificationPreferences />
                        </Route>
                        {/* <Route path={'/en/signup'}>
                        <RedirectLanguage />
                      </Route> */}

                        <Route exact path={'/registracija'}>
                          <Signup />
                        </Route>
                        <Route exact path={'/emailpotvrdauspjeh'}>
                          <EmailConfirmation />
                        </Route>
                        <Route exact path={'/invite'}>
                          <Signup />
                        </Route>
                        <Route exact path={'/registracija/potvrda/:inviteId'}>
                          <CodeVerification />
                        </Route>

                        <Route path={`/:orgId/:locationId`}>
                          <AppComponent isMobile={isMobile} />
                        </Route>

                        <Route path={`/:orgId`}>
                          <AppComponent isMobile={isMobile} />
                        </Route>
                        <PrivateRoute path={`/:orgId/:locationId`}>
                          <AppComponent isMobile={isMobile} />
                        </PrivateRoute>

                        <Route path="/">
                          <AppComponent isMobile={isMobile} />
                        </Route>
                        <Route component={NotFound} />
                      </Switch>
                    </Suspense>
                  </Router>
                </ThemeProvider>
              </div>
            </MobileContext.Provider>
          </ApolloProvider>
        </UserContext.Provider>
      </AppContext.Provider>
    </PromptToInstall.Provider>
  )
}

export function usePromptToInstall() {
  const ctx = useContext(PromptToInstall)
  if (!ctx) {
    throw new Error(
      'Cannot use usePromptToInstall() outside <PromptToInstallProvider />'
    )
  }
  return ctx
}

function AppComponent({ isMobile }) {
  const { orgId, locationId } = useParams<any>()
  const { data: userData } = useMarketMeQuery()
  const user = userData?.market.me
  useEffect(() => {
    orgId && localStorage.setItem('orgId', orgId)
    locationId && sessionStorage.setItem('locationId', locationId)
    locationId && localStorage.setItem('locationId', locationId)
  }, [orgId, locationId])

  const appUser = useMemo(
    () => ({
      ...(user || {}),
      user_id: user?.id,
      OrganizationID: orgId,
      LocationID: locationId,
      Phone: user?.phone,
      RoleID: user?.role,
      StartDate: getISODate(),
      IsOrganizationOwner: false,
      gender: user?.gender,
      name: user?.firstName + ' ' + user?.lastName,
      isMobile: isMobile,
      language: user?.language || getCurrentLanguage(),
      //@ts-ignore
      Isapp: !!navigator.standalone,
      UserType: 'B2C - Client',
    }),
    [isMobile, locationId, user, orgId]
  )

  const analytics = window.analytics || {}
  useEffect(() => {
    if (!user?.id || !analytics || typeof analytics.identify !== 'function')
      return
    analytics.identify(user.id, {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      ...appUser,
    })
  }, [user, appUser, analytics])

  var bannerContent = React.createElement(
    'span',
    { style: { fontWeight: 600 } },
    'Mi koristimo kolačiće kako bismo poboljšali iskustvo aplikacije. Korištenjem ove stranice,' +
      ' pristajete na prikupljanje podataka kao što je opisano u ',
    ' ',
    React.createElement(
      'a',
      { href: 'https://e-red.hr/politika-kolacica', target: '_blank' },
      'Politici Kolačića'
    ),
    '.'
  )
  var bannerSubContent = 'Promjeni postavke.'
  var preferencesDialogTitle = 'Postavke prikupljanja podataka'
  var preferencesDialogContent =
    'Mi koristimo prikupljanje podataka pomoću kolačića kako bismo poboljšali iskustvo i' +
    ' performanse' +
    ' korištenja ove aplkacije te analizirali promet. '

  var cancelDialogTitle = 'Jeste li sigurni da želite odustati?'
  var cancelDialogContent =
    'Vaše postavke nisu spremljene. Ako nastavite, slažete se s našom Politikom Kolačića'

  return (
    <AppContainer isMobile={isMobile}>
      <ClientRoutes isMobile={isMobile} />
      <div style={{ position: 'fixed', bottom: 0, width: '100%', zIndex: 999 }}>
        <ConsentManager
          writeKey={'Ad8nMgzE38Jg66FF4NK45lPzkpBYWcpx'}
          bannerContent={bannerContent}
          bannerSubContent={bannerSubContent}
          preferencesDialogTitle={preferencesDialogTitle}
          preferencesDialogContent={preferencesDialogContent}
          cancelDialogTitle={cancelDialogTitle}
          cancelDialogContent={cancelDialogContent}
          bannerBackgroundColor={' #ffca1c'}
          bannerTextColor={'#004288'}
          closeBehavior={categories => ({
            ...categories,
            advertising: false,
          })}
          implyConsentOnInteraction={true}
        />
      </div>
      <div id={'intercom-launcher'} />
    </AppContainer>
  )
}

export default App
