import { createGlobalStyle } from 'styled-components'
import { fontFamily } from './components/style/fonts'
import 'fonts/Icons_Mobiscroll/mobiscroll.react.min.css'
import { device } from './constants'
import { ITheme } from 'themes'
const GlobalStyles = createGlobalStyle<{ selectedTheme: ITheme }>`

#intercom-launcher{
display: none;
}

.react-calendar__navigation__label__labelText.react-calendar__navigation__label__labelText--from {
font-size: 18px;
    font-family: ${fontFamily} !important;
    
    @media ${device.tablet}{
        font-size: 16px;
    }
}


#target-container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}
  
.HW_badge_cont{
top: -5px;
right: -10px;
position: absolute !important;

@media ${device.tablet}{
        right: 25px !important;
    top: 7px !important;
}
}
.HW_badge {
background: #fe6160 !important;

@media ${device.tablet}{
        width: 26px !important;
    height: 26px !important;
    font-size: 14px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
}
}

.rc-tooltip-inner{
@media ${device.tablet}{
border: none;
}
}
.react-tel-input input {
min-height: 47px;
}
p {
margin-bottom: 0px;
}
  .react-tel-input {
    width: auto;
  }
  
:focus {
   @media ${device.tablet}{
    outline: none}
}
.rc-tooltip{
background: ${props => props.selectedTheme.colors.light};
    z-index: 999999;
}



#react-select-portal-target {
 div {
z-index: 99999999999999;
}
}
.Toastify__toast-container {
border-radius: 10px;
z-index: 9999999999;
    width: auto;
    max-width: 420px;
    
    @media ${device.tablet}{
      max-width: 100%;
      width: 100%;
      border-radius: 0px;
    }
}

.Toastify__toast-body{
  @media ${device.tablet}{
     text-align: center;
    }
}

.Toastify__toast {
 @media ${device.tablet}{
     min-height: 50px;
    }
}

.Toastify__toast--success {
background: ${props => props.selectedTheme.colors.primary} !important; 
  border-radius: 10px !important;
    padding: 10px 20px !important;
    
    @media ${device.tablet}{
             border-radius: 0px !important;
    }
}

.Toastify__toast--error {
background: ${props => props.selectedTheme.colors.accent5} !important; 
  border-radius: 10px !important;
    padding: 10px 20px !important;
}

.Toastify__toast--default {
    border-radius: 10px !important;
    padding: 10px 20px !important;
}


//POPUP
.mbsc-fr {
z-index: 999999999999;
}

.mbsc-mobiscroll .mbsc-fr-w {
  
    width: 400px !important;
}

.mbsc-alert.mbsc-fr .mbsc-fr-c.mbsc-wdg-c {
    max-width: 400px !important;
}

.mbsc-mobiscroll.mbsc-input .mbsc-label {
    position: absolute;
    top: 0;
    font-size: .75em;
    color: #333;
    font-size: 1rem;
    font-weight: 500;
    line-height: 28px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    cursor: inherit;
    margin-bottom: 16px;
    margin-right: 0px;
}

.mbsc-mobiscroll.mbsc-input .mbsc-control {
    border-radius: 4px;
    box-shadow: 2px 2px 0 2px transparent;
    -webkit-transition-property: border-width,border-color,box-shadow;
    transition-property: border-width,border-color,box-shadow;
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
    border: 1px solid #dbdfe2;
    box-sizing: border-box;
    margin-top: 8px;
    margin-bottom: 8px;
    resize: none;
    padding: 8px;
    color: #3D3D3D;
    font-size: 1rem;
    font-weight: 400;
    line-height: 28px;
    width: 97%;
    height: 50px;
    margin: 3px;
    margin-top: 30px;
}

.mbsc-mobiscroll.mbsc-input .mbsc-control:focus, .mbsc-mobiscroll.mbsc-input select:focus ~ input .mbsc-input textarea:focus {
    /* border-color: #4eccc4; */
    border: 1px solid #1a74bb;
    box-shadow: 0px 0px 0px 3px #ABB7FF;
    outline: none;
    -webkit-transition-property: border-width,border-color,box-shadow;
    transition-property: border-width,border-color,box-shadow;
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
}

.mbsc-mobiscroll .mbsc-fr-btn.mbsc-active, .mbsc-mobiscroll.mbsc-no-touch .mbsc-fr-btn-e:not(.mbsc-disabled):hover {
    background: rgb(106 78 225 / 20%);
    border-radius: 4px;
}

.mbsc-mobiscroll .mbsc-fr-hdr, .mbsc-mobiscroll .mbsc-fr-btn{
color: ${props => props.selectedTheme.colors.primary} !important;
}


[data-reach-tooltip] {
z-index: 999999999999999 !important;
}

html,
body {
    font-family: ${fontFamily};
    width: 100%;
    height: 100%;
background: #004187;
    font-size: 14px;
    
     @media ${device.tablet}{
     overflow: hidden;
    user-select: none;
        &::-webkit-scrollbar {
      display: none;
    }
    }
}
.kiamaki {
height: 100%;
display: flex;

@media ${device.tablet}{
    max-height: calc((var(--vh, 1vh) * 100));
}
}

input {
font-family: ${fontFamily} !important;
}

a {
    display: flex;
    align-items: center;
    color: inherit;
    text-decoration: none;
    
    :hover {
        text-decoration: none;
    }
}

*,
*::before,
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

#root {
    width: 100%;
    height: 100%;

}


`

export default GlobalStyles
