import i18n from 'i18next'
import detector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import regionalFormatsHR from './localizations/hr/regionalFormats.json'
import translationHR from './localizations/hr/translation.json'

import regionalFormatsEN from './localizations/en/regionalFormats.json'
import translationEN from './localizations/en/translation.json'

// the translations
// (tip move them in a EN file and import them)
const resources = {
  en: {
    translation: {
      regionalFormats: regionalFormatsHR,
      translation: translationHR,
    },
  },
  hr: {
    translation: {
      regionalFormats: regionalFormatsHR,
      translation: translationHR,
    },
  },
}

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'hr',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export const getCurrentLanguage = () => {
  // const language = localStorage.getItem('i18nextLng')
  return 'hr'
}
export default i18n
