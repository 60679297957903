import React, { useContext } from 'react'
import { animated, useSpring } from 'react-spring'
import { MobileContext } from '../../App'

export const AnimatedDiv = props => {
  const { children, handlers, style, isHome } = props
  const animationProps = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 700 },
  })

  const isMobile = useContext(MobileContext)

  return (
    <animated.div
      style={{
        ...animationProps,
        overflow: isMobile ? 'initial' : 'hidden',
        width: '100%',
        height: '100%',
        display: isHome && isMobile ? 'flex' : 'initial',
        flexDirection: 'column',
        ...style,
      }}
      handlers={handlers}
    >
      {children}
    </animated.div>
  )
}
