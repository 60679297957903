import Add from './Icons/Add'
import Alarm from './Icons/Alarm'
import ArrowLeft from './Icons/ArrowLeft'
import ArrowNext from './Icons/ArrowNext'
import ArrowRight from './Icons/ArrowRight'
import ArrowRightThick from './Icons/ArrowRightThick'
import Billing from './Icons/Billing'
import Book from './Icons/Book'
import Briefcase from './Icons/Briefcase'
import BriefCaseLight from './Icons/BriefCaseLight'
import Calendar from './Icons/Calendar'
import Chart from './Icons/Chart'
import ChartArrowDown from './Icons/ChartArrowDown'
import ChartArrowUp from './Icons/ChartArrowUp'
import ChatHelp from './Icons/ChatHelp'
import Check from './Icons/Check'
import CheckBox from './Icons/CheckBox'
import Checkmark from './Icons/Checkmark'
import ChevDown from './Icons/ChevDown'
import ChevronDown from './Icons/ChevronDown'
import ChevronLeft from './Icons/ChevronLeft'
import ChevronRight from './Icons/ChevronRight'
import ChevronUp from './Icons/ChevronUp'
import ChewronDownLight from './Icons/ChewronDownLight'
import Clients from './Icons/Clients'
import Clock from './Icons/Clock'
import CreditCard from './Icons/CreditCard'
import CroatianFlag from './Icons/CroatianFlag'
import Cross from './Icons/Cross'
import CSV from './Icons/CSV'
import Dashboard from './Icons/Dashboard'
import Day from './Icons/Day'
import Edit from './Icons/Edit'
import Email from './Icons/Email'
import Employees from './Icons/Employees'
import Error from './Icons/Error'
import Facebook from './Icons/Facebook'
import Flash from './Icons/Flash'
import Gear from './Icons/Gear'
import GeneralInfo from './Icons/GeneralInfo'
import Gift from './Icons/Gift'
import Google from './Icons/Google'
import Group from './Icons/Group'
import HamburgerMenu from './Icons/HamburgerMenu'
import Holidays from './Icons/Holidays'
import House from './Icons/House'
import HouseBold from './Icons/HouseBold'
import Icon from './Icons/Icon'
import Info from './Icons/Info'
import Intercom from './Icons/Intercom'
import Light from './Icons/Light'
import Location from './Icons/Location'
import Login from './Icons/Login'
import Logout from './Icons/Logout'
import Menu from './Icons/Menu'
import More from './Icons/More'
import NewLocation from './Icons/NewLocation'
import NewWindow from './Icons/NewWindow'
import OnlineBooking from './Icons/OnlineBooking'
import PasswordLock from './Icons/PasswordLock'
import PDF from './Icons/PDF'
import Phone from './Icons/Phone'
import PhoneBold from './Icons/PhoneBold'
import PhoneDownload from './Icons/PhoneDownload'
import PhoneEmail from './Icons/PhoneEmail'
import Professions from './Icons/Professions'
import RatingStar from './Icons/RatingStar'
import Refresh from './Icons/Refresh'
import Register from './Icons/Register'
import SafariShare from './Icons/SafariShare'
import Search from './Icons/Search'
import SendEmail from './Icons/SendEmail'
import Services from './Icons/Services'
import Shop from './Icons/Shop'
import ShoppingCart from './Icons/ShoppingCart'
import Star from './Icons/Star'
import ThreeDay from './Icons/ThreeDay'
import Tour from './Icons/Tour'
import Trash from './Icons/Trash'
import UKFlag from './Icons/UKFlag'
import User from './Icons/User'
import UserBold from './Icons/UserBold'
import UserMan from './Icons/UserMan'
import Warning from './Icons/Warning'
import Web from './Icons/Web'
import Week from './Icons/Week'
import WorkHours from './Icons/WorkHours'

export const Icons = {
  Add,
  Alarm,
  ArrowLeft,
  ArrowNext,
  ArrowRight,
  ArrowRightThick,
  Billing,
  Book,
  Briefcase,
  BriefCaseLight,
  Calendar,
  Chart,
  ChartArrowDown,
  ChartArrowUp,
  ChatHelp,
  Check,
  CheckBox,
  Checkmark,
  ChevDown,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChewronDownLight,
  Clients,
  Clock,
  CreditCard,
  CroatianFlag,
  Cross,
  CSV,
  Dashboard,
  Day,
  Edit,
  Email,
  Employees,
  Error,
  Facebook,
  Flash,
  Gear,
  GeneralInfo,
  Gift,
  Google,
  Group,
  HamburgerMenu,
  Holidays,
  House,
  HouseBold,
  Icon,
  Info,
  Intercom,
  Light,
  Location,
  Login,
  Logout,
  Menu,
  More,
  NewLocation,
  NewWindow,
  OnlineBooking,
  PasswordLock,
  PDF,
  Phone,
  PhoneBold,
  PhoneDownload,
  PhoneEmail,
  Professions,
  RatingStar,
  Refresh,
  Register,
  SafariShare,
  Search,
  SendEmail,
  Services,
  Shop,
  ShoppingCart,
  Star,
  ThreeDay,
  Tour,
  Trash,
  UKFlag,
  User,
  UserBold,
  UserMan,
  Warning,
  Web,
  Week,
  WorkHours,
}
