import React from 'react'
import { useSdk } from 'sdk'
import { ErrorContainer, ErrorText, ErrorTitle } from './style'

export const NotFound = () => {
  const { t } = useSdk()

  return (
    <ErrorContainer>
      <img
        src={process.env.PUBLIC_URL + '/assets/illustrations/oh-no.svg'}
        alt=""
      />
      <ErrorTitle>{t('translation.NotFound.label-are-you-lost')}</ErrorTitle>
      <ErrorText>{t('translation.NotFound.label-page-not-found')}</ErrorText>
    </ErrorContainer>
  )
}
