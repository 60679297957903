export const publicAssets = {
  '404.svg': `${process.env.PUBLIC_URL}/assets/404.svg`,
  'akd-desktop-bg.svg': `${process.env.PUBLIC_URL}/assets/akd-desktop-bg.svg`,
  'akd-logo-blue.svg': `${process.env.PUBLIC_URL}/assets/akd-logo-blue.svg`,
  'akd-logo-white.svg': `${process.env.PUBLIC_URL}/assets/akd-logo-white.svg`,
  'akd-red-logo.svg': `${process.env.PUBLIC_URL}/assets/akd-red-logo.svg`,
  'akd-small-logo.svg': `${process.env.PUBLIC_URL}/assets/akd-small-logo.svg`,
  'default-avatar.svg': `${process.env.PUBLIC_URL}/assets/default-avatar.svg`,
  'empty-box.svg': `${process.env.PUBLIC_URL}/assets/empty-box.svg`,
  'empty-image.svg': `${process.env.PUBLIC_URL}/assets/empty-image.svg`,
  'empty-reservations.svg': `${process.env.PUBLIC_URL}/assets/empty-reservations.svg`,
  'eRed-background.svg': `${process.env.PUBLIC_URL}/assets/eRed-background.svg`,
  'eRed-logo.svg': `${process.env.PUBLIC_URL}/assets/eRed-logo.svg`,
  'eRed.svg': `${process.env.PUBLIC_URL}/assets/eRed.svg`,
  'eRedLogo.svg': `${process.env.PUBLIC_URL}/assets/eRedLogo.svg`,
  'favicon.ico': `${process.env.PUBLIC_URL}/assets/favicon.ico`,
  'navigation-menu.svg': `${process.env.PUBLIC_URL}/assets/navigation-menu.svg`,
  'navigation-menu2.svg': `${process.env.PUBLIC_URL}/assets/navigation-menu2.svg`,
  'noImage.svg': `${process.env.PUBLIC_URL}/assets/noImage.svg`,
  'notificationSettings.svg': `${process.env.PUBLIC_URL}/assets/notificationSettings.svg`,
  'ooops.svg': `${process.env.PUBLIC_URL}/assets/ooops.svg`,
  'reception.svg': `${process.env.PUBLIC_URL}/assets/reception.svg`,
  'red.svg': `${process.env.PUBLIC_URL}/assets/red.svg`,
}
