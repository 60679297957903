import styled from 'themes'
import { NavLink } from 'react-router-dom'
import { fontFamily } from './components/style/fonts'
import { AvatarWrapper } from './components/Avatar/Avatar'
import { ButtonStyled } from './components/Button/Button'
import { DropdownWrapper } from './components/Dropdown/styles'
import { device } from './constants'
import { TooltipWrapper } from './components/Tooltip/style'
import { ButtonLabel } from './components/Button/style'
import { FieldRow } from './views/Settings/styles'
import { SelectStyled } from './components/Select/style'
import { StyledSelect } from './components/Select2/style'
import { FormContentContainer, FormFooterContainer } from './components'
import { OrganizationSwitchIcon } from './layout/styles'
import { transparentize } from 'polished'

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
export let vh = window.innerHeight * 0.01
export let vw = window.innerWidth * 0.01
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`)
document.documentElement.style.setProperty('--vw', `${vw}px`)

type TAppContainerProps = {
  isMobile?: boolean
}
export const AppContainer = styled.div<TAppContainerProps>`
  background-color: ${props =>
    props.isMobile ? props.theme.colors.gradientBackground : 'transparent'};
  color: ${props => props.theme.colors.text};
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const ClientAppWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background: white;
  flex-direction: column;
`
type TClientBodyWrapperProps = {
  showMobileFooter?: boolean
}
export const ClientBodyWrapper = styled.div<TClientBodyWrapperProps>`
  margin: 0 auto;
  width: 100%;
  position: relative;
  max-width: 1220px;
  margin-top: 100px;
  @media ${device.tablet} {
    margin-top: 0px;
    margin-bottom: 40px;
    padding: 0px 20px;

    overflow: ${props => (props.showMobileFooter ? 'auto' : 'initial')};
  }
`

type TWizardBodyWrapperProps = {
  isHome?: boolean
}
export const WizardBodyWrapper = styled.div<TWizardBodyWrapperProps>`
  margin: 0 auto;
  width: 100%;
  position: relative;
  margin-top: ${props => (props.isHome ? '-90px' : '0')};
  @media ${device.tablet} {
    margin-top: 0px;
    overflow: auto;
    height: 100%;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`

type TClientAppBodyProps = {
  isHome?: boolean
}
export const ClientAppBody = styled.div<TClientAppBodyProps>`
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  background: ${props =>
    props.isHome ? 'linear-gradient(45deg,#1a74bb,#004187)' : 'transparent'};

  @media ${device.tablet} {
    height: 100%;
    flex-direction: column;
    overflow: hidden;
  }
`
type TContainerProps = {
  isInitial?: boolean
}
export const Container = styled.div<TContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: ${props => (props.isInitial ? 'initial' : 'relative')};
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }

  @media ${device.tablet} {
    padding: 0px 15px;
  }
`

export const FormsContainer = styled.div`
  @media ${device.tablet} {
    padding: 10px 0px;
  }
`

export const FooterButtonMobile = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 5px 1.071rem;
  position: absolute;
  bottom: 0;
  border-radius: 0px 0px 0.714rem 0.714rem;
  border-top: 1px solid ${props => props.theme.colors.outline};
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  transition-delay: 0.1s;
  opacity: 0;
  left: 0;
  &.show {
    opacity: 1;
  }

  ${ButtonStyled} {
    height: 40px !important;
    font-size: 14px !important;
  }
`

export const Footer = styled.div`
  width: 100%;
  background: ${props => props.theme.colors.light};
  padding-bottom: 10px;
  @media ${device.tablet} {
    box-shadow: 0 -4px 10px 0 rgba(0, 0, 0, 0.1);
    z-index: 999;
    max-height: 80px;
    @supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices */
      padding-bottom: 20px;
    }
  }
`
type TNavigationButtonsProps = {
  isMobile?: boolean
}
export const NavigationButtons = styled.div<TNavigationButtonsProps>`
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: ${props => (props.isMobile ? '0' : '1.429rem')};
  height: 60px;
  @media ${device.tablet} {
    height: 60px;
    max-height: 60px;
    overflow: hidden;
  }
`

export const NavigationItemWrapper = styled.div``
type TNavTitleProps = {
  isSelected?: boolean
}
export const NavTitle = styled.span<TNavTitleProps>`
  color: ${props =>
    props.isSelected ? props.theme.colors.primary : props.theme.colors.text};
  font-size: 8px;
  font-weight: 400;
  margin-top: 5px;
  text-align: center;
  letter-spacing: 0.2px;
`

export const NavSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
type TNavigationItemProps = {
  isSelected?: boolean
}
export const NavigationItem = styled(NavLink)
  .attrs(props => ({
    activeClassName: 'active',
  }))
  .withConfig({
    shouldForwardProp: (prop, defaultValidatorFn) =>
      !['isSelected', 'isSecondChild'].includes(prop),
  })<TNavigationItemProps>`  
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 10px;
  min-height:50px;
  color: ${props => props.theme.colors.text};
  width: 100%;
  margin-bottom: 5px;
  svg {
    color: ${props =>
      props.isSelected
        ? props.theme.colors.primary
        : props.theme.colors.secondaryDark};
  }
  svg.chavron {
    margin-right: 20px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &.active {
    background: rgb(220 221 229);
    .nav-title{
      font-weight:bold
    }
  }
  @media ${device.tablet} {
    margin-left: 0px;
    margin: 0px 1rem;
    padding: 15px 0px;
    min-height: 60px;
        border-bottom: 1px solid #dbdfe2;
    width: auto;
    &.active {
      background: initial;      
    }
    svg {
      color: ${props =>
        props.isSelected
          ? props.theme.colors.primary
          : props.theme.colors.text};
    }
  }
`
type TNavigationTitleProps = {
  isSelected?: boolean
  isSubItem?: boolean
}
export const NavigationTitle = styled.span<TNavigationTitleProps>`
  font-weight: ${props => (props.isSelected ? '600' : '400')};
  font-size: 13px;
  line-height: 1.5rem;
  letter-spacing: 0.1px;
  margin-right: auto;
  max-width: ${props => (props.isSubItem ? '100px' : '100%')};
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline;
  overflow: hidden;
  color: ${props => props.theme.colors.text};

  @media ${device.tablet} {
    font-weight: 500;
    font-size: 1.15rem;
  }
`
export const FooterWrapper = styled.div`
  background: ${props => props.theme.colors.light};
  height: 100%;

  ${NavigationItem} {
    margin-top: 7px;
  }
`

export const NavigationNumber = styled.div`
  width: 20px;
  height: 20px;
  background: ${props => props.theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  position: absolute;
  right: 0px;
  top: -5px;
  span {
    font-size: 12px;
    font-weight: 500;
    color: ${props => props.theme.colors.text};
  }
`

export const NavigationItems = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1.429rem;
  a:first-child {
    margin-top: 0px !important;
  }
`
type TTableHeaderItemProps = {
  isHomeTable?: boolean
}
export const TableHeaderItem = styled.div<TTableHeaderItemProps>`
  color: ${props => props.theme.colors.accent3};
  font-size: 14px;
  text-align: left;
  padding: 10px 14px;
  background: ${props => props.theme.colors.accent1};
  border-bottom: ${props =>
    props.isHomeTable ? '1px solid ' + props.theme.colors.outline : 'none'};
  text-transform: uppercase;
  position: sticky;
  top: 0px;
  z-index: 1;
  font-weight: 600;
  // margin: 0 5px;
  .resizer {
    display: inline-block;
    background: ${props => props.theme.colors.light};
    width: 10px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    ${'' /* prevents from scrolling while dragging on touch devices */}
    touch-action:none;

    &.isResizing {
      background: ${props => props.theme.colors.accent5};
    }
  }
`
type TTableItemProps = {
  isHomeTable?: boolean
  hasAvatar?: boolean
}
export const TableItem = styled.div.withConfig({
  shouldForwardProp: prop =>
    !['isWide', 'isHomeTable', 'hasAvatar', 'isDisabled', 'isAvatar'].includes(
      prop
    ),
})<TTableItemProps>`
  cursor: pointer;
  font-style: normal;
  font-weight: ${props => (props.isHomeTable ? '400' : '500')};
  font-size: ${props => (props.isHomeTable ? '13px' : '1rem !important')};
  line-height: 1.429rem;
  color: ${props => props.theme.colors.text} !important;
  font-family: ${fontFamily};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  height: 35px;

  ${AvatarWrapper} {
    float: ${props => (props.hasAvatar ? 'left' : 'none')};
  }

  span {
    float: ${props => (props.hasAvatar ? 'left' : 'none')};
    margin-left: ${props => (props.hasAvatar ? '10px' : 'inherit')};
    display: inline;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  @media ${device.tablet} {
    img {
      max-width: 25px;
    }
  }
`
type TTableRowProps = {
  isDisabled?: boolean
  isExpired?: boolean
}
export const TableRow = styled.div<TTableRowProps>`
  &:hover {
    background: ${props =>
      props.isDisabled ? 'transparent' : props.theme.colors.lightBackground};
  }

  @media ${device.tablet} {
    :hover {
      background: transparent;
    }
  }

  ${TableItem} {
    color: ${props =>
      props.isExpired
        ? props.theme.colors.secondaryDark
        : props.theme.colors.text} !important;
  }

  .moveButton {
    cursor: move;
    button {
      .mbsc-ic {
        transform: rotate(90deg);
        color: ${props => props.theme.colors.secondaryDark}!important;
        cursor: move !important;
      }
    }
  }
`

export const ContainerRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
  @media ${device.tablet} {
    flex-direction: column;
    margin-bottom: 0px;
  }
`

export const ContainerRowDays = styled(ContainerRow)`
  @media ${device.tablet} {
    flex-direction: row;
  }
`

export const ContainerColumn = styled.div`
  display: flex;
  flex: 1;

  &:not(:last-child) {
    margin-right: 14px;
  }

  ${DropdownWrapper} {
    margin-right: 0px;

    button {
      margin-right: 0px !important;
    }
  }
  @media ${device.tablet} {
    width: 100%;

    margin-bottom: 10px;

    &:not(:last-child) {
      margin-right: 0px;
    }
  }
`

export const ModalBodyContainer = styled.div`
  max-height: calc((var(--vh, 1vh) * 100 - 35px));
  width: 100%;
  display: flex;
  flex-direction: column;
  @media ${device.tablet} {
    display: inline;
    overflow: auto;
    max-height: calc((var(--vh, 1vh) * 100));

    ::-webkit-scrollbar {
      display: none;
    }
  }
`
export const FormPageContainer = styled.div`
  max-height: 100%;
  overflow: auto;

  @media ${device.tablet} {
    padding: 0px 15px;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`

type TFormContainerProps = {
  isMobile?: boolean
  isFullHeight?: boolean
}
export const FormContainer = styled.div.withConfig({
  shouldForwardProp: prop => !['isMobile', 'isFullHeight'].includes(prop),
})<TFormContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${props => (props.isMobile ? '15px' : '20px')};
  height: ${props => (props.isFullHeight ? '100%' : 'auto')};

  @media ${device.tablet} {
    display: initial;
    height: 100%;
    padding: 20px 20px 20px 10px;
  }
`
export const ListBodyWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: ${props => props.theme.colors.light};
  height: 100%;
  min-height: 70vh;
  width: 100%;
  overflow: hidden;
  @media ${device.tablet} {
    &::-webkit-scrollbar {
      display: none;
    }
    min-height: 0px;
    flex: 1;
    display: block;
    overflow: initial;
  }
`
export const ListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding: 20px;
  background: ${props => props.theme.colors.light};
  &::-webkit-scrollbar {
    display: none;
  }

  @media ${device.tablet} {
    padding: 10px 0px !important;
    overflow: hidden;
  }
`
export const UserAvatarWrapper = styled.div`
  padding-bottom: 27px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`
export const ZoyyaLogo = styled.div`
  margin-top: -25px;

  img {
    width: 120px;
  }
`
export const UserAvatar = styled.div`
  width: 112px;
  height: 112px;
  border: 3px solid ${props => props.theme.colors.primary};
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  img {
    width: 112px;
    height: 112px;
    border-radius: 100px;
    object-fit: cover;
  }
`
export const UserName = styled.span`
  font-weight: 600;
  font-size: 20px;
  color: ${props => props.theme.colors.text};
  line-height: 35px;
  margin-top: 10px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
`
export const NavigationButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  color: ${props => props.theme.colors.text};
  width: calc(100% - 40px);
  margin-left: 0px;
  border-bottom: 1px solid ${props => props.theme.colors.outline};
  padding: 20px 0;
  min-height: 60px;

  @media ${device.tablet} {
    margin-left: 0px;
    margin: 0px 1rem;
    padding: 15px;
    min-height: 60px;
    border-bottom: none;
    background: ${props => props.theme.colors.lightBackground};
    width: auto;
    margin-bottom: 10px;
    border-radius: 10px;
  }
`
type TEventRowProps = {
  isSticky?: boolean
}
export const EventRow = styled.div<TEventRowProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: ${props => (props.isSticky ? 'sticky' : 'relative')};
  top: -2px;
  background: white;
  z-index: 9;
  margin-bottom: 20px;
  @media ${device.tablet} {
    position: relative;
    padding: 10px 10px 0px 10px;
    margin-bottom: 0px;
  }
`
export const EventGroupCapacity = styled.div`
  position: absolute;
  right: 20px;
  top: 10px;
  font-size: 26px;
  font-weight: 500;
  color: ${props => props.theme.colors.text};
`
type TDatePickerCalendarContainerProps = {
  isHeaderPicker?: boolean
  isAbsolute?: boolean
}
export const DatePickerCalendarContainer = styled.div<
  TDatePickerCalendarContainerProps
>`
  margin: 10px 0px;
  overflow: hidden;
  display: flex;
  top: ${props => (props.isHeaderPicker ? '40px' : 'initial')};
  position: ${props => (props.isAbsolute ? 'absolute' : 'initial')};
  background: ${props => (props.isAbsolute ? 'white' : 'transparent')};
  box-shadow: ${props =>
    props.isAbsolute ? '0px 0px 10px 0px #8080803b' : 'none'};
  border-radius: ${props => (props.isAbsolute ? '4px' : '0')};
  z-index: 99999;

  @media ${device.tablet} {
    overflow: auto;
    max-height: calc((var(--vh, 1vh) * 100 - 100px));
    margin-top: 0px;
  }
`
export const DatePickerLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`
export const DatePickerHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: -5px;

  span {
    color: ${props => props.theme.colors.secondaryDark};
  }
`
export const DateArrow = styled.div`
  width: 25px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  span {
    color: ${props => props.theme.colors.text};
  }
  &:hover {
    span {
      color: ${props => props.theme.colors.text};
    }
  }
`
export const DatePickerContainer = styled.div`
  display: flex;
  align-content: center;
  border-radius: 4px;
  margin: auto;
  border: 1px solid ${props => transparentize(0.4, props.theme.colors.outline)};
`
export const DatePickerWrapper = styled.div`
  width: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const DateButton = styled.button`
  padding: 8px 8px;
  white-space: nowrap;

  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  user-select: none;
  text-align: center;
  cursor: pointer;
  border-radius: 0px;
  font-size: 14px;
  height: 32px;
  font-family: ${fontFamily};

  &:focus,
  :active {
    border: none;
    outline: none;
  }
`
export const DatePickerArrow = styled(DateButton)`
  box-shadow: none;
  transform: none;
  color: #101928;
  font-weight: 500;
  line-height: 16px;
  text-transform: none;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
  min-width: 40px;
  background: ${props => props.theme.colors.light};
  border: none;
  border-radius: 4px;

  &:first-child {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: 0;
  }

  &:last-child {
    border-left: 1px solid
      ${props => transparentize(0.4, props.theme.colors.outline)};
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
`
type TDatePickerTodayProps = {
  isToday?: boolean
}
export const DatePickerToday = styled(DateButton)<TDatePickerTodayProps>`
  min-width: 80px;
  background-color: ${props =>
    !props.isToday
      ? transparentize(0.6, props.theme.colors.accent4)
      : transparentize(0.6, props.theme.colors.outline)};

  height: 32px;
  border: none;
  box-shadow: none;
  transform: none;
  color: ${props => props.theme.colors.text};
  font-weight: 500;
  line-height: 16px;
  text-transform: none;
`
export const DatePickerDate = styled(DateButton)`
  background: ${props => props.theme.colors.light};
  min-width: 180px;
  border: none;
  cursor: pointer;
`
export const ArrowLeft = styled(DatePickerArrow)`
  border-top-right-radius: 3px !important;
  border-bottom-right-radius: 3px !important;
  padding-left: 8px;
  padding-right: 8px;

  span {
    color: ${props => props.theme.colors.secondaryDark};
    font-size: 12px;
  }
`
export const DatePickerName = styled.p`
  font-size: 16px;

  text-align: center;
  margin-right: auto;
  color: ${props => props.theme.colors.text};
`
type TDatePickerCalendarStyledProps = {
  isHeaderPicker?: boolean
}
export const DatePickerCalendarStyled = styled.div<
  TDatePickerCalendarStyledProps
>`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(7, minmax(10px, 1fr));
  grid-template-rows: auto;
  grid-auto-rows: auto;
  overflow: hidden;
  position: relative;
  height: 100%;
  margin-top: ${props => (props.isHeaderPicker ? '10px' : '0')};
`
export const DatePickerMonthlyStyled = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, minmax(45px, 1fr));
  grid-template-rows: 50px;
  grid-auto-rows: auto;
  overflow: hidden;
  position: relative;
  height: 100%;
`
type TMonthDayNameProps = {
  isDisabled?: boolean
  isCurrent?: boolean
  isSelected?: boolean
  isHovered?: boolean
  isSmall?: boolean
  isHeaderPicker?: boolean
}

export const MonthDayName = styled.span<TMonthDayNameProps>`
  letter-spacing: 1px;
  font-size: 10px;
  background: ${props =>
    props.isCurrent && !props.isDisabled
      ? props.theme.colors.accent4
      : props.isSelected
      ? props.theme.colors.primary
      : props.isHovered
      ? transparentize(0.9, props.theme.colors.primary)
      : 'transparent'};
  color: ${props =>
    (props.isCurrent && !props.isDisabled) || props.isSelected
      ? props.theme.colors.light
      : props.theme.colors.text};
  padding: ${props => (props.isSmall && !props.isCurrent ? '0' : '3px 6px;')};
  border-radius: 4px;

  width: ${props => (props.isHeaderPicker ? '30px' : '20px;')};
  height: ${props => (props.isHeaderPicker ? '30px' : '22px;')};
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: ${props => transparentize(0.9, props.theme.colors.primary)};
  }
`
type TMonthNameProps = {
  isSelected?: boolean
  isSmall?: boolean
  isCurrent?: boolean
  isDisabled?: boolean
  isHovered?: boolean
}
export const MonthName = styled.span<TMonthNameProps>`
  letter-spacing: 1px;
  font-size: 12px;
  background: ${props =>
    props.isCurrent && !props.isDisabled
      ? props.theme.colors.accent4
      : props.isSelected
      ? props.theme.colors.primary
      : props.isHovered
      ? transparentize(0.9, props.theme.colors.primary)
      : props.theme.colors.light};
  color: ${props =>
    (props.isCurrent && !props.isDisabled) || props.isSelected
      ? props.theme.colors.light
      : props.theme.colors.secondaryDark};
  padding: ${props => (props.isSmall && !props.isCurrent ? '0' : '3px 6px;')};
  border-radius: 4px;
  width: 65px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: ${props => transparentize(0.9, props.theme.colors.primary)};
  }
`
export const DatePickerCalendarMonth = styled.div`
  text-align: right;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;

  &:nth-of-type(n + 1):nth-of-type(-n + 4) {
    grid-row: 1;
  }

  &:nth-of-type(n + 8):nth-of-type(-n + 8) {
    grid-row: 2;
  }

  &:nth-of-type(n + 15):nth-of-type(-n + 12) {
    grid-row: 3;
  }

  &:nth-of-type(4n + 1) {
    grid-column: 1/1;
  }

  &:nth-of-type(4n + 2) {
    grid-column: 2/2;
  }

  &:nth-of-type(4n + 3) {
    grid-column: 3/3;
  }

  &:nth-of-type(4n + 4) {
    grid-column: 4/4;
  }
`
export const DatePickerCalendarDay = styled.div`
  text-align: right;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  position: relative;

  &:nth-of-type(7n + 7) {
    border-right: 0;
  }

  &:nth-of-type(n + 1):nth-of-type(-n + 7) {
    grid-row: 1;
  }

  &:nth-of-type(n + 8):nth-of-type(-n + 14) {
    grid-row: 2;
  }

  &:nth-of-type(n + 15):nth-of-type(-n + 21) {
    grid-row: 3;
  }

  &:nth-of-type(n + 22):nth-of-type(-n + 28) {
    grid-row: 4;
  }

  &:nth-of-type(n + 29):nth-of-type(-n + 35) {
    grid-row: 5;
  }

  &:nth-of-type(7n + 1) {
    grid-column: 1/1;
  }

  &:nth-of-type(7n + 2) {
    grid-column: 2/2;
  }

  &:nth-of-type(7n + 3) {
    grid-column: 3/3;
  }

  &:nth-of-type(7n + 4) {
    grid-column: 4/4;
  }

  &:nth-of-type(7n + 5) {
    grid-column: 5/5;
  }

  &:nth-of-type(7n + 6) {
    grid-column: 6/6;
  }
  &:nth-of-type(7n + 7) {
    grid-column: 7/7;
  }
`
export const DatePickerDayName = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  color: ${props => props.theme.colors.text};
  text-align: center;
  font-weight: 600;
`
export const DatePickerDayDisabled = styled(DatePickerCalendarDay)`
  span {
    color: ${props => props.theme.colors.accent5};
    font-weight: 500;
  }
`
export const EventBodyWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow: auto;

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #dbdfe2;
  }
  ${FieldRow} {
    margin-bottom: 10px;
  }

  ${SelectStyled} {
    max-width: inherit;
    min-width: 180px;
    width: auto;
  }

  ${StyledSelect} {
    min-width: 180px;

    @media ${device.tablet} {
      min-width: 140px;
    }
  }

  ${DatePickerCalendarContainer} {
    top: 40px;
  }
  @media ${device.tablet} {
    overflow: hidden;
    height: auto;
    ${FormFooterContainer} {
      background: none;
      border-top: none;
    }

    ${FormContentContainer} {
      padding: 20px 0px 0px 20px;
    }

    ${SelectStyled} {
      left: -125px;
    }
  }
`
export const EventStatus = styled.div`
  display: flex;
  align-items: center;
  margin-right: 15px;

  @media ${device.tablet} {
    margin-right: 0;
  }
`
type TEventTimeRowProps = {
  isFullWidth?: boolean
}
export const EventTimeRow = styled.div<TEventTimeRowProps>`
  display: flex;
  align-items: center;
  width: ${props => (props.isFullWidth ? '100%' : '340px')};

  ${TooltipWrapper} {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }

  @media ${device.tablet} {
    padding-right: 10px;
    ${ButtonLabel} {
      display: none !important;
    }
  }
`
export const EventTimeReservationWrapper = styled.div`
  color: ${props => props.color};

  @media ${device.tablet} {
    display: none;
  }
`
export const EventContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 3px;
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: #ffffff;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #dbdfe2;
  }

  @media ${device.tablet} {
    max-height: 100%;
    overflow: hidden;
    padding: 10px 10px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`
export const EventTimeIcon = styled.div`
  font-size: 1.714rem;
  border-radius: 9.5px;
  color: ${props => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  min-width: 38px;
`
export const EventDateTitle = styled.div`
  font-size: 22px;
  cursor: pointer;
  color: ${props => props.theme.colors.text};
  display: flex;
  align-items: center;
  svg {
    margin-left: 8px;
  }

  @media ${device.tablet} {
    font-size: 18px;
    padding: 5px 0px 0px 5px;
  }
`
type TEventServicesListProps = {
  isEvent?: boolean
}
export const EventServicesList = styled.div<TEventServicesListProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: ${props => (props.isEvent ? '300px' : 'auto')};
  overflow: auto;
  padding: 5px 20px;
  background: ${props => props.theme.colors.lightBackground};
  border-radius: 4px;

  @media ${device.tablet} {
    padding: 5px 10px;
  }
`
export const WorkHourRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`
type TWorkHourNameProps = {
  isInactive?: boolean
  isBusy?: boolean
}
export const WorkHourName = styled.span<TWorkHourNameProps>`
  flex: 2;
  font-size: 14px;
  color: ${props =>
    props.isInactive
      ? props.theme.colors.accent4
      : props.isBusy
      ? props.theme.colors.accent5
      : props.theme.colors.text};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
type TWorkHourHintProps = {
  isBusy?: boolean
  isInactive?: boolean
}
export const WorkHourHint = styled.span<TWorkHourHintProps>`
  flex: 1;
  font-size: 14px;
  font-style: italic;
  color: ${props =>
    props.isInactive
      ? props.theme.colors.accent4
      : props.isBusy
      ? props.theme.colors.accent5
      : props.theme.colors.text};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline;
`
type TEventColumnProps = {
  marginTop?: string
  isRight?: boolean
}
export const EventColumn = styled.div<TEventColumnProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${props => (props.marginTop ? '1.714rem' : '0')};
  margin-left: ${props => (props.isRight ? 'auto' : 'initial')};
`
export const ServiceCircle = styled.div`
  width: 1.714rem;
  height: 1.714rem;
  border-radius: 0.857rem;
  background: ${props => props.color};
  min-width: 1.714rem;
  margin-right: 12px;
`
export const SelectRow = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 40px);

  ${AvatarWrapper} {
    margin-right: 8px;
  }

  ${OrganizationSwitchIcon} {
    margin-left: 0px;
  }

  @media ${device.tablet} {
    width: 100%;
  }
`
export const EventService = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 6px 0px;
  position: relative;
  span {
    margin-left: 8px;
    font-weight: 500;
  }

  @media ${device.tablet} {
    ${ServiceCircle} {
      display: none;
    }

    ${SelectRow} {
      max-width: calc(100% - 40px);
    }
  }
`
export const ServiceName = styled.span`
  flex: 2;
  font-size: 14px;
  color: ${props => props.theme.colors.accent3};
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
export const SelectOptionLabel = styled.span`
  font-size: 14px;
  color: ${props => props.theme.colors.accent3};
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline;
  margin-right: 15px;

  @media ${device.tablet} {
    flex: initial;
  }
`
export const SelectOptionWarning = styled.span`
  flex: 1;
  font-size: 14px;
  color: ${props => props.theme.colors.accent5};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline;

  @media ${device.tablet} {
    flex: initial;
  }
`
export const EventServiceCloseButton = styled.div`
  position: absolute;
  right: 10px;
  cursor: pointer;
`
export const ClientHeader = styled.div`
  display: flex;
  align-items: center;
`
export const ClientRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`
export const ClientContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.outline};
  padding: 10px 25px;
  background: ${props => props.theme.colors.lightBackground};

  @media ${device.tablet} {
    flex-direction: column;
    padding: 15px 15px 5px 15px;
  }
`
export const ClientColumn = styled.div`
  display: flex;
  flex-direction: column;
`
export const ClientItem = styled.div`
  display: flex;
  padding: 0 12px;
  flex-direction: column;

  @media ${device.tablet} {
    padding: 0px 12px;
  }
`
export const ClientItemHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 12px 8px;
  cursor: pointer;
  &:hover {
    background: ${props => props.theme.colors.lightBackground};
  }

  @media ${device.tablet} {
    flex-wrap: wrap;
    padding: 15px 12px 0px;
    &:hover {
      background: none;
    }
  }
`
export const ClientItemTitle = styled.div`
  margin-right: 20px;
  font-weight: 600;
  @media ${device.tablet} {
    font-weight: 500;
    margin-bottom: 10px;

    &:last-child {
      margin-top: 10px;
    }
  }
`
export const ClientEmployee = styled.div`
  margin-right: 20px;
  min-width: 100px;
  margin-left: 10px;
  @media ${device.tablet} {
    margin-right: auto;
    min-width: 100px;
    margin-bottom: 10px;
    margin-left: 0px;
    font-weight: 500;
    width: auto;
  }
`
export const ClientItemServices = styled.div`
  margin-right: auto;
  max-width: 350px;
  display: initial;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  @media ${device.tablet} {
    margin-bottom: 10px;
  }
`
export const ClientItemIcon = styled.div`
  margin-right: 20px;
  @media ${device.tablet} {
    font-weight: 500;
    margin-bottom: 10px;
  }
`
export const ClientList = styled.div`
  max-height: 500px;
  overflow: auto;
  padding: 10px 20px;

  @media ${device.tablet} {
    max-height: 100%;
    overflow: initial;
    padding: 10px 5px;
  }
`
