import { css } from 'styled-components'

const getImageCss = ({ size, type = 'default' }) =>
  css<any>`
    width: ${size}px;
    height: ${size}px;
    border-radius: 100px;
    -webkit-mask-size: cover;
    ${type === 'social' &&
    `-webkit-mask-image: url(https://static.buffer.com/ui/avatar-mask-${size}.svg);`}
    background: url(${props => props.src}) center no-repeat, url(${props =>
      props.fallbackUrl}) center no-repeat;
    background-size: cover;
  `

export const image = {
  default: {
    small: getImageCss({ size: 20 }),
    smaller: getImageCss({ size: 26 }),
    default: getImageCss({ size: 32 }),
    medium: getImageCss({ size: 40 }),
    large: getImageCss({ size: 34 }),
    extraLarge: getImageCss({ size: 64 }),
    extraExtraLarge: getImageCss({ size: 80 }),
  },
}

const getWrapperCss = ({ size }) =>
  css`
    position: relative;
    width: ${size}px;
    height: ${size}px;
  `

export const wrapper = {
  small: getWrapperCss({ size: 20 }),
  smaller: getWrapperCss({ size: 26 }),
  default: getImageCss({ size: 32 }),
  medium: getWrapperCss({ size: 40 }),
  large: getWrapperCss({ size: 34 }),
  extraLarge: getWrapperCss({ size: 64 }),
  extraExtraLarge: getWrapperCss({ size: 80 }),
}

const getObjectCss = ({ size }) =>
  css`
    border-radius: 0.857rem;
    width: ${size}px;
    height: ${size}px;
  `

export const object = {
  small: getObjectCss({ size: 20 }),
  smaller: getObjectCss({ size: 26 }),
  default: getImageCss({ size: 32 }),
  medium: getObjectCss({ size: 40 }),
  large: getObjectCss({ size: 34 }),
  extraLarge: getWrapperCss({ size: 64 }),
  extraExtraLarge: getWrapperCss({ size: 80 }),
}
