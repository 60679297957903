import styled from 'themes'
import { device } from '../../constants'

export const SettingsAccountAvatarRow = styled.div`
  display: flex;
  margin: 0px 0 1.714rem;
`

export const SettingsAccountAvatar = styled.img`
  width: 128px;
  height: 128px;
  min-width: 128px;
  min-height: 128px;
  border-radius: 0.286rem;
  margin-right: 1.714rem;
  object-fit: cover;
`

export const SettingsAccountSubtitle = styled.span`
  font-size: 1.143rem;
  line-height: 1.571rem;
  color: ${props => props.theme.colors.secondaryDark};
  margin-bottom: 0.857rem;
  margin-top: 1.714rem;
`

export const SettingsAccountSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const SettingsAccountAvatarButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 324px;
`
type TFieldColumnProps = {
  isNarrow?: boolean
}

export const FieldColumn = styled.div<TFieldColumnProps>`
  display: flex;
  flex: 1;
  flex-direction: ${props => (props.isNarrow ? 'column' : 'initial')};
  max-width: ${props => (props.isNarrow ? '160px' : 'auto')};
  margin-bottom: ${props => (props.isNarrow ? '10px' : '0')};

  @media ${device.tablet} {
    width: 100%;
    margin-bottom: 14px;
  }
  :not(:last-child) {
    padding-right: 14px;
  }
`
export const FieldRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
  @media ${device.tablet} {
    flex-direction: column;
    width: 100%;
    margin-bottom: 0px;
  }
`
