import styled from 'styled-components'
import { ButtonLabel } from '../../../components/Button/style'
import { device } from '../../../constants'
import { SelectStyled } from '../../../components/Select/style'

export const NavigationContainer = styled.div<any>`
  z-index: 99999;
  display: block;
  width: 100%;
  height: auto;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background: ${props =>
    props.isHome ? 'transparent' : 'linear-gradient(45deg, #1a74bb, #004187)'};
  position: ${props => (props.isHome ? 'relative' : 'fixed')};
  min-height: 80px;
  @media ${device.tablet} {
    display: flex;
    align-items: center;
    height: 70px;
    min-height: 70px;
    position: sticky;
  }
`

export const NavigationWrapper = styled.div<any>`
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  display: flex;
  padding: 15px 40px;
  margin-top: 0;
  margin-left: auto;
  position: relative;
  width: 100%;
  margin-right: auto;
  min-height: 70px;
`

export const SelectWrapper = styled.div<any>`
  ${SelectStyled} {
    left: ${props => (props.isRight ? '-190px' : 'inherit')};
  }
`
export const NavigationLeftColumn = styled.div`
  display: flex;
  margin-right: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  text-align: left;
  align-items: center;
  width: 100%;
  ${ButtonLabel} {
    font-weight: 700;
    font-size: 17px;
    letter-spacing: -0.5px;
    padding: 0px 8px;
  }

  @media ${device.tablet} {
    button {
      display: none;
    }
  }
`

export const NavigationLogo = styled.a`
  float: left;
  text-decoration: none;
  position: relative;
  background-color: transparent;
  text-align: left;
  position: absolute;

  @media ${device.tablet} {
    width: 150px;
    margin: auto;
    margin-left: 20px;
  }
`

export const NavigationLogoImage = styled.img`
  will-change: opacity;
  opacity: 1;
  width: 88px;
  margin-left: 10px;
  height: auto;
  max-width: 100%;
  border: 0;
  vertical-align: middle;
  display: inline-block;
  cursor: pointer;
`

export const NavigationNav = styled.nav`
  display: flex;
  margin-left: auto;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: auto;
  position: relative;

  @media ${device.tablet} {
    display: none;
  }
`

export const NavigationNavLink = styled.span<any>`
  color: ${props =>
    props.isActive ? props.theme.colors.accent1 : props.theme.colors.light};
  max-width: 1220px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  cursor: pointer;
  display: block;
  line-height: 26px;
  padding: 15px 20px;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-weight: ${props => (props.isActive ? 700 : 400)};
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  vertical-align: top;
  text-decoration: none;
  background-color: transparent;
`
