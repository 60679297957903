import React from 'react'
import { ErrorContainer, ErrorText, ErrorTitle } from './style'
import Button from '../Button/Button'
import { Translation } from 'react-i18next'
const analytics = window.analytics || {}
export default class ErrorBoundary extends React.Component {
  componentDidCatch(error, info) {
    if (analytics && typeof analytics.track === 'function') {
      analytics.track('Fatal, app crashed!', {
        severity: 'error',
        orgId: localStorage.getItem('orgId'),
        errorInfo: info && info.componentStack,
      })
    }
  }

  onRefresh = () => {
    window.history.back()
    setTimeout(() => window.location.reload(), 1000)
  }

  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Translation>
          {(t, { i18n }) => (
            <ErrorContainer>
              <img
                src={process.env.PUBLIC_URL + '/assets/ooops.svg'}
                alt="Glass"
              />
              <ErrorTitle>
                {t('translation.ErrorBoundary.label-error-404')}
              </ErrorTitle>
              <ErrorText>
                {t('translation.ErrorBoundary.label-error-line-1')}
                <br /> {t('translation.ErrorBoundary.label-error-line-2')}
              </ErrorText>
              <Button
                buttonType={'primary'}
                onClick={this.onRefresh}
                label={t('translation.ErrorBoundary.label-refresh')}
              />
            </ErrorContainer>
          )}
        </Translation>
      )
    }

    return this.props.children
  }
}
