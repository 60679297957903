import { Toast } from './Toast'

import { useMemo, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { produce } from 'immer'
import { useMutation, useQuery, useApolloClient } from '@apollo/client'
import { useAppServices } from './appServices'
import { routeBuilder } from './routeBuilder'
import { getOrgId, getLocationId } from 'helpers'
export { DateUtil } from './DateUtil'
type TRouteBuilderKeys = keyof typeof routeBuilder

type INavigateTo = {
  [key in TRouteBuilderKeys]: (params) => any
}
export const useSdk = () => {
  const toast = useMemo(() => new Toast(), [])
  const history = useHistory()
  const { t } = useTranslation()
  const apolloClient = useApolloClient()
  const appServices = useAppServices()

  const handleNavigate = useCallback(
    key => {
      return (params, options) => {
        const route = routeBuilder[key]({
          orgId: getOrgId(),
          locationId: getLocationId(),
          ...params,
        })
        if (options?.replace) {
          history.replace(route)
        } else {
          history.push(route)
        }
      }
    },
    [history]
  )

  const navigateTo = useMemo(() => {
    const mappedNavigateTo = {}
    Object.keys(routeBuilder).forEach(
      key => (mappedNavigateTo[key] = handleNavigate(key))
    )
    return mappedNavigateTo as typeof routeBuilder
  }, [handleNavigate])

  const updateQueryCache = useCallback(
    (q, p) => {
      const current = apolloClient.readQuery({
        query: q.query,
        variables: q.variables,
      })
      const newData = produce(current, draft => {
        p(draft, current)
      })
      apolloClient.writeQuery({
        query: q.query,
        variables: q.variables,
        data: newData,
      })
    },
    [apolloClient]
  )

  const sdk = useMemo(
    () => ({
      toast,
      history,
      useMutation,
      useQuery,
      useApolloClient,
      useParams,
      apolloClient,
      updateQueryCache,
      t,
      produce,
      appServices,
      navigateTo,
      routeBuilder,
    }),
    [toast, history, t, apolloClient, appServices, navigateTo, updateQueryCache]
  )
  return sdk
}
