import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { ServiceWorkerProvider, useServiceWorker } from './useServiceWorker'
import { ServiceWorkerMessage } from './components/ServiceWorkerMessage/ServiceWorkerMessage'
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import Helmet from 'react-helmet'
import { datadogLogs } from '@datadog/browser-logs'
datadogLogs.init({
  clientToken: process.env.REACT_APP_DATA_DOG_TOKEN,
  site: 'datadoghq.eu',
  forwardErrorsToLogs: true,
  sampleRate: 100,
  service: process.env.REACT_APP_DATA_DOG_SERVICE,
  env: process.env.REACT_APP_DATA_DOG_ENV,
})

const AppComponent = () => {
  const { isUpdateAvailable, updateAssets } = useServiceWorker()

  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <ToastContainer
        position="top-center"
        hideProgressBar={false}
        newestOnTop={false}
        draggable
        pauseOnHover
        closeOnClick={false}
        closeButton={false}
        pauseOnFocusLoss={false}
        autoClose={3000}
      />
      <App />
      {isUpdateAvailable && <ServiceWorkerMessage onClick={updateAssets} />}
    </div>
  )
}

ReactDOM.render(
  <ServiceWorkerProvider>
    <Helmet>
      {process.env.REACT_APP_ENV === 'development' ? (
        <script>
          {`  !(function () {
              var analytics = (window.analytics = window.analytics || [])
              if (!analytics.initialize)
                if (analytics.invoked) {
                  window.console &&
                    console.error &&
                    console.error('Segment snippet included twice.')
                } else {
                  analytics.invoked = !0
                  analytics.methods = [
                    'trackSubmit',
                    'trackClick',
                    'trackLink',
                    'trackForm',
                    'pageview',
                    'identify',
                    'reset',
                    'group',
                    'track',
                    'ready',
                    'alias',
                    'debug',
                    'page',
                    'once',
                    'off',
                    'on',
                    'addSourceMiddleware',
                    'addIntegrationMiddleware',
                    'setAnonymousId',
                    'addDestinationMiddleware',
                  ]
                  analytics.factory = function (e) {
                    return function () {
                      var t = Array.prototype.slice.call(arguments)
                      t.unshift(e)
                      analytics.push(t)
                      return analytics
                    }
                  }
                  for (var e = 0; e < analytics.methods.length; e++) {
                    var key = analytics.methods[e]
                    analytics[key] = analytics.factory(key)
                  }
                  analytics.load = function (key, e) {
                    var t = document.createElement('script')
                    t.type = 'text/javascript'
                    t.async = !0
                    t.src =
                      'https://cdn.segment.com/analytics.js/v1/' +
                      key +
                      '/analytics.min.js'
                    var n = document.getElementsByTagName('script')[0]
                    n.parentNode.insertBefore(t, n)
                    analytics._loadOptions = e
                  }
                  analytics.SNIPPET_VERSION = '4.13.1'
                  analytics.page()
                }
            })()`}
        </script>
      ) : (
        <script>
          {`!(function () {
              var analytics = (window.analytics = window.analytics || [])
              if (!analytics.initialize)
                if (analytics.invoked)
                  window.console &&
                    console.error &&
                    console.error('Segment snippet included twice.')
                else {
                  analytics.invoked = !0
                  analytics.methods = [
                    'trackSubmit',
                    'trackClick',
                    'trackLink',
                    'trackForm',
                    'pageview',
                    'identify',
                    'reset',
                    'group',
                    'track',
                    'ready',
                    'alias',
                    'debug',
                    'page',
                    'once',
                    'off',
                    'on',
                    'addSourceMiddleware',
                    'addIntegrationMiddleware',
                    'setAnonymousId',
                    'addDestinationMiddleware',
                  ]
                  analytics.factory = function (e) {
                    return function () {
                      var t = Array.prototype.slice.call(arguments)
                      t.unshift(e)
                      analytics.push(t)
                      return analytics
                    }
                  }
                  for (var e = 0; e < analytics.methods.length; e++) {
                    var key = analytics.methods[e]
                    analytics[key] = analytics.factory(key)
                  }
                  analytics.load = function (key, e) {
                    var t = document.createElement('script')
                    t.type = 'text/javascript'
                    t.async = !0
                    t.src =
                      'https://cdn.segment.com/analytics.js/v1/' +
                      key +
                      '/analytics.min.js'
                    var n = document.getElementsByTagName('script')[0]
                    n.parentNode.insertBefore(t, n)
                    analytics._loadOptions = e
                  }
                  analytics.SNIPPET_VERSION = '4.13.1'
                  analytics.page()
                }
            })()`}
        </script>
      )}
    </Helmet>
    <ErrorBoundary>
      <AppComponent />
    </ErrorBoundary>
  </ServiceWorkerProvider>,

  document.getElementById('root')
)
