import styled from 'styled-components'

export const BannerWrapper = styled.div`
  position: absolute;
  bottom: 1.429rem;

  z-index: 9999999999999;
  margin: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const BannerDiv = styled.div`
  padding: 1.429rem;
  background: rgb(19 102 173);
  color: rgb(255 255 255);
  border-radius: 0.714rem;

  display: flex;
  box-shadow: 3px 3px 1.429rem 3px rgba(0, 0, 0, 0.25);
  align-items: center;
  span {
    font-size: 1rem;
    margin-right: 1.071rem;
  }

  button {
    background-color: rgb(255 203 28);
    color: rgb(1 66 136);
    border: 1px solid rgb(232 180 0);
    font-weight: 600;
  }
`
