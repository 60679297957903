import React from 'react'
import ReactCalendar, { CalendarProps } from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import styled from 'styled-components'
import { device } from '../../constants'
import { fontFamily } from '../style/fonts'

export const CalendarContainer = styled.div`
  .react-calendar {
    border: none;
    font-size: 1.2rem;
    min-width: 700px;
    color: ${props => props.theme.colors.accent2};
    font-family: ${fontFamily};

    @media ${device.tablet} {
      min-width: 100vw;
    }
  }

  .react-calendar__tile {
    max-width: 100%;
    text-align: center;
    font-family: ${fontFamily} !important;
    font-size: 16px;
    padding: 0.75em 0.5em;
    :hover {
      background: ${props => props.theme.colors.accent2};
      color: ${props => props.theme.colors.light};
      opacity: 0.7;
    }
  }
  .react-calendar__tile:disabled {
    background-color: ${props => props.theme.colors.lightBackground};
    color: ${props => props.theme.colors.accent2};
    opacity: 0.6;
  }
  .react-calendar__tile--now {
    background-color: ${props => props.theme.colors.lightBackground};
    color: ${props => props.theme.colors.accent2};
    opacity: 0.6;
  }
  .react-calendar__tile--active:enabled:focus {
    background: ${props => props.theme.colors.accent2};
    color: ${props => props.theme.colors.light};
  }
  .react-calendar__tile--active {
    background: ${props => props.theme.colors.accent2};
    border-radius: 2px;
    color: ${props => props.theme.colors.light} !important;
  }
  .react-calendar__month-view__days__day--weekend {
    color: inherit;
    font-weight: bold;
  }
  abbr[data-original-title],
  abbr[title] {
    text-decoration: none;
    -webkit-text-decoration: none;
    cursor: help;
    border-bottom: 0;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
    font-size: 1.1rem;
  }
`

export const Calendar = (props: CalendarProps & { style?: any }) => {
  return (
    <CalendarContainer>
      <ReactCalendar
        {...props}
        tileClassName="selected-day"
        locale="hr"
        showNeighboringMonth={false}
        maxDetail="month"
      />
    </CalendarContainer>
  )
}
