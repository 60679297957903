import React from 'react'
import { AppLoading, LoadingCenter, Spinner } from './styles'

export const Loader = props => {
  const { isComponent } = props
  return (
    <AppLoading isComponent={isComponent} data-cy={'zoyya-loading'}>
      <LoadingCenter>
        <Spinner image={process.env.PUBLIC_URL + '/ripple.svg'}></Spinner>
      </LoadingCenter>
    </AppLoading>
  )
}
