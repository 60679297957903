import React, {
  useRef,
  useState,
  useMemo,
  Suspense,
  lazy,
  useEffect,
} from 'react'
import {
  ClientAppBody,
  ClientAppWrapper,
  NavSection,
  WizardBodyWrapper,
} from './styles'
import { Route, Switch, useLocation } from 'react-router'
import { useViewportDimensions } from 'helpers'
import { PrivateRoute } from './sdk/PrivateRoute'
import { HeaderContext, ClientMobileHeader } from 'mobile/Header'
import { useSdk } from 'sdk'
import { UserAvatar, UserAvatarWrapper, UserName } from 'styles'
import { MenuItem } from 'mobile/Menu'
import { Redirect } from 'react-router-dom'
import { getCurrentLanguage } from './localization'

import i18n from 'localization'
import {
  NavigationContainer,
  NavigationLeftColumn,
  NavigationLogo,
  NavigationLogoImage,
  NavigationNav,
  NavigationNavLink,
  NavigationWrapper,
} from './views/Booking/Navigation/styles'
import {
  NavBarChavron,
  NavBarName,
  NavBarStyled,
  NavBarUser,
} from './components/NavBarMenu/style'
import {
  AkdLogo,
  LoacationSection,
  LocationGridHeader,
  LocationImageSection,
  LocationsGridTitle,
  OrderButton,
  TitleBig,
} from './views/Booking/LocationsGrid/LocationsGrid'
import { useAuth } from 'sdk/useAuth'
import { Loader, Icons } from 'components'
import { usePromptToInstall } from './App'

const FooterNavigation = lazy(() => import('./mobile/FooterNavigation'))
const SelectedDesktopLocation = lazy(
  () => import('./views/Booking/SelectedLocation/SelectedLocation')
)
const NewEventWizard = lazy(
  () => import('./views/Booking/NewEventWizard/NewEventWizard')
)
const NewEventWizardMobile = lazy(
  () => import('./mobile/Booking/NewEventWizard/NewEventWizardMobile')
)
const SelectedLocation = lazy(
  () => import('./mobile/Booking/SelectedLocation/SelectedLocation')
)
const SettingsMobile = lazy(() => import('./mobile/Settings/SettingsMobile'))
const NavigationHeader = lazy(
  () => import('./views/Booking/Navigation/Navigation')
)
const Footer = lazy(() => import('./views/Booking/Footer/Footer'))
const OrgLocations = lazy(() => import('./views/OrgLocations/OrgLocations'))
const NotFound = lazy(() => import('./components/NotFound/NotFound'))
const MyAccount = lazy(() => import('./views/Settings/MyAccount/MyAccount'))
const DesktopLocationsGridComponent = lazy(
  () => import('./views/Booking/LocationsGrid/LocationsGrid')
)
const BookingRezervationsDesktop = lazy(
  () => import('./views/Booking/Reservations/BookingRezervationsDesktop')
)
const AppointmentMobile = lazy(() => import('./mobile/Appointment/Appointment'))
const Appointment = lazy(
  () => import('./views/Booking/Appointment/Appointment')
)
const MyAccountSecurity = lazy(
  () => import('./views/Settings/MyAccount/MyAccountSecurity')
)
const MyAccountContact = lazy(
  () => import('./views/Settings/MyAccount/MyAccountContact')
)

const MyAccountSettings = lazy(
  () => import('./views/Settings/MyAccount/MyAccountSettings')
)

const AboutUs = lazy(() => import('./views/Pages/AboutUs'))
const Contact = lazy(() => import('./views/Pages/Contact'))
const PrivacyPolicy = lazy(() => import('./views/Pages/PrivacyPolicy'))
const Cookies = lazy(() => import('./views/Pages/Cookies'))
const TermsOfService = lazy(() => import('./views/Pages/TermsOfService'))

const getUserAvatar = user => {
  return !user?.avatarUrl || user?.avatarUrl.endsWith('null') ? (
    <UserAvatar>
      <img
        alt={'avatar'}
        style={{ padding: 7 }}
        src={process.env.PUBLIC_URL + '/assets/default-avatar.svg'}
      />
    </UserAvatar>
  ) : (
    <UserAvatar>
      <img alt={'slika'} style={{ padding: 7 }} src={user?.avatarUrl} />
    </UserAvatar>
  )
}

export const ClientAppDrawer = props => {
  const { onCloseDrawer } = props
  const { appServices, history, t } = useSdk()
  const { deferredPrompt } = usePromptToInstall()
  const { user, signOut } = useAuth()
  const navItems = useMemo(
    () => [
      ...(!!user
        ? [
            {
              id: 1,
              route: `/mjesta`,
              title: t('translation.FooterNavigation.title-locations'),
              icon: <Icons.Dashboard />,
            },
            {
              id: 2,
              route: `/termini`,
              title: t('translation.FooterNavigation.appointments'),
              icon: <Icons.Calendar />,
            },
            {
              id: 3,
              route: `/racun`,
              title: getCurrentLanguage() === 'en' ? 'My account' : 'Moj račun',
              icon: <Icons.UserBold />,
            },
            {
              id: 4,
              route: `/racun/postavke`,
              title: getCurrentLanguage() === 'en' ? 'English' : 'Hrvatski',
              icon:
                getCurrentLanguage() === 'en' ? (
                  <Icons.UKFlag />
                ) : (
                  <Icons.CroatianFlag />
                ),
            },
            ...(deferredPrompt
              ? [
                  {
                    id: 1,
                    title: t('translation.clientRoutes.installApp'),
                    onClick: () => {
                      deferredPrompt.prompt()
                    },
                    icon: <Icons.PhoneDownload size={'smaller'} />,
                  },
                ]
              : []),
            {
              id: 5,
              title: t('translation.clientRoutes.logout'),
              onClick: () => {
                signOut()
                window.open(process.env.PUBLIC_URL + `/mjesta`, '_self')
              },
              icon: <Icons.Logout size={'smaller'} />,
            },
          ]
        : []),
      ...(!user
        ? [
            {
              id: 1,
              route: '/prijava',
              title: t('translation.clientRoutes.login'),
              icon: <Icons.Login size={'smaller'} />,
            },
            {
              id: 1,
              route: '/registracija',
              title: t('translation.clientRoutes.register'),
              icon: <Icons.Register size={'smaller'} />,
            },
            ...(deferredPrompt
              ? [
                  {
                    id: 1,
                    title: t('translation.clientRoutes.installApp'),
                    onClick: () => {
                      deferredPrompt.prompt()
                    },
                    icon: <Icons.PhoneDownload size={'smaller'} />,
                  },
                ]
              : []),
          ]
        : []),
    ],
    [user, appServices, t]
  )

  const handleMenuItemClick = item => {
    onCloseDrawer()
    if (item.onClick) {
      item.onClick()
    } else {
      history.push(item.route)
    }
  }
  return (
    <div>
      {user?.id ? (
        <UserAvatarWrapper>
          {getUserAvatar(user)}
          <UserName>{user?.firstName + ' ' + user?.lastName}</UserName>
        </UserAvatarWrapper>
      ) : null}
      {navItems.map((x, index) => (
        <MenuItem
          key={index}
          label={x.title}
          icon={x.icon}
          cy={x.id}
          onClick={() => handleMenuItemClick(x)}
        />
      ))}
    </div>
  )
}

export const InstallAppOnIos = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Icons.PhoneDownload color={'white'} />
      <div style={{ margin: 'auto' }}>
        <span>{i18n.t('translation.clientRoutes.installAppLabel')}</span>
        <Icons.SafariShare color={'white'} size={'small'} />
        <br />
        <span>{i18n.t('translation.clientRoutes.installAppLabel2')}</span>
      </div>
      <Icons.Cross color={'white'} size={'small'} />
    </div>
  )
}

export const ClientRoutes = ({ isMobile }) => {
  const { t } = useSdk()
  const { user } = useAuth()
  const { pathname } = useLocation()
  const clientRef = useRef()
  useEffect(() => {
    try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0)
    }
  }, [pathname, clientRef])

  const { navigateTo } = useSdk()

  const SuspenseWrapper = () => (
    <ClientAppWrapper ref={clientRef}>
      <Loader isComponent />
      <ClientAppBody isHome={pathname.endsWith('mjesta') && isMobile}>
        {!pathname.endsWith('mjesta') && isMobile ? (
          <ClientMobileHeader />
        ) : !pathname.endsWith('mjesta') ? (
          <NavigationContainer isHome={pathname.endsWith('mjesta')}>
            <NavigationWrapper>
              <NavigationLeftColumn>
                <NavigationLogo>
                  <NavigationLogoImage
                    onClick={() => navigateTo.myLocations()}
                    src={process.env.PUBLIC_URL + '/assets/eRedLogo.svg'}
                  />
                </NavigationLogo>
                {!!user?.id ? (
                  <NavigationNav>
                    <NavigationNavLink
                      isActive={pathname.startsWith('/mjesta')}
                    >
                      {t('translation.Navigation.link-myPlaces')}
                    </NavigationNavLink>
                    <NavigationNavLink isActive={pathname.endsWith('/termini')}>
                      {t('translation.Navigation.link-myBookings')}
                    </NavigationNavLink>
                    <NavBarStyled data-intercom-target="User-Menu">
                      <div
                        style={{
                          border: '1px solid #ffffff',
                          borderRadius: '100%',
                          padding: 5,
                          marginRight: '10px',
                          width: '30px',
                          height: '30px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Icons.UserBold color={'#ffffff'} size={'small'} />
                      </div>

                      <NavBarUser>
                        <NavBarName
                          style={{
                            fontSize: '14px',
                            color: '#ffffff',
                          }}
                          id="sidebarButton_myAccount"
                          data-cy="sidebar_myAccount"
                        >
                          {user?.firstName + ' ' + user?.lastName}
                        </NavBarName>
                      </NavBarUser>
                      <NavBarChavron>
                        <Icons.ChevronDown color={'#ffffff'} />
                      </NavBarChavron>
                    </NavBarStyled>
                  </NavigationNav>
                ) : (
                  <NavigationNav>
                    <NavigationNavLink>
                      {t('translation.Navigation.link-places')}
                    </NavigationNavLink>
                    <NavigationNavLink
                      onClick={() =>
                        window.open(
                          process.env.PUBLIC_URL + '/registracija',
                          '_self'
                        )
                      }
                    >
                      {t('translation.Navigation.link-register')}
                    </NavigationNavLink>
                    <NavigationNavLink
                      onClick={() =>
                        window.open(
                          process.env.PUBLIC_URL + '/prijava',
                          '_self'
                        )
                      }
                    >
                      {t('translation.Navigation.link-singIn')}
                    </NavigationNavLink>
                  </NavigationNav>
                )}
              </NavigationLeftColumn>
            </NavigationWrapper>
          </NavigationContainer>
        ) : null}
        {pathname.endsWith('mjesta') ? (
          <LoacationSection style={{ minHeight: '100vh' }}>
            <LocationGridHeader>
              <LocationImageSection>
                <LocationsGridTitle>
                  <AkdLogo
                    src={process.env.PUBLIC_URL + '/assets/eRed-logo.svg'}
                  />
                  <TitleBig>
                    {' '}
                    {t('translation.clientRoutes.selectLocation')}
                  </TitleBig>
                  <span>
                    {t('translation.clientRoutes.requestAppointment')}
                  </span>
                  <OrderButton>
                    {t('translation.clientRoutes.bookAppointment')}
                  </OrderButton>
                </LocationsGridTitle>
                <img
                  alt={'desktop'}
                  src={process.env.PUBLIC_URL + '/assets/eRed-background.svg'}
                />
              </LocationImageSection>
            </LocationGridHeader>
          </LoacationSection>
        ) : (
          <div style={{ minHeight: '100vh' }}></div>
        )}
        {!isMobile && !!user?.id && <Footer />}
      </ClientAppBody>
    </ClientAppWrapper>
  )

  return (
    <Suspense fallback={<SuspenseWrapper />}>
      <Switch>
        <ClientRoute path={`/postavke`} component={<SettingsMobile />} />
        <ClientRoute
          exact
          path={'/o-nama'}
          component={<AboutUs />}
          showMobileFooter={true}
        />
        <ClientRoute
          exact
          path={'/kontakt'}
          component={<Contact />}
          showMobileFooter={true}
        />
        <ClientRoute
          exact
          path={'/politika-privatnosti'}
          component={<PrivacyPolicy />}
          showMobileFooter={true}
        />
        <ClientRoute
          exact
          path={'/politika-kolacica'}
          component={<Cookies />}
          showMobileFooter={true}
        />
        <ClientRoute
          exact
          path={'/uvjeti-koristenja'}
          component={<TermsOfService />}
          showMobileFooter={true}
        />
        <ClientRoute
          exact
          path={'/termini'}
          isPrivate
          component={<BookingRezervationsDesktop />}
          showMobileFooter={true}
        />
        <ClientRoute
          exact
          path={'/termin/:locationId'}
          isPrivate
          component={<BookingRezervationsDesktop />}
          showMobileFooter={true}
        />

        <ClientRoute
          exact
          path={'/mjesta'}
          component={<DesktopLocationsGridComponent />}
          showMobileFooter={true}
        />
        <ClientRoute
          isPrivate
          path="/racun/sigurnost"
          component={<MyAccountSecurity />}
        />
        <ClientRoute
          isPrivate
          path="/racun/kontakt"
          component={<MyAccountContact />}
        />
        <ClientRoute
          isPrivate
          path="/racun/postavke"
          component={<MyAccountSettings />}
        />
        <ClientRoute isPrivate path="/racun" component={<MyAccount />} />

        <ClientRoute
          path={`/:orgId/:locationId/dan/:dayId/:calendarView/:stepId/:employeeId/:timeId/:inviteId`}
          component={isMobile ? <NewEventWizardMobile /> : <NewEventWizard />}
        />
        <ClientRoute
          path={`/:orgId/:locationId/dan/:dayId/:calendarView/:stepId/:employeeId/:timeId`}
          component={isMobile ? <NewEventWizardMobile /> : <NewEventWizard />}
        />
        <ClientRoute
          path={`/:orgId/:locationId/dan/:dayId/:calendarView/:stepId/:employeeId`}
          component={isMobile ? <NewEventWizardMobile /> : <NewEventWizard />}
        />
        <ClientRoute
          path={`/:orgId/:locationId/dan/:dayId/:calendarView/:stepId`}
          component={isMobile ? <NewEventWizardMobile /> : <NewEventWizard />}
        />
        <ClientRoute
          path={`/:orgId/:locationId/dan/:dayId/:calendarView`}
          component={isMobile ? <NewEventWizardMobile /> : <NewEventWizard />}
        />
        <ClientRoute
          path={'/termini/:bookingId'}
          isPrivate
          component={isMobile ? <AppointmentMobile /> : <Appointment />}
        />

        <ClientRoute
          path={`/:orgId/:locationId`}
          component={
            isMobile ? <SelectedLocation /> : <SelectedDesktopLocation />
          }
        />

        <ClientRoute path={`/:orgId`} component={<OrgLocations />} />

        <Route path="/" exact>
          <Redirect to="/mjesta" />
        </Route>
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  )
}

const ClientRoute = props => {
  const { isPrivate, path, exact, component, showMobileFooter } = props
  return isPrivate ? (
    <PrivateRoute path={path} exact={exact}>
      <ClientRouteContainer
        component={component}
        showMobileFooter={showMobileFooter}
      />
    </PrivateRoute>
  ) : (
    <Route path={path} exact={exact}>
      <ClientRouteContainer
        component={component}
        showMobileFooter={showMobileFooter}
      />
    </Route>
  )
}

const ClientRouteContainer = ({ component, showMobileFooter }) => {
  const containerRef = useRef()
  const [headerOptions, setHeaderOptions] = useState()
  const { isMobile } = useViewportDimensions()

  const headerProvider = useMemo(
    () => ({
      setOptions: options => setHeaderOptions(options),
      getOptions: () => headerOptions,
    }),
    [headerOptions]
  )
  const { pathname } = useLocation()
  const { user } = useAuth()
  return (
    <HeaderContext.Provider value={headerProvider}>
      <ClientAppWrapper ref={containerRef}>
        <ClientAppBody isHome={pathname.endsWith('mjesta') && isMobile}>
          {isMobile ? <ClientMobileHeader /> : <NavigationHeader />}
          <WizardBodyWrapper isHome={pathname.endsWith('mjesta')}>
            {component}
          </WizardBodyWrapper>
          {!isMobile && <Footer />}
          {isMobile && showMobileFooter && !!user?.id && <FooterNavigation />}
        </ClientAppBody>
      </ClientAppWrapper>
    </HeaderContext.Provider>
  )
}
