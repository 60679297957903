import React, {
  ChangeEventHandler,
  InputHTMLAttributes,
  useCallback,
  MouseEventHandler,
  useState,
} from 'react'
import { useField } from 'formik'
import { useFormContext } from '../Form'
import styled from 'styled-components'
import { FieldContainer } from '../FieldContainer'

export const CheckboxInput = styled.input`
  position: relative;
  cursor: pointer;
  height: 40px;
  &[disabled] {
    &:before {
      background-color: ${props => props.theme.colors.outline} !important;
    }
    :checked:before {
      background-color: ${props => props.theme.colors.outline} !important;
    }
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0;
    left: -1px;
    background-color: ${props => props.theme.colors.light};
    border: 1px solid ${props => props.theme.colors.outline};
    border-radius: 4px;
  }
  :checked:before {
    content: '';
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0;
    left: -1px;
    background-color: ${props => props.theme.colors.primary};
  }
  :checked:after {
    content: '';
    display: block;
    width: 10px;
    height: 20px;
    border: solid ${props => props.theme.colors.light};
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 7px;
    left: 15px;
  }
`

type TFormFieldCheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string
  onChange?: ChangeEventHandler<HTMLInputElement>
  label?: string
  labelAlignment?: string
  style?: any
  parseValue?: (value?: string) => any
  onClick?: (event: MouseEventHandler<HTMLInputElement>) => any
  html?: any
  helpText?: string
  helpLink?: string
}

export const FormFieldCheckbox = (props: TFormFieldCheckboxProps) => {
  const { parseValue, helpText, helpLink, ...rest } = props
  const [field, meta] = useField(props)
  const formContext = useFormContext()
  const [isChecked, setIsChecked] = useState(props.checked)

  const handleChange = useCallback(
    event => {
      setIsChecked(!isChecked)
      formContext?.valueChange(field, event, !isChecked)
    },
    [formContext, field, isChecked]
  )
  return (
    <FieldContainer
      label={props.label}
      labelAlignment={'horizontal'}
      error={meta.error}
      fieldName={field.name}
      type={props.type}
      html={props.html}
      helpText={props.helpText}
      helpLink={props.helpLink}
      style={props.style}
    >
      <CheckboxInput
        {...rest}
        name={props.name}
        onChange={handleChange}
        value={field.value || ''}
        checked={props.checked || field.value}
        disabled={props.disabled}
        data-cy={`checkbox_${props.name}`}
      />
    </FieldContainer>
  )
}
